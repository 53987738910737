  
.Container {
  position: relative;
  min-height: 300px;
  max-height: 650px;
  overflow: hidden;
  /* margin-bottom: 500px; */
}


@media (max-width: 1190px)
{
  .Container
  {
    display: none;  
  }
}

@media (max-width: 991px)
{
  .Container
  {
    display: none;
  }
}

@media (max-width: 600px)
{
  .Container
  {

  }

}

@media (min-width: 1800px)
{
  .Container
  {
    /* display: none; */
    min-height: 100%;
    max-height: 100%;



  }
}

/* Style the video: 100% width and height to cover the entire window */
.Video {
  width: 100%;
  height: 100%;
}

/* Add some content at the bottom of the video/page */
.Content {
  position: absolute;
  top: 0;
  color:#f1f1f1;
  background-color: #a7dcdf;
  backdrop-filter: blur(5px);

  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}
.Content2 {
  position: absolute;
  top: 0;
  color:#f1f1f1;
  background-color: rgba(255, 255, 255, 0.712);
  backdrop-filter: blur(5px);

  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}




.SubContent {
    text-align: center;
    /* padding: 10px; */
  }
.SubContent p {
    color: #eeeeeead;
    /* font-size: 1.3rem; */
}
.SubContent button {
    font-weight: bold;
    /* margin-top: 30px; */
    /* color: #f1f1f1;
    border: #f1f1f1 1px solid; */
    padding: 10px 5px;
    /* background-color: hsla(0, 0%, 0%, 0); */
    font-size: 1.25rem;
    border-radius: 5px;
}
/* .SubContent button:hover {
    background-color: #f1f1f1;
    color: #000000c0;
} */

.SubContent img {
  /* width: 200px;
  height: 200px; */
  /* border-radius: 50%; */
  display: block;
  margin: 15px auto;
  /* border: #f1f1f1 1px solid; */
}