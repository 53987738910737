html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

:root {
  --bg:  #a7226e;
  --bg-accent: #8c57a3;
  --text-color: #dadce1;
  --nav-size: 100px;
  --border: 1px solid #8c57a3;
  --border-radius: 8px;
  --speed: 500ms; 
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  color: var(--text-color);
  text-decoration: none;;
}

/* Top Navigation Bar */

/* <nav> */
.navbar2 {
  height: var(--nav-size);
  background-color:#00503d;
  padding: 0 1rem;
  width: 100%;
  top: 0;
  overflow: visible;
  border-radius: 0px;
  position: fixed;
}

/* <ul> */
.navbar2-nav2 {
  max-width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}

/*this gives a box with its children in the middle*/
/* <li> */
.nav2-item2 {
  width: calc(var(--nav-size) * 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Icon Button */
/*this creates an icon button with a ciscular shape*/
.icon2-button2 {
  --button-size: calc(var(--nav-size) * 0.5); /*uses calc funciton to give us a box that is 50% off the original navbar size*/
  width: var(--button-size);
  height: var(--button-size);
  background-color: transparent;
  border-radius: 50%; /*makes the shape circular*/
  padding: 5px;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: filter 300ms;
}

/*this changes colour on hover*/
.icon2-button2:hover {
  filter: brightness(1.2);
}

/*this sizes the icons*/
.icon2-button2 svg { 
  fill: var(--text-color);
  width: 20px;
  height: 20px;
}

/*this will make the menu overlap with the navbar by 2 pixels*/
.dropdown2 {
  position: absolute;
  top: 68px;
  width: 300px;
  transform: translateX(-45%); /*this moves it to the left, translate just means move*/
  background-color: var(--bg);
  border: var(--border);
  border-radius: var(--border-radius); /*this will hide any child element if they overlap this contaner, this is important because we will be sliding multiple menus within this container*/
  padding: 1rem;
  overflow: hidden;
  transition: height var(--speed) ease;
}

.menu2 {
  width: 100%;
}

/*menu items will be set as flex containers that align items to the center, in the context of a row this means vertical alignment*/
.menu2-item2 {
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: var(--border-radius);
  transition: background var(--speed);
  padding: 0.5rem;
}

.menu2-item2 .icon2-button2 {
  margin-right: 0.5rem;
}

/*target the menu items sudo selector by changing the background colour to something a little bit lighter*/
.menu2-item2:hover {
  background-color: #8c57a3;
}

/*w flexbox setting the left margin of the last item to auto will push it to the right and everything else to the left*/
.icon2-right {
  margin-left: auto; 
}

/* CSSTransition classes  */
.menu2-primary-enter {
  position: absolute;
  transform: translateX(-110%);
}
.menu2-primary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}
.menu2-primary-exit {
  position: absolute;
}
.menu2-primary-exit-active {
  transform: translateX(-110%);
  transition: all var(--speed) ease;
}


.menu2-secondary-enter {
  transform: translateX(110%);
}
.menu2-secondary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}
/* go */
.menu2-secondary-exit-active {
  transform: translateX(110%);
  transition: all var(--speed) ease;
}

.dropdown2 {

  transition: height var(--speed) ease;
}

.first-bottom-image {
  position: absolute;
  z-index: 9999999;
  bottom: -10%;
  left: 25%;
  width: 350px;
}

@media (max-width: 600px) {
  .first-bottom-image {
    display: none;
  }
}