stoke.css

.relative
{
  position: relative;
}

.clearer
{
  clear: both;
}

html
{
  overflow-x: hidden; 
  overflow-y: scroll;
}

body
{
  background: #a7226e;
  /* padding-top: 30px; */
}

body,::-webkit-input-placeholder
{
  font-family: 'Work Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  max-width: 100%;
}

img
{
  max-width: 100%;
}

input::-webkit-outer-spin-button,input::-webkit-inner-spin-button
{
  -webkit-appearance: none !important;
  margin: 0 !important;
}

.navbar-shrink,.navbar-default
{
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.logo
{
  margin: 10px 0;
}

a
{
  text-decoration: none !important;
  -webkit-transition: all ease-in-out 0.2s;
  -moz-transition: all ease-in-out 0.2s;
  transition: all ease-in-out 0.2s;
}

/* a:hover
{
  color: #464e51;
} */

header svg
{
  max-width: 200px;
}

.heading
{
  font-size: 34px;
  font-weight: normal;
  color: white;
  font-weight: 300;
  margin-top: 0;
  line-height: 1.2em;
}

@media (max-width: 800px)
{
  .heading
  {
    font-size: 24px;
  }
}

.heading a
{
  -webkit-transition: all ease-in-out 0.2s;
  -moz-transition: all ease-in-out 0.2s;
  transition: all ease-in-out 0.2s;
}

.heading a:hover
{
  color: #464e51;
}

.heading--28
{
  font-size: 23px;
}

.heading--alt
{
  font-size: 28px;
}




.heading--medium
{
  font-size: 36px;
}

.heading--large
{
  font-size: 42px;
}

@media (max-width: 800px)
{
  .heading--large
  {
    font-size: 28px;
  }
}

.heading--xlarge
{
  font-size: 55px;
  line-height: 55px;
}

@media (max-width: 800px)
{
  .heading--xlarge
  {
    font-size: 28px;
  }
}

.heading--small
{
  font-size: 24px;
}

.heading--purple
{
  color: #a7226e
;
}

.heading--push
{
  margin-bottom: 40px;
}

.heading--center
{
  text-align: center !important;
}

strong
{
  font-weight: 500;
}

p
{
  color: white;
  line-height: 1.8em;
  font-weight: 300;
  font-size: 18px;
}

p a:hover
{
  color: #464e51;
}

p .bold
{
  font-weight: 500;
}

.block
{
  display: block;
}

.push
{
  margin-bottom: 60px;
}

.push--small
{
  margin-bottom: 30px;
}

.purple
{
  color: #a7226e
;
}

a
{
  color: inherit;
}

.para-small
{
  font-size: 14px;
}

.para-xsmall
{
  font-size: 14px !important;
  line-height: 16px !important;
}

.download-button
{
  background: #c43427;
  width: auto;
  display: inline-block;
  color: white !important;
  padding: 7px 30px;
  font-weight: 300;
  border-radius: 5px;
  -webkit-transition: all ease-in-out 0.2s;
  -moz-transition: all ease-in-out 0.2s;
  transition: all ease-in-out 0.2s;
  text-transform: uppercase;
  font-size: 14px;
}

.download-button:hover
{
  padding: 7px 40px;
  text-decoration: none;
  color: white;
}

.download-button--purple
{
  background: #a7226e

;
}

.download-button--dark-purple
{
  background: #a7226e
;
}

.download-button--teal
{
  background: #599a98
;
}

.download-button--active
{
  background: #464e51 !important;
  pointer-events: none;
}

.section
{
  background-position: center top;
  position: relative;
}

.section ul
{
  margin-bottom: 20px;
}

.section ol
{
  margin-bottom: 20px;
}

.section h4
{
  color: white;
}

.section li
{
  color: white;
  line-height: 1.6em;
  font-weight: 300;
  font-size: 18px;
}

.section li a:hover
{
  color: inherit !important;
  text-decoration: underline !important;
}

@media (max-width: 1190px)
{
  .section
  {
    /* padding: 60px 0; */
    height: auto !important;
    background-size: 100% 100% !important;
  }
}

@media (max-width: 960px)
{
  .section
  {
    /* padding: 60px 0; */
    height: auto !important;
    background-size: auto 100% !important;
  }
}

.section .container
{
  padding-top: 0 !important;
}

@media (max-width: 1190px)
{
  .section .container
  {
    height: auto !important;
  }
}

.section .section__overlay
{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  left: 0;
  width: 100%;
  height: 100%;
  display: table;
}

@media (max-width: 800px)
{
  .section .section__overlay
  {
    position: relative;
  }
}

.section .section__overlay .container
{
  height: 100%;
  display: table-row;
}

@media (max-width: 1190px)
{
  .section .row
  {
    margin-top: 0px !important;
    height: auto !important;
  }
}

.section .col-md-6
{
  height: 500px;
  display: table;
}

@media (max-width: 991px)
{
  .section .col-md-6
  {
    height: auto !important;
    display: block !important;
    padding: 40px 20px;
  }
}

.section .section-content
{
  display: table-cell;
  vertical-align: middle;
  width: 100%;
}

@media (max-width: 991px)
{
  .section .section-content
  {
    display: block !important;
  }
}

.section .section-image
{
  display: inline-block;
  max-height: 400px;
  max-width: 300px;
  max-width: 400px;
  width: auto;
  height: auto;
}

@media (max-width: 1190px)
{
  .section .section-image
  {
    max-width: 300px !important;
  }
}

.section .section-text
{
  max-width: 380px;
  max-width: 100%;
  width: 100%;
  display: inline-block;
}

@media (max-width: 1190px)
{
  .section .section-text
  {
    max-width: 420px;
    margin: 0 auto !important;
    width: 100%;
    display: block;
  }
}

.section .section-text--wide
{
  max-width: 100% !important;
  max-width: 100%;
}

.section .section-text--full
{
  max-width: 100% !important;
  width: 100% !important;
}

@media (max-width: 1190px)
{
  .section .section-text--full
  {
    max-width: 100%;
    width: 100%;
    display: block;
  }
}

.section .read-more
{
  display: inline-block;
  padding: 40px 15px 0;
  border: 2px solid white;
  text-decoration: none;
  color: white;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.2s ease;
  border-radius: 100px;
  height: 120px;
  width: 120px;
}

.section .read-more:hover
{
  text-decoration: none !important;
  background: white;
  font-weight: bold;
}

.section .read-more--large
{
  height: 180px;
  width: 180px;
  padding-top: 74px;
}

.section .read-more__grape:hover
{
  color: #8c58a4 !important;
}

.section .read-more__purple--yellow
{
  color: #3D2154 !important;
  border-color: #3D2154;
}

.section .read-more__purple--yellow:hover
{
  color: #ffb91c;
  background: #3D2154 !important;
}

.section .read-more__green:hover
{
  color: #5f9c9b !important;
}

.section .read-more__blue:hover
{
  color: #3c78b8 !important;
}

.section .read-more__sky:hover
{
  color: #9dd9f7 !important;
}

.section .read-more__purple:hover
{
  color: #a7226e
 !important;
}

.section .read-more__dark-purple:hover
{
  color: #320735 !important;
}

@media (max-width: 991px)
{
  .push-top-20-mobile
  {
    margin-top: 20px !important;
  }
}

@media (max-width: 991px)
{
  .push-bottom-20-mobile
  {
    margin-bottom: 20px !important;
  }
}

.section-content
{
  text-align: center;
  width: 100%;
  margin: 0 auto;
  max-width: 600px;
}

.section-content--semifull
{
  text-align: center;
  width: 100%;
  margin: 0 auto;
  max-width: 900px;
}

.section-content--full
{
  text-align: center;
  width: 100%;
  margin: 0 auto;
  max-width: 100% !important;
}



.section-circle
{
  /* background: #a7226e; */
  border-radius: 50%;
  height: 270px;
  width: 270px;
  text-align: center;
  color: white;
  font-size: 20px;
  padding-top: 40px;
  font-weight: 300;
  margin: 0 auto;
}

@media (min-width: 1600px)
{
  .section-circle
  {
    height: 400px;
    width: 400px;
  }
}



@media (max-width: 991px)
{
  .section-circle
  {
    margin-top: 150px;
    background: rgba(196,62,50,0.8);
  }
}

.video-overlay
{
  display: none;
  position: fixed;
  background: rgba(88,44,131,0.9);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.video-overlay__inner
{
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}

.videoWrapper
{
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
}

.videoWrapper iframe
{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.videoWrapperSquare
{
  padding-bottom: 100% !important;
}

.video-overlay__close
{
  cursor: pointer;
  width: 50px;
  float: right;
  margin-bottom: 10px;
}

.video-overlay__open
{
  cursor: pointer;
}

.video-overlay__open img
{
  width: 50px;
}

.section-video
{
  max-width: 450px;
  margin: 0 auto;
}

.center
{
  text-align: center;
}

@media (max-width: 991px)
{
  .mobile-push
  {
    margin-bottom: 20px !important;
  }
}

@media (max-width: 991px)
{

  .no-mobile
  {
    display: none !important;
  }

  .section-content.no-mobile {
    display: none !important;
  } 
}

@media (min-width: 991px)
{
  .no-desktop
  {
    display: none !important;
  }
}

.pointer-events-none
{
  pointer-events: none;
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

@media (max-width: 1199px)
{
  .zopim
  {
    display: none;
  }
}

.default-section .container
{
  padding-bottom: 40px;
}

.standard-animation
{
  top: 40px;
  opacity: 0;
  position: relative;
}

@media (max-width: 990px)
{
  .standard-animation
  {
    opacity: 1;
    top: auto;
  }
}

.scroll-animation
{
  opacity: 0;
  top: 140px;
  position: relative;
}

@media (max-width: 990px)
{
  .scroll-animation
  {
    opacity: 1;
    top: auto;
  }
}

.fadeInRight
{
  position: relative;
  right: -60px;
  opacity: 0;
}

@media (max-width: 990px)
{
  .fadeInRight
  {
    opacity: 1;
    right: 0;
  }
}

.fadeInLeft
{
  position: relative;
  left: -60px;
  opacity: 0;
}

@media (max-width: 990px)
{
  .fadeInLeft
  {
    opacity: 1;
    left: auto;
  }
}

.fadeInLeft--active
{
  position: relative;
  opacity: 1;
  left: auto;
}

.fadeInRight--active
{
  position: relative;
  opacity: 1;
  right: 0;
}

.fadeInTop
{
  top: 40px;
  opacity: 0;
  position: relative;
}

@media (max-width: 990px)
{
  .fadeInTop
  {
    opacity: 1;
    top: 0;
  }
}

.fadeInTopSnow
{
  right: -1000px;
  transition-timing-function: ease-out;
  position: relative;
  transform: rotate(179deg);
}

@media (max-width: 990px)
{
  .fadeInTopSnow
  {
    opacity: 1;
    top: 0;
  }
}

.text-block p,.text-block h3,.image-block img,.quote-block h3
{
  top: rem-calc(40);
  opacity: 0;
  position: relative;
}

.fadeInTop--active,p.fadeInTop--active,img.fadeInTop--active,h3.fadeInTop--active
{
  position: relative;
  opacity: 1;
  top: 0;
}

.fadeInTopSnow--active
{
  transform: rotate(0deg);
  right: 0;
  opacity: 1;
}

.solarplicity-logo
{
  display: block;
  float: left;
  padding-top: 7px;
  position: relative;
}

.solarplicity-logo svg
{
  height: 67px;
}

@media (max-width: 991px)
{
  .solarplicity-logo
  {
    z-index: 99999;
  }
}

.header-top
{
  background: #a7226e
;
  position: fixed;
  height: 80px;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
}

@media (max-width: 991px)
{
  .header-top
  {
    padding-left: 10px;
  }
}

.header-top .container
{
  padding: 0;
}

.header-middle
{
  position: fixed;
  top: 20px;
  width: 100%;
  left: 0;
  z-index: 9998;
  transition: top 0.2s ease-in-out 0s;
  -webkit-transition: top 0.2s ease-in-out 0s;
  -moz-transition: top 0.2s ease-in-out 0s;
  -o-transition: top 0.2s ease-in-out 0s;
}

@media (max-width: 991px)
{
  .header-middle
  {
    top: 80px;
  }
}

.header-middle.nav-hidden
{
  top: -340px;
}

@media (max-width: 991px)
{
  .header-middle.nav-hidden
  {
    top: -600px;
  }
}

.header-account
{
  position: fixed;
  top: 40px;
  width: 100%;
  left: 0;
  z-index: 9998;
  transition: top 0.2s ease-in-out 0s;
  -webkit-transition: top 0.2s ease-in-out 0s;
  -moz-transition: top 0.2s ease-in-out 0s;
  -o-transition: top 0.2s ease-in-out 0s;
}

@media (max-width: 991px)
{
  .header-account
  {
    top: 80px;
  }
}

.header-account.nav-hidden
{
  top: -340px;
}

@media (max-width: 991px)
{
  .header-account.nav-hidden
  {
    top: -600px;
  }
}

nav
{
  background: #a7226e
;
  display: block;
  overflow: hidden;
  position: absolute;
  /* font-size: 16px; */
  top: 20px;
  width: 180px;
  right: 0;
  z-index: 9998;
  padding: 20px;
  border-bottom-left-radius: 50px;
}

@media (max-width: 1199px)
{
  nav
  {
    top: 60px;
  }
}

@media (max-width: 991px)
{
  nav
  {
    width: 100%;
    left: 0;
    /* text-align: center; */
    background: #a7226e
;
    border-bottom-left-radius: 0px;
    font-size: 20px;
    position: absolute;
    top: 0;
  }
}

nav a
{
  color: #fff;
  font-weight: 300;
  line-height: 1.8em;
}

.top-nav
{
  list-style: none;
  margin-top: 6px;
  padding-left: 0;
  float: right;
  margin-right: 110px;
}

.top-nav li
{
  display: inline-block;
}

.top-nav li a
{
  display: block;
  padding: 10px 20px;
  background: none;
  color: #fff;
  font-size: 16px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

@media (max-width: 991px)
{
  .top-nav li a
  {
    padding-left: 0;
  }
}

.top-nav li a:hover
{
  background: #a7226e
;
}

.top-nav li a.active
{
  background: #a7226e
;
}

@media (max-width: 991px)
{
  .top-nav
  {
    margin-top: 19px;
    margin-right: 80px;
  }
}

.account-nav
{
  background: #a7226e
;
  display: block;
  overflow: hidden;
  position: absolute;
  font-size: 16px;
  text-align: right;
  top: 0px;
  right: 110px;
  width: 250px;
  z-index: 9998;
  padding: 20px;
  list-style: none;
  border-bottom-left-radius: 50px;
}

@media (max-width: 1199px)
{
  .account-nav
  {
    top: 40px;
  }
}

@media (max-width: 991px)
{
  .account-nav
  {
    width: 100%;
    left: 0;
    text-align: center;
    background: #a7226e
;
    border-bottom-left-radius: 0px;
    font-size: 20px;
    top: 0;
  }
}

.account-nav a
{
  color: #fff;
  font-weight: 300;
  line-height: 1.8em;
}

.account-button
{
  cursor: pointer;
  margin-top: 29px;
  margin-right: 30px;
  float: right;
  font-size: 16px;
  color: #fff;
}

.account-button i
{
  font-size: 28px;
  color: #fff;
  float: right;
  margin-top: -4px;
  margin-left: 10px;
}

@media (max-width: 991px)
{
  .account-button i
  {
    margin-top: -7px;
    font-size: 36px;
  }
}

.ces-button
{
  float: right;
  margin-top: 16px;
  margin-right: 30px;
  font-size: 16px;
  color: #fff;
}

.menu-button
{
  float: right;
  margin-top: 25px;
  width: 80px;
  text-align: right;
  margin-right: 5px;
}

@media (max-width: 991px)
{
  .menu-button
  {
    margin-right: 20px;
    width: auto;
  }
}

.menu-button .icon-bar
{
  width: 100%;
  background: white !important;
  height: 3px !important;
  margin: 0 0 3px !important;
  display: block;
}

.menu-button .icon-text
{
  font-size: 16px;
  margin: 0 0 3px !important;
  display: block;
  line-height: 17px !important;
  user-select: none;
  cursor: pointer;
  color: #fff;
  display: none;
}

.menu-button .icon-text-open
{
  display: block;
}

.menu-button .menu-button__left
{
  float: left;
}

.menu-button i
{
  color: #fff;
  margin-left: 10px;
  font-size: 28px;
}

.fa-chevron-circle-down
{
  cursor: pointer;
}

.footer-section
{
  text-align: center;
  padding-top: 30px;
  background: #00503d;
}

.footer-section .container
{
  border-top: 1px solid #ffffff;
  padding-top: 40px;
}

.footer-logo
{
  max-width: 160px;
  margin-top: -15px;
}

@media (max-width: 991px)
{
  .footer-logo
  {
    margin-top: 0px;
    margin-bottom: 30px;
  }
}

.footer-contact
{
  color: white;
  font-size: 22px;
  font-weight: 500;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.footer-contact:hover,.footer-contact:focus,.footer-contact:active
{
  color: #ffffff;
  text-decoration: none;
}

@media (max-width: 767px)
{
  .footer-contact
  {
    font-size: 16px;
  }
}

.footer-contact-icon
{
  margin-bottom: 7px;
  width: 35px;
}

@media (max-width: 767px)
{
  .footer-contact-icon
  {
    width: 40px;
    height: auto;
  }
}

.footer-address
{
  color: white;
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 30px;
}

.footer-social-row
{
  margin-top: 30px;
}

.footer-social
{
  margin-right: 3px;
  margin-left: 3px;
  text-decoration: none;
}

.footer-social:hover,.footer-social:focus,.footer-social:active
{
  text-decoration: none;
}

.footer-social img
{
  width: 35px;
}

.footer-heading
{
  color: #ffffff;
  border-bottom: 1px solid #ffffff;
  text-transform: uppercase;
  font-size: 26px;
  font-weight: 200;
  margin-bottom: 0px;
  margin-top: 50px;
  padding-bottom: 15px;
}

.footer-sponsor-row
{
  margin-top: 20px;
  margin-bottom: 10px;
}

.footer-sponsor
{
  margin: 10px;
  max-width: 70px;
}

@media (max-width: 767px)
{
  .footer-sponsor
  {
    margin-top: 20px;
  }
}

.footer-nav
{
  list-style-type: none;
  font-size: 18px;
  font-weight: 200;
  border-top: 1px solid #ffffff;
  margin-left: 0;
  margin-top: 30px;
  padding-left: 0;
  padding-top: 30px;
}

.footer-nav li
{
  display: inline;
  padding-right: 15px;
  padding-left: 10px;
  border-right: 1px solid #ffffff
;
}

.footer-nav li:last-of-type
{
  border-right: none;
}

@media (max-width: 991px)
{
  .footer-nav li
  {
    display: block;
    border-right: none;
  }
}

.footer-nav li a
{
  color: white;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.footer-nav li a:hover,.footer-nav li a:focus,.footer-nav li a:active
{
  color: #ffffff;
  text-decoration: none;
}

.footer-copyright
{
  color: white;
  font-size: 14px;
  font-weight: 300;
  margin-top: 10px;
}

.home-section-1
{
  background-image: url(img/1-welcome.jpg);
  background-color: #599a98;
  background-size: 100% 1000px;
  background-repeat: no-repeat;
  padding-top: 50px;
  padding-bottom: 50px;
}

@media (max-width: 1920px) and (min-width: 1700px)
{
  .home-section-1
  {
    background-size: 100% 1100px !important;
  }
}

@media (max-width: 1190px)
{
  .home-section-1
  {
    background-size: auto 100% !important;
    padding-bottom: 150px;
  }
}

@media (max-width: 991px)
{
  .home-section-1
  {
    padding: 60px 0 60px;
  }
}

.home-section-1 .col-md-6
{
  height: 500px;
  position: relative;
}

@media (max-width: 1920px) and (min-width: 1700px)
{
  .home-section-1 .col-md-6
  {
    height: 550px;
  }
}

@media (max-width: 991px)
{
  .home-section-1 .col-md-6
  {
    height: auto;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.home-section-1 .secondSection
{
  height: 300px;
  margin-bottom: 100px;
}

@media (max-width: 1920px) and (min-width: 1700px)
{
  .home-section-1 .secondSection
  {
    margin-bottom: 150px;
  }
}

@media (max-width: 991px)
{
  .home-section-1 .secondSection
  {
    margin-bottom: 0px;
    padding-bottom: 240px;
  }
}

.home-section-1 .heading
{
  margin: 0 0 20px;
}

@media (max-width: 991px)
{
  .mobileLift
  {
    margin-top: -150px;
  }
}

.home-section-bg
{
  /* background-image: url(img/blurry.jpg); */
  background-repeat: no-repeat;
  background-position: center center !important;
  background-size: cover !important;
  /* padding-top: -50px; */
  height: 650px;
  position: relative;
}

@media (max-width: 1190px)
{
  .home-section-bg
  {
    height: 650px !important;
    background-size: 139% auto !important;
  }
  

}

@media (max-width: 991px)
{
  .home-section-bg
  {
    height: 1000px !important;
    background-size: auto 700px !important;
    padding: 0;
  }

}

@media (max-width: 600px)
{
  .home-section-bg
  {
    height: 1000px !important;
    /* I edited this line from 529 to 1000px */
    background-size: auto 600px !important;
    padding: 0;
  }

}

@media (min-width: 1800px)
{
  .home-section-bg
  {

    background-size: auto 1200px;
    height: 800px;
  }
}

@media (max-width: 1920px) and (min-width: 1100px)
{
  .home-section-bg .saraimargin
  {
    margin-top: 100px !important;
    /* I added this */
  }
}




















/* .home-section-bg .col-md-6
{
  position: relative;
  height: auto !important;
} */








.home-section-bg .ring-col
{
  margin-top: 60px;
}

@media (max-width: 990px)
{
  .home-section-bg .ring-col
  {
    display: none !important;
  }
}

.home-section-bg .heading
{
  font-size: 50px;
  line-height: 52px;
  color: #a7226e
;
  margin-top: 50px;
  letter-spacing: -2px !important;
}

@media (max-width: 1199px)
{
  .home-section-bg .heading
  {
    font-size: 38px;
    line-height: 42px;
    margin-top: 0px;
    letter-spacing: -1px !important;
  }
}

@media (max-width: 991px)
{
  .home-section-bg .heading
  {
    font-size: 38px;
    line-height: 42px;
    margin-top: 10px;
  }

  .home-section-bg .heading br
  {
    display: none;
  }
}

.home-section-bg .section-content
{
  /* display: table-cell; */
  /* vertical-align: top; */
  width: 100%;
  max-width: 100%;
}

.home-section-bg .secondSection
{
  height: 300px;
  margin-bottom: 100px;
}

@media (max-width: 1920px) and (min-width: 1700px)
{
  .home-section-bg .secondSection
  {
    margin-bottom: 150px;
  }
}

@media (max-width: 991px)
{
  .home-section-bg .secondSection
  {
    margin-bottom: 0px;
    padding-bottom: 240px;
  }
}

.home-section-bg .whale-curve-bottom
{
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: auto;
  filter: brightness(0) saturate(100%) invert(20%) sepia(35%) saturate(5125%) hue-rotate(305deg) brightness(90%) contrast(92%);
  z-index: 9;

}

.home-section-bg .whale-curve-top
{
  position: absolute;
  top: -1px;
  left: 0;
    filter:brightness(0) saturate(100%) invert(20%) sepia(35%) saturate(5125%) hue-rotate(305deg) brightness(90%) contrast(92%);
  z-index: 9;

}

.home-section-qpr
{
  background-image: url(img/qpr.jpg);
  background-repeat: no-repeat;
  background-position: center center !important;
  background-size: 100% auto !important;
  padding-top: 50px;
  height: 800px;
  position: relative;
}

@media (max-width: 1190px)
{
  .home-section-qpr
  {
    height: 700px !important;
  }
}

@media (max-width: 991px)
{
  .home-section-qpr
  {
    height: 500px !important;
    background-size: auto 750px !important;
    padding: 0;
  }
}

@media (max-width: 600px)
{
  .home-section-qpr
  {
    height: 400px !important;
    background-size: 627px auto !important;
    background-position: center top !important;
    padding: 0;
  }
}

.home-section-qpr .col-md-6
{
  position: relative;
  height: auto !important;
}

.home-section-qpr .ring-col
{
  margin-top: 60px;
}

@media (max-width: 991px)
{
  .home-section-qpr .ring-col
  {
    display: none !important;
  }
}

.home-section-qpr .heading
{
  font-size: 52px;
  line-height: 52px;
  color: #3b78b7
;
  margin-top: 10px;
  letter-spacing: -2px !important;
}

@media (max-width: 1190px)
{
  .home-section-qpr .heading
  {
    font-size: 42px;
    line-height: 42px;
    margin-top: 0px;
    letter-spacing: -1px !important;
  }
}

@media (max-width: 991px)
{
  .home-section-qpr .heading
  {
    font-size: 32px;
    line-height: 32px;
    margin-top: 10px;
  }
}

.home-section-qpr .section-content
{
  display: table-cell;
  vertical-align: top;
  width: 100%;
}

.home-section-qpr .secondSection
{
  height: 300px;
  margin-bottom: 100px;
}

@media (max-width: 1920px) and (min-width: 1700px)
{
  .home-section-qpr .secondSection
  {
    margin-bottom: 150px;
  }
}

@media (max-width: 990px)
{
  .home-section-qpr .secondSection
  {
    margin-bottom: 0px;
    padding-bottom: 240px;
  }
}

@media (max-width: 991px)
{
  .home-section-qpr .qpr-col
  {
    padding-top: 20px;
  }
}

.home-section-qpr .qpr-col a
{
  color: #a7226e
;
  text-decoration: underline !important;
}

.home-section-qpr .qpr-col a:hover
{
  text-decoration: none !important;
}

@media (max-width: 991px)
{
  .home-section-qpr .qpr-col a
  {
    font-size: 14px;
  }
}

.home-section-qpr .whale-curve-bottom
{
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: auto;
}

.home-section-qpr .whale-curve-top
{
  position: absolute;
  top: -1px;
  left: 0;
}

.qpr-crest
{
  max-width: 150px;
  width: 100%;
  margin: 0 auto;
  display: block;
}

@media (max-width: 991px)
{
  .qpr-crest
  {
    margin-bottom: 20px;
  }
}

@media (max-width: 991px)
{
  .mobileLift
  {
    margin-top: -150px;
  }
}

@media (min-width: 990px)
{
  .whale-curve-bottom
  {
    width: 100%;
  }

  .whale-curve-top
  {
    width: 100%;
  }
}

.home-section-whale--bottom
{
  
  background-repeat: no-repeat;
  background-position: center top !important;
  background-size: auto 1000px;
  padding-top: 50px;
  height: 280px;
  position: relative;
  align-items: center;
  align-content: center;
}

@media (max-width: 1190px)
{
  .home-section-whale--bottom
  {
    background-size: auto 800px !important;
    padding-top: 50px;
    padding-bottom: 0;
  }
}

@media (max-width: 991px)
{
  .home-section-whale--bottom
  {
    background-size: auto 600px !important;
    padding-top: 70px;
  }
}

@media (min-width: 1800px)
{
  .home-section-whale--bottom
  {
    background-size: auto 1200px;
  }
}

.home-section-whale--bottom .col-md-6
{
  position: relative;
  height: auto !important;
}

.home-section-whale--bottom .ring-col
{
  margin-top: 60px;
}

@media (max-width: 991px)
{
  .home-section-whale--bottom .ring-col
  {
    display: none !important;
  }
}

@media (max-width: 991px)
{
  .home-section-whale--bottom .rings-quote
  {
    margin-top: -100px;
  }
}

.home-section-whale--bottom .section-content
{
  display: table-cell;
  vertical-align: top;
  width: 100%;
}

.home-section-whale--bottom .heading
{
  margin: 0 0 20px;
}

@media (max-width: 991px)
{
  .home-section-whale--bottom .heading
  {
    margin-top: -70px;
  }
}

@media (max-width: 991px)
{
  .mobileLift
  {
    margin-top: -150px;
  }
}

.home-section-qpr--bottom
{
  background-color: #6a458e;
  background-repeat: no-repeat;
  background-position: center top !important;
  background-size: auto 1000px;
  padding-top: 50px;
  position: relative;
}

@media (max-width: 1190px)
{
  .home-section-qpr--bottom
  {
    background-size: auto 800px !important;
    padding-top: 50px;
    padding-bottom: 0;
  }
}

@media (max-width: 991px)
{
  .home-section-qpr--bottom
  {
    background-size: auto 600px !important;
    padding-top: 70px;
  }
}

@media (min-width: 1800px)
{
  .home-section-qpr--bottom
  {
    background-size: auto 1200px;
  }
}

.home-section-qpr--bottom .col-md-6
{
  position: relative;
  height: 500px;
}

.home-section-qpr--bottom .ring-col
{
  margin-top: 60px;
}

@media (max-width: 990px)
{
  .home-section-qpr--bottom .ring-col
  {
    display: none !important;
  }
}

@media (max-width: 991px)
{
  .home-section-qpr--bottom .rings-quote
  {
    margin-top: -100px;
  }
}

.home-section-qpr--bottom .section-content
{
  display: table-cell;
  vertical-align: top;
  width: 100%;
  max-width: 100%;
}

.home-section-qpr--bottom .heading
{
  margin: 0 0 20px;
}

@media (max-width: 991px)
{
  .mobileLift
  {
    margin-top: -150px;
  }
}

.mobileLift .whale-curve-bottom
{
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: auto;
}

.home-section-1_2
{
  background-image: url(img/1-welcome-2.jpg);
  background-color: #599a98;
  background-size: 100% 1000px;
  background-repeat: no-repeat;
  padding-top: 50px;
  padding-bottom: 50px;
}

@media (max-width: 1190px)
{
  .home-section-1_2
  {
    background-size: auto 100% !important;
    padding-top: 100px;
  }
}

@media (max-width: 991px)
{
  .home-section-1_2
  {
    padding: 60px 0 0;
  }
}

.home-section-1_2 .col-md-6
{
  height: 500px;
  position: relative;
}

@media (max-width: 991px)
{
  .home-section-1_2 .col-md-6
  {
    height: auto;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.home-section-1_2 .secondSection
{
  height: 300px;
  margin-bottom: 100px;
}

@media (max-width: 991px)
{
  .home-section-1_2 .secondSection
  {
    margin-bottom: 0px;
    padding-bottom: 240px;
  }
}

.home-section-1_2 .heading
{
  margin: 0 0 20px;
}

.home-section-1_5
{
  background-color: #599a98;
  padding-bottom: 100px;
}

@media (max-width: 991px)
{
  .home-section-1_5
  {
    padding: 0;
  }
}

.home-section-1_5 .col-md-6
{
  height: 250px;
  position: relative;
}

@media (max-width: 991px)
{
  .home-section-1_5 .col-md-6
  {
    height: auto;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.home-section-2
{
  background-color: #599a98;
  padding-bottom: 100px;
}

@media (max-width: 1190px)
{
  .home-section-2
  {
    background-size: auto 100% !important;
  }
}

@media (max-width: 991px)
{
  .home-section-2
  {
    padding: 0;
  }
}

.home-section-2 .col-md-6
{
  height: 350px;
  position: relative;
}

.home-section-2--whale
{
  background-image: url('img/1-helloeweresolarplicity-whale.png');
  background-size: 100% 100px !important;
  background-repeat: no-repeat;
  background-color: #599a98;
  padding-bottom: 0px;
  padding-top: 60px;
}

@media (max-width: 1190px)
{
  .home-section-2--whale
  {
    background-size: auto 100% !important;
    padding-top: 100px;
  }
}

.home-section-2--whale .col-md-6
{
  height: 350px;
  position: relative;
}

.home-section-3
{
  background-image: url(img/money_background.png);
  background-size: 100% 790px;
  background-repeat: no-repeat;
  padding: 0 0 60px;
}

@media (max-width: 1190px)
{
  .home-section-3
  {
    background-size: auto 100% !important;
    padding: 0 0 60px;
  }
}

.home-section-3 .pig-image
{
  display: block;
  height: auto;
  margin-bottom: 0;
  margin-left: auto;
  max-width: 300px;
  margin-right: auto;
}

.home-section-3 .col-md-6
{
  height: 220px;
  display: table;
}

.home-section-3 .section-content
{
  vertical-align: top;
}

.home-section-4
{
  background: #a7226e
;
  padding: 0;
}

@media (max-width: 1190px)
{
  .home-section-4
  {
    background-position: left top;
    padding-top: 60px;
    padding-bottom: 0;
  }
}

@media (max-width: 991px)
{
  .home-section-4
  {
    padding-bottom: 60px;
  }
}

.home-section-4 .col-md-6
{
  height: 500px;
  display: table;
}

@media (max-width: 1190px)
{
  .home-section-4 .col-md-6
  {
    height: auto !important;
    padding: 0;
  }
}

.home-section-4 .globe-image
{
  margin-top: 100px;
}

@media (max-width: 1190px)
{
  .home-section-4 .globe-image
  {
    margin-top: 0;
  }
}

@media (max-width: 990px)
{
  .home-section-4 .globe-image
  {
    margin-bottom: 40px;
    margin-top: 0;
  }
}

.home-section-5
{
  padding: 0;
}

@media (max-width: 991px)
{
  .home-section-5
  {
    background: url(img/4-photobg.jpg) no-repeat;
    background-position: center top;
    background-size: auto 600px !important;
    height: 600px !important;
    padding: 0;
  }

  .home-section-5 img
  {
    display: none;
  }
}

.home-section-5 .section-circle
{
  padding-top: 65px;
  font-size: 24px;
  font-weight: 300;
  margin: 0 auto;
}

@media (min-width: 1600px)
{
  .home-section-5 .section-circle
  {
    font-size: 36px;
    padding-top: 85px;
  }
}

.home-section-6
{
  background-size: 100% 600px !important;
  background-image: url(img/5-alwayshappytohelp.jpg);
  padding: 0px 0 150px;
}

@media (max-width: 1190px)
{
  .home-section-6
  {
    background-size: auto 100% !important;
    padding: 0px 0 220px;
  }
}

@media (max-width: 960px)
{
  .home-section-6
  {
    padding: 100px 0 360px;
  }
}

.home-section-6 .col-md-6
{
  height: 450px;
  display: table;
}

@media (max-width: 1190px)
{
  .home-section-6 .col-md-6
  {
    height: 600px;
  }
}

.home-section-6 .section-image
{
  max-width: 400px;
}

.home-section-7
{
  background: #a7226e
;
}

@media (max-width: 1190px)
{
  .home-section-7 .container
  {
    margin-top: -250px;
    padding: 0;
  }
}

.home-section-7 .col-md-6
{
  height: 500px;
  position: relative;
}

@media (max-width: 990px)
{
  .home-section-7 .col-md-6
  {
    margin-top: 0px;
    height: auto;
    padding: 0;
  }
}

.home-section-qpr-3
{
  background-size: 100% 130px !important;
  background-image: url(img/qpr-background.jpg);
  background-repeat: no-repeat;
  padding: 150px 0 150px;
}

@media (max-width: 1199px)
{
  .home-section-qpr-3
  {
    background-size: 100% auto !important;
    padding: 150px 0 150px;
  }
}

.home-section-qpr-3 .col-md-6
{
  height: 500px;
  position: relative;
}

@media (max-width: 991px)
{
  .home-section-qpr-3 .col-md-6
  {
    margin-top: 0px;
    height: auto;
    padding: 0;
  }
}

.home-alt-section-1-fair
{
  background-image: url(img/fair-bg.jpg);
  background-color: #599a98;
  background-size: 100% 680px;
  background-repeat: no-repeat;
}

@media (max-width: 1190px)
{
  .home-alt-section-1-fair
  {
    background-size: auto 100% !important;
  }
}

@media (max-width: 991px)
{
  .home-alt-section-1-fair
  {
    padding: 60px 0 0;
  }
}

.home-alt-section-1-fair .col-md-7
{
  height: 680px;
  position: relative;
  padding-bottom: 90px;
  display: table;
}

@media (max-width: 991px)
{
  .home-alt-section-1-fair .col-md-7
  {
    height: auto;
    margin: 0 auto !important;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.home-alt-section-1-fair .col-md-5
{
  height: 680px;
  position: relative;
  margin: 0 auto !important;
  padding-bottom: 90px;
  display: table;
}

@media (max-width: 990px)
{
  .home-alt-section-1-fair .col-md-5
  {
    height: auto;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.home-alt-section-1-fair .heading
{
  margin: 0 0 20px;
}

.home-alt-section-1-LED
{
  background-image: url(img/led-bg.jpg);
  background-color: #599a98;
  background-size: 100% 680px;
  background-repeat: no-repeat;
  color: #a7226e
 !important;
}

@media (max-width: 1190px)
{
  .home-alt-section-1-LED
  {
    background-size: auto 100% !important;
  }
}

@media (max-width: 991px)
{
  .home-alt-section-1-LED
  {
    padding: 60px 0 0;
  }
}

.home-alt-section-1-LED .col-md-7
{
  height: 680px;
  position: relative;
  padding-bottom: 90px;
  display: table;
}

@media (max-width: 990px)
{
  .home-alt-section-1-LED .col-md-7
  {
    height: auto;
    padding-top: 0;
    margin: 0 auto !important;
    padding-bottom: 0;
  }
}

.home-alt-section-1-LED .col-md-5
{
  height: 680px;
  position: relative;
  padding-bottom: 90px;
  display: table;
}

@media (max-width: 990px)
{
  .home-alt-section-1-LED .col-md-5
  {
    height: auto;
    padding-top: 0;
    margin: 0 auto !important;
    padding-bottom: 0;
  }
}

.home-alt-section-1-LED .heading
{
  margin: 0 0 20px;
}

.home-alt-section-1-saving
{
  background-image: url(img/saving-bg.jpg);
  background-color: #599a98;
  background-size: 100% 680px;
  background-repeat: no-repeat;
  color: #a7226e
 !important;
}

@media (max-width: 1190px)
{
  .home-alt-section-1-saving
  {
    background-size: auto 100% !important;
  }
}

@media (max-width: 990px)
{
  .home-alt-section-1-saving
  {
    padding: 60px 0 0;
  }
}

.home-alt-section-1-saving .col-md-7
{
  height: 680px;
  position: relative;
  padding-bottom: 90px;
  display: table;
}

@media (max-width: 990px)
{
  .home-alt-section-1-saving .col-md-7
  {
    height: auto;
    padding-top: 0;
    padding-bottom: 0;
    margin: 0 auto !important;
  }
}

.home-alt-section-1-saving .col-md-5
{
  height: 680px;
  position: relative;
  padding-bottom: 90px;
  display: table;
}

@media (max-width: 990px)
{
  .home-alt-section-1-saving .col-md-5
  {
    height: auto;
    padding-top: 0;
    padding-bottom: 0;
    margin: 0 auto !important;
  }
}

.home-alt-section-1-saving .heading
{
  margin: 0 0 20px;
}

.home-alt-section-1-renewable
{
  background-image: url(img/renewable-bg.jpg);
  background-color: #599a98;
  background-size: 100% 680px;
  background-repeat: no-repeat;
}

@media (max-width: 1190px)
{
  .home-alt-section-1-renewable
  {
    background-size: auto 100% !important;
  }
}

@media (max-width: 991px)
{
  .home-alt-section-1-renewable
  {
    padding: 60px 0 0;
  }
}

.home-alt-section-1-renewable .col-md-7
{
  height: 680px;
  position: relative;
  padding-bottom: 90px;
  display: table;
}

@media (max-width: 990px)
{
  .home-alt-section-1-renewable .col-md-7
  {
    height: auto;
    padding-top: 0;
    margin: 0 auto !important;
    padding-bottom: 0;
  }
}

.home-alt-section-1-renewable .col-md-5
{
  height: 680px;
  position: relative;
  padding-bottom: 90px;
  display: table;
}

@media (max-width: 990px)
{
  .home-alt-section-1-renewable .col-md-5
  {
    height: auto;
    padding-top: 0;
    margin: 0 auto !important;
    padding-bottom: 0;
  }
}

.home-alt-section-1-renewable .heading
{
  margin: 0 0 20px;
}

.home-alt-section-1
{
  background-color: #599a98;
  background-size: 100% 500px;
  background-repeat: no-repeat;
  padding-top: 0px;
  padding-bottom: 50px;
}

@media (max-width: 1190px)
{
  .home-alt-section-1
  {
    background-size: auto 100% !important;
  }
}

@media (max-width: 991px)
{
  .home-alt-section-1
  {
    padding: 60px 0 0;
  }
}

.home-alt-section-1 .col-md-6
{
  height: 500px;
  position: relative;
}

@media (max-width: 990px)
{
  .home-alt-section-1 .col-md-6
  {
    height: auto;
    padding-top: 0;
    padding-bottom: 0;
    margin: 0 auto !important;
  }
}

.home-alt-section-1 .heading
{
  margin: 0 0 20px;
}

.heading--hero
{
  font-size: 54px;
  text-align: left;
}

@media (max-width: 991px)
{
  .heading--hero
  {
    font-size: 42px !important;
  }
}

.heading--hero-LED
{
  font-size: 54px;
  text-align: left;
  color: #a7226e
;
}

@media (max-width: 991px)
{
  .home-video
  {
    margin-bottom: 20px;
  }
}

.home-section-kettering
{
  background-image: url(img/kettering.jpg);
}

.kettering-subhead
{
  color: #a7226e
;
  line-height: 1;
  font-size: 18px;
}

@media (max-width: 990px)
{
  .kettering-subhead
  {
    font-size: 13px;
  }
}

.about-section-1
{
  background: url(img/1-header.jpg) no-repeat;
  background-size: 100% 600px;
} 

@media (max-width: 1190px)
{
  .about-section-1
  {
    background-size: auto 100% !important;
  }
}

@media (max-width: 990px)
{
  .about-section-1
  {
    padding-top: 100px;
    padding-bottom: 0;
  }
}

.about-section-1 .col-md-6
{
  height: 600px;
  position: relative;
}

@media (max-width: 990px)
{
  .about-section-1 .col-md-6
  {
    padding: 0 20px;
  }
}

.about-section-2
{
  padding: 0;
}

@media (max-width: 800px)
{
  .about-section-2
  {
    background: url(img/jump.jpg) no-repeat;
    background-position: center top;
    background-size: auto 600px !important;
    height: 600px !important;
    padding: 0;
  }

  .about-section-2 img
  {
    display: none;
  }
}

.about-section-2 .col-md-6
{
  position: relative;
}

@media (min-width: 1600px)
{
  .about-section-2 .section-circle
  {
    font-size: 30px;
    padding-top: 50px;
  }
}

.about-section-3
{
  background: #6193C6;
}

.about-section-3 .col-md-6
{
  height: 500px;
  display: table;
}

.about-section-4
{
  background: url(img/3-energy-advice.jpg) no-repeat;
  background-size: 100% 500px;
}

@media (max-width: 1190px)
{
  .about-section-4
  {
    background-size: auto 100% !important;
    background-position: center top;
  }
}

.about-section-4 .col-md-6
{
  height: 500px;
  display: table;
}

.about-section-4 .section-image
{
  height: 430px;
  margin-top: 72px;
}

@media (max-width: 1190px)
{
  .about-section-4 .section-image
  {
    margin-top: 180px;
  }
}

@media (max-width: 990px)
{
  .about-section-4 .section-image
  {
    height: auto;
    margin-top: 0;
  }
}

.about-section-5
{
  background: #5E9C9B;
}

.about-section-5 .col-md-6
{
  height: 500px;
  display: table;
}

.about-section-6
{
  background: url(img/4-gotafeedintariff.jpg) no-repeat;
  background-size: 100% 500px;
}

@media (max-width: 1190px)
{
  .about-section-6
  {
    padding: 60px 0px 100px;
    background-size: auto 100% !important;
    background-position: left top;
  }
}

.about-section-6 .col-md-6
{
  height: 500px;
  display: table;
}

.about-section-7
{
  background: #a7226e
;
}

.about-section-7 .container
{
  padding-top: 0;
}

.about-section-7 .col-md-6
{
  height: 600px;
  position: relative;
}

@media (max-width: 990px)
{
  .about-section-7 .col-md-6
  {
    padding-top: 100px;
    padding-bottom: 0;
  }
}

.about-section-video
{
  background: url(img/video-bg.jpg) no-repeat;
  background-size: 100% 500px;
  background-color: #3e2255;
  padding-top: 200px;
  padding-bottom: 50px;
}

@media (max-width: 1190px)
{
  .about-section-video
  {
    padding: 200px 0px 60px;
    background-position: right top;
    background-size: 100% !important;
  }
}

@media (max-width: 990px)
{
  .about-section-video .col-md-4
  {
    margin-bottom: 40px;
  }
}

.about-section-video .col-md-6
{
  height: 500px;
  display: table;
}

.maintenance-form p
{
  line-height: 1em !important;
}

.advice-section-1
{
  background: url(img/1-header.jpg) no-repeat;
  background-size: 100% 600px;
}

.advice-section-1 .container
{
  height: 600px;
  padding-top: 0;
}

.advice-section-1 .row
{
  height: 600px;
}

.advice-section-1 .col-md-6
{
  height: 600px;
  position: relative;
}

.advice-section-2
{
  background: #6193C6;
}

.advice-section-2 .container
{
  height: 400px;
}

.advice-section-2 .row
{
  height: 400px;
}

.advice-section-2 .col-md-6
{
  height: 400px;
  display: table;
}

.advice-section-3
{
  background: url(img/2-energyadvicemadesimple.jpg) no-repeat;
  background-size: 100% 460px;
  padding-top: 100px;
}

.advice-section-3 .container
{
  padding-top: 0;
}

.advice-section-3 .col-md-6
{
  height: 360px;
  position: relative;
}

.advice-section-4
{
  background: url(img/3-allyouneedtoknow.jpg) no-repeat;
  background-size: 100% 1000px;
  padding-top: 100px;
}

.advice-section-4 .container
{
  height: auto;
  padding-top: 0;
}

.advice-section-4 .row
{
  height: auto;
}

.advice-section-4 .col-md-6
{
  height: auto;
  position: relative;
}

.advice-section-5
{
  background: url(img/4-stillnot100happy.jpg) no-repeat;
  background-size: 100% 600px;
}

.advice-section-5 .container
{
  height: 600px;
  padding-top: 0;
}

.advice-section-5 .row
{
  height: 600px;
}

.advice-section-5 .col-md-6
{
  height: 600px;
  position: relative;
}

.advice-section-6
{
  background: #3D2154;
  padding: 40px 0;
}

.advice-section-6 .col-md-6
{
  display: block !important;
}

.advice-section-6 .section-text
{
  display: block !important;
  width: 100%;
  max-width: 1000px !important;
}

.advice-section-6 .section-content
{
  display: block !important;
}

.advice-section-7
{
  background: url(img/5-typechecksavesimple.jpg) no-repeat;
  background-size: 100% 550px;
}

.advice-section-7 .container
{
  height: 550px;
  padding-top: 0;
}

.advice-section-7 .col-md-6
{
  height: 550px;
  position: relative;
}

.help-section-1
{
  /* background: url(img/1a-gotaquestion.jpg) no-repeat; */
  background-size: 100% 500px;
}

.help-section-1 .container
{
  height: 500px;
  padding-top: 0;
}

.help-section-1 .row
{
  height: 500px;
}

.help-section-1 .col-md-6
{
  height: 500px;
  position: relative;
}

/* .help-section-2
{
  background: #599a98;
} */

.help-section-2 .container
{
  height: auto;
  padding-top: 0;
  max-width: 900px;
}

.help-section-2 .col-md-4
{
  height: 400px;
  padding: 0 20px;
  position: relative;
  margin-bottom: 40px;
}

@media (max-width: 800px)
{
  .help-section-2 .col-md-4
  {
    height: 220px;
  }
}

.help-section-2 .col-md-4 img
{
  width: 100%;
  height: auto;
}

.help-section-2 .heading
{
  height: 80px;
}

@media (max-width: 960px)
{
  .help-section-2 .heading
  {
    height: auto;
    margin-bottom: 20px;
  }
}

.help-section-2 .section-image--help
{
  max-width: 300px;
  height: auto;
}

/* x */

.help-section-22 .container
{
  height: auto;
  padding-top: 0;
  max-width: 900px;
}

.help-section-22 .col-md-4
{
  height: 400px;
  padding: 0 20px;
  position: relative;
  margin-bottom: 40px;
}

@media (max-width: 800px)
{
  .help-section-22 .col-md-4
  {
    height: 300px;
  }
}

.help-section-22 .col-md-4 img
{
  width: 100%;
  height: auto;
}

.help-section-22 .heading
{
  height: 80px;
}

@media (max-width: 960px)
{
  .help-section-22 .heading
  {
    height: auto;
    margin-bottom: 20px;
  }
}

.help-section-22 .section-image--help
{
  max-width: 300px;
  height: auto;
}

/* x */

.help-section-3
{
  background: url(img/2a-emergencies.jpg) no-repeat;
  background-size: 100% 700px;
}

@media (max-width: 1190px)
{
  .help-section-3
  {
    background-size: auto 100% !important;
    background-position: right top;
    padding-bottom: 100px;
  }
}

@media (max-width: 960px)
{
  .help-section-3
  {
    padding-bottom: 150px;
    padding-top: 0;
    background-position: left top;
  }
}

.help-section-3 .heading a:hover
{
  color: #a7226e
 !important;
}

.help-section-3 .container
{
  padding-top: 0;
}

.help-section-3 .red-light
{
  max-width: 150px !important;
  margin-top: 10px;
}

@media (max-width: 800px)
{
  .help-section-3 .red-light
  {
    max-width: 150px;
    margin-top: -0px;
  }
}

.help-section-3 .col-md-6
{
  height: 250px;
  margin-bottom: 250px;
  position: relative;
}

@media (max-width: 960px)
{
  .help-section-3 .col-md-6
  {
    margin-bottom: 0px;
  }
}

@media (max-width: 600px)
{
  .help-grid-item
  {
    height: 100px;
  }
}

@media (max-width: 600px)
{
  .help-grid-item .download-button
  {
    padding: 7px 10px;
    width: 100%;
  }
}

.help-grid-item .heading
{
  margin-top: 10px;
}

@media (max-width: 600px)
{
  .help-grid-item .heading
  {
    height: 50px;
    font-size: 20px;
  }
}

@media (max-width: 800px)
{
  .help-grid-item img
  {
    width: auto !important;
    margin: 0 auto;
    max-height: 200px;
    padding: 10px;
  }
}

.help-grid-item .section-content
{
  width: 100% !important;
  display: block;
}

/* promises section */

@media (max-width: 600px)
{
  .help-grid-item2
  {
    height: 100px;
  }
}

@media (max-width: 600px)
{
  .help-grid-item2 .download-button
  {
    padding: 7px 10px;
    width: 100%;
  }
}

.help-grid-item2 .heading
{
  margin-top: 10px;
}

@media (max-width: 600px)
{
  .help-grid-item2 .heading
  {
    height: 50px;
    font-size: 20px;
  }
}

@media (max-width: 800px)
{
  .help-grid-item2 img
  {
    width: auto !important;
    margin: 0 auto;
    max-height: 120px;
  }
}

.help-grid-item2 .section-content
{
  width: 100% !important;
  display: block;
}


/* promises end */



.help-child-section-1
{
  /* background: url(img/1-questionsaboutxyz.jpg) no-repeat; */
  background-size: 100% 400px;

}

.help-child-section-1 .col-md-6
{
  height: 400px;
  position: relative;
}

.help-child-section-2
{
  background: #599a98;
}

.help-child-section-2 .col-md-6
{
  height: auto;
  position: relative;
}

.help-child-section-3
{
  background: url(img/2-questionsabout.jpg) no-repeat;
  background-size: 100% 150px;
  background-color: #3d2154;
  padding-top: 120px;
  padding-bottom: 0;
}

@media (max-width: 1190px)
{
  .help-child-section-3
  {
    background-size: 100% auto !important;
  }
}

.help-child-section-3 .container
{
  max-width: 900px;
}

.help-child-section-3 .col-md-4
{
  height: 400px;
  position: relative;
  margin-bottom: 40px;
  padding: 0 20px;
}

@media (max-width: 800px)
{
  .help-child-section-3 .col-md-4
  {
    height: 220px;
  }
}

.help-child-section-3 .section-image--help
{
  max-width: 200px;
  height: auto;
}

.help-child-section-3 .heading
{
  height: 80px;
}

@media (max-width: 800px)
{
  .help-child-section-3 .heading
  {
    height: auto;
    margin-bottom: 20px;
  }
}

.contact-section-1
{
  background: url(img/1-wereheretohelp.jpg) no-repeat;
  background-size: 100% 600px;
}

@media (max-width: 1190px)
{
  .contact-section-1
  {
    background-size: auto 100% !important;
  }
}

.contact-section-1 .container
{
  height: 600px;
  padding-top: 0;
}

.contact-section-1 .row
{
  height: 600px;
}

.contact-section-1 .col-md-6
{
  height: 600px;
  position: relative;
}

.contact-section-2
{
  background: url(img/2-ringring.jpg) no-repeat;
  background-size: 100% 500px;
}

@media (max-width: 1190px)
{
  .contact-section-2
  {
    background-size: auto 100% !important;
  }
}

@media (max-width: 960px)
{
  .contact-section-2
  {
    padding: 20px 0 20px !important;
    background-position: center top;
  }
}

.contact-section-2 .container
{
  height: 500px;
  padding-top: 0;
}

.contact-section-2 .row
{
  height: 500px;
}

.contact-section-2 .col-md-6
{
  height: 500px;
  position: relative;
}

.contact-section-3
{
  background: url(img/3-pingusamessage.jpg) no-repeat;
  background-size: 100% 600px;
}

@media (max-width: 1190px)
{
  .contact-section-3
  {
    background-size: auto 100% !important;
  }
}

@media (max-width: 960px)
{
  .contact-section-3
  {
    padding: 50px 0 00px !important;
  }
}

.contact-section-3 .container
{
  height: 600px;
  padding-top: 0;
}

.contact-section-3 .row
{
  height: 600px;
}

.contact-section-3 .col-md-6
{
  height: 600px;
  position: relative;
}

.contact-section-4
{
  background: url(img/4-findushere.jpg) no-repeat;
  background-size: 100% 700px;
  padding: 100px 0 60px;
  background-color: #3D2154;
}

@media (max-width: 1190px)
{
  .contact-section-4
  {
    background-size: 100% 100% !important;
    background-position: right top;
  }
}

@media (max-width: 960px)
{
  .contact-section-4
  {
    padding: 100px 0 00px !important;
    background-size: auto 100% !important;
  }
}

.contact-section-4 .container
{
  padding-top: 0;
}

.contact-section-4 .section-content
{
  display: block;
}

.contact-section-4 .col-md-4
{
  height: 560px;
  position: relative;
}

@media (max-width: 800px)
{
  .contact-section-4 .col-md-4
  {
    height: auto;
    margin-bottom: 40px;
  }
}

.contact-section-5
{
  background: #3D2154;
  padding-top: 0px;
  height: auto;
  padding-bottom: 0;
}

@media (max-width: 800px)
{
  .contact-section-5
  {
    background-size: auto 100%;
    padding: 0px 0 !important;
  }
}

.contact-section-5 .container
{
  padding-top: 0;
  height: auto;
}

.contact-section-5 .col-md-6
{
  position: relative;
  height: auto;
}

.contact-section-6
{
  background: url(img/5-letsgetsocial.jpg) no-repeat;
  background-size: 100% 740px;
  background-position: center bottom;
  padding: 60px 0;
}

@media (max-width: 1190px)
{
  .contact-section-6
  {
    background-size: auto 100%;
  }
}

@media (max-width: 960px)
{
  .contact-section-6
  {
    background-size: auto 100% !important;
    padding: 0px 0 100px !important;
  }
}

.contact-section-6 .container
{
  padding-top: 0;
}

.contact-section-6 .col-md-6
{
  height: 600px;
  position: relative;
}

.contact-form
{
  text-align: center;
  color: #a7226e
 !important;
}

.contact-form input[type="text"]
{
  width: 100%;
  border-radius: 5px;
  margin-bottom: 10px;
  height: 35px;
  border: 0;
  color: #a7226e
 !important;
  text-align: left;
  padding-left: 10px;
}

.contact-form select
{
  width: 100%;
  border-radius: 5px;
  margin-bottom: 10px;
  height: 35px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: 0;
  color: #999999 !important;
  text-align: left;
  padding-left: 10px;
}

.contact-form input[type="email"]
{
  width: 100%;
  border-radius: 5px;
  margin-bottom: 10px;
  height: 35px;
  border: 0;
  color: #a7226e
 !important;
  text-align: left;
  padding-left: 10px;
}

.contact-form input[type="number"]
{
  width: 100%;
  border-radius: 5px;
  margin-bottom: 10px;
  height: 35px;
  border: 0;
  color: #a7226e
 !important;
  text-align: left;
  padding-left: 10px;
}

.contact-form input[type="tel"]
{
  width: 100%;
  border-radius: 5px;
  margin-bottom: 10px;
  height: 35px;
  border: 0;
  color: #a7226e
 !important;
  text-align: left;
  padding-left: 10px;
}

.contact-form input[type="date"]
{
  width: 100%;
  border-radius: 5px;
  margin-bottom: 10px;
  height: 35px;
  border: 0;
  color: #a7226e
 !important;
  text-align: left;
  padding-left: 10px;
}

.contact-form textarea
{
  width: 100%;
  border-radius: 5px;
  margin-bottom: 0px;
  border: 0;
  height: 150px;
  color: #a7226e
 !important;
  padding-left: 10px;
  text-align: left;
  padding-top: 5px;
}

.contact-form input[type="submit"]
{
  width: 100%;
  border-radius: 5px;
  margin-bottom: 10px;
  height: 35px;
  border: 0;
  color: #a7226e
 !important;
  text-align: left;
  padding-left: 10px;
  background: #c43427;
  width: auto;
  display: inline-block;
  color: white !important;
  padding: 0 30px;
  -webkit-transition: all ease-in-out 0.2s;
  -moz-transition: all ease-in-out 0.2s;
  transition: all ease-in-out 0.2s;
  text-transform: uppercase;
}

.contact-form input[type="submit"]:hover
{
  padding: 0 40px;
}

.contact-form--push
{
  margin-bottom: 100px;
}

@media (max-width: 960px)
{
  .contact-form--push
  {
    margin-bottom: 0;
  }
}

.social
{
  margin-top: 40px;
}

.contact-social img
{
  width: 45px;
}

.contact-image
{
  max-width: 350px;
  width: 100%;
  margin: 0 auto 20px;
}

div.wpcf7-response-output
{
  color: #fff !important;
  border: none !important;
  padding: 20px !important;
  margin: 0 !important;
  font-weight: normal !important;
  font-size: 16px !important;
  margin-bottom: 80px !important;
}

select
{
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: #fff;
}

select:after
{
  content: '<i class="fa fa-2x fa-chevron-down" style="color:#000;" aria-hidden="true"></i>';
}

.section-text--long-form
{
  text-align: left;
  color: white;
  max-width: 100%;
  top: -50px;
}

.section-text--long-form li
{
  color: white;
  line-height: 2em;
  font-weight: 300;
  font-size: 18px;
}

.section--long-form
{
  background: #3e1d55;
  padding: 0px;
}

@media (max-width: 990px)
{
  .section--long-form h1
  {
    margin-top: 100px;
  }
}

.heading--blog
{
  margin-top: -60px;
}

.grid-col
{
  padding-right: 40px;
  padding-left: 40px;
  padding-top: 20px;
  margin-bottom: 40px;
}

@media (max-width: 990px)
{
  .grid-col
  {
    max-width: 450px;
    margin: 0 auto 40px;
  }
}

@media (min-width: 992px)
{
  .grid-col:nth-child(3n+1)
  {
    clear: left;
  }
}

@media (max-width: 991px) and (min-width: 767px)
{
  .grid-col:nth-child(2n+1)
  {
    clear: left;
  }
}

.grid-cell
{
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border-radius: 100%;
  background-size: cover !important;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
}

.blog-description
{
  text-align: center;
  text-decoration: none;
}

.blog--link
{
  text-decoration: none !important;
}

.Blog.grid-cell
{
  border: 5px solid #5f9c9b;
}

.Blog.grid-cell:hover,.Blog.grid-cell:focus,.Blog.grid-cell:active
{
  border: 5px solid #5f9c9b;
}

.News.grid-cell
{
  border: 5px solid #464e51;
}

.News.grid-cell:hover,.News.grid-cell:focus,.News.grid-cell:active
{
  border: 5px solid #464e51;
}

.Press-release.grid-cell
{
  border: 5px solid #c52576;
}

.Press-release.grid-cell:hover,.Press-release.grid-cell:focus,.Press-release.grid-cell:active
{
  border: 5px solid #c52576;
}

.download-button.News
{
  background: #464e51;
}

.download-button.Press-release
{
  background: #c52576;
}

.download-button.Blog
{
  background: #5f9c9b;
}

p.Blog
{
  color: #5f9c9b;
  font-weight: 500;
}

p.News
{
  color: #464e51;
  font-weight: 500;
}

p.Press-release
{
  color: #c52576;
  font-weight: 500;
}

.grid-title
{
  font-size: 20px;
  color: white;
  padding-right: 30px;
  padding-left: 30px;
  text-align: center;
}

.grid-intro
{
  font-size: 14px;
  text-transform: uppercase;
  color: white;
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.grid-cat
{
  font-size: 14px;
  font-weight: 900;
  color: white;
  margin-bottom: 0px;
}

.ratio
{
  box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  position: relative;
  display: block;
}

.ratio--square
{
  padding-bottom: 100%;
}

.ratio--4-3
{
  padding-bottom: 75%;
}

.ratio--16-9
{
  padding-bottom: 56.25%;
}

.ratio--blog
{
  padding-bottom: 34.25%;
}

.blog-top-curve
{
  position: absolute;
  z-index: 10;
  margin-bottom: 0px;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
}

.blog-header-curve
{
  position: absolute;
  z-index: 10;
  margin-top: 0px;
  width: 100%;
  height: auto;
  left: 0;
  bottom: 0;
}

.blog-footer-curve
{
  position: relative;
  z-index: 10;
  width: 100%;
  height: auto;
}

/* .blog--section
{
  background-color: #3e1d55;
} */

.blog--section--top
{
  /* background-color: #3e1d55;
  background-image: url(img/top-curve.png); */
  background-size: 100% 200px;
  padding-top: 100px;
  padding-bottom: 40px !important;
  background-repeat: no-repeat;
}

@media (max-width: 1190px)
{
  .blog--section--top
  {
    background-size: auto 200px !important;
  }
}

@media (max-width: 990px)
{
  .blog--section--top
  {
    padding-bottom: 20px !important;
  }
}

.share-section
{
  background-color: #3e1d55;
  padding-top: 0;
}

.share-button
{
  float: left;
  display: block;
  margin-right: 2px;
  margin-right: 2px;
}

@media (max-width: 990px)
{
  .share-button
  {
    margin-bottom: 40px !important;
  }
}

.a2a_button_facebook
{
  margin-top: -2px;
}

.a2a_button_email
{
  margin-top: -3px;
}

.blog-image--top
{
  position: relative;
  height: 400px;
  width: 400px;
  margin: 0 auto;
}

@media (max-width: 480px)
{
  .blog-image--top
  {
    height: 320px;
    width: 320px;
  }
}

@media (max-width: 1190px)
{
  .blog--section-grid
  {
    padding-top: 200px !important;
  }
}

@media (min-width: 992px)
{
  .blog--image
  {
    height: 20%;
    padding-top: 55px;
  }
}

@media (max-width: 991px) and (min-width: 767px)
{
  .blog--image
  {
    height: 354px;
    padding-top: 39px;
  }
}

@media (max-width: 766px)
{
  .blog--image
  {
    height: 463px;
    padding-top: 39px;
  }
}

@media (max-width: 520px)
{
  .blog--image
  {
    height: 299px;
    padding-top: 39px;
  }
}

@media (max-width: 420px)
{
  .blog--image
  {
    height: 236px;
    padding-top: 39px;
  }
}

.a2a_kit
{
  width: 170px;
  margin: 0 auto;
}

.fuel-section-1
{
  background: url(img/1-helpingourplanet_alt.jpg) no-repeat;
  background-size: 100% 1000px;
  padding-top: 100px;
}

@media (max-width: 1190px)
{
  .fuel-section-1
  {
    background-position: center top !important;
  }
}

@media (max-width: 990px)
{
  .fuel-section-1
  {
    padding-bottom: 500px !important;
  }
}

.fuel-section-1 .col-md-6
{
  position: relative;
  height: 800px;
}

@media (max-width: 990px)
{
  .fuel-section-1 .col-md-6
  {
    height: auto !important;
  }
}

.fuel-section-2
{
  background: #CFEBF9;
}

@media (max-width: 1190px)
{
  .fuel-section-2
  {
    padding-bottom: 0;
    background-size: auto 100%;
    background-position: right top !important;
  }
}

@media (max-width: 990px)
{
  .fuel-section-2
  {
    padding-bottom: 60px;
  }
}

.fuel-section-2 .col-md-6
{
  position: relative;
  padding: 0 !important;
  height: auto;
}

@media (max-width: 990px)
{
  .fuel-section-2 .section-image
  {
    margin-bottom: 0 !important;
  }
}

.fuel-section-2 .heading
{
  margin-top: 60px;
}

@media (max-width: 990px)
{
  .fuel-section-2 .heading
  {
    margin-top: 0;
    height: auto !important;
  }
}

@media (max-width: 960px)
{
  .fuel-section-2 .container
  {
    margin-top: -500px;
  }
}

.fuel-section-3
{
  height: 400px;
  background: url(img/2-renewableenergy_v2.jpg) no-repeat;
  background-size: 100% 400px;
}

@media (max-width: 1190px)
{
  .fuel-section-3
  {
    padding-bottom: 0;
    background-size: auto 100%;
    background-position: center top !important;
  }
}

.fuel-section-3 .col-md-6
{
  position: relative;
  height: 400px;
}

.fuel-section-3 .heading
{
  margin-top: 60px;
}

@media (max-width: 960px)
{
  .fuel-section-3 .heading
  {
    margin-top: 0;
  }
}

.fuel-section-4
{
  padding: 0;
}

@media (max-width: 990px)
{
  .fuel-section-4
  {
    background: url(img/3-photo-bg.jpg) no-repeat;
    background-position: center top;
    background-size: auto 600px !important;
    height: 600px !important;
    padding: 0;
  }

  .fuel-section-4 img
  {
    display: none;
  }
}

.fuel-section-4 .col-md-6
{
  position: relative;
}

.fuel-section-4 .section-circle
{
  font-size: 24px;
  padding-top: 80px;
}

@media (min-width: 1600px)
{
  .fuel-section-4 .section-circle
  {
    font-size: 34px;
    padding-top: 120px;
  }
}

.fuel-section-5
{
  background: #a7226e
;
  padding: 0;
}

.fuel-section-5 .col-md-6
{
  position: relative;
}

.business-section-1
{
  background-image: url(img/1-whatabrilliantbusinessdecision.jpg);
  background-repeat: no-repeat;
  background-size: 100% 600px;
  padding-top: 100px;
  background-color: #6a458ef0;
}

@media (max-width: 1190px)
{
  .business-section-1
  {
    background-size: auto 100% !important;
  }
}

@media (max-width: 991px)
{
  .business-section-1
  {
    padding-top: 100px;
    padding-bottom: 0;
  }
}

.business-section-1 .col-md-6
{
  position: relative;
  height: 500px;
}

@media (max-width: 990px)
{
  .business-section-1 .col-md-6
  {
    height: auto;
    padding: 0;
    margin-bottom: 20px;
  }
}

.business-section-1 .col-md-6 p
{
  width: 100%;
  max-width: 380px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.business-section-1 img
{
  margin-bottom: 30px;
}

.business-section-2
{
  background: url(img/2-yourcompletesolarsolution.jpg) no-repeat;
  background-size: 100% 510px;
  padding-top: 100px;
}

@media (max-width: 1190px)
{
  .business-section-2
  {
    padding-top: 180px;
    background-size: auto 100% !important;
  }
}

@media (max-width: 991px)
{
  .business-section-2
  {
    padding-bottom: 0px;
    padding-top: 100px;
  }
}

.business-section-2 .col-md-6
{
  position: relative;
  height: 400px;
}

.business-section-3
{
  background: url(img/3-afairpriceforbusiness.jpg) no-repeat;
  background-size: 100% 800px;
  padding-top: 0;
}

@media (max-width: 1190px)
{
  .business-section-3
  {
    padding-top: 180px;
    background-size: auto 100% !important;
    background-position: right top;
  }
}

@media (max-width: 991px)
{
  .business-section-3
  {
    padding-top: 80px;
    padding-bottom: 100px;
  }

  .business-section-3 p
  {
    margin-bottom: 0;
  }
}

.business-section-3 .col-md-6
{
  position: relative;
  height: 800px;
}

/* .business-section-4
{
  background: #9C73AD;
} */

@media (max-width: 960px)
{
  .business-section-4
  {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.business-section-4 .col-md-6
{
  position: relative;
  height: 600px;
}

@media (max-width: 960px)
{
  .business-section-4 .col-md-6
  {
    padding-top: 0;
  }
}

.business-section-4 .section-image
{
  margin-bottom: 20px;
}

.business-section-5
{
  padding: 0;
}

@media (max-width: 1190px)
{
  .business-section-5
  {
    padding-top: 0px;
    background-size: auto 100% !important;
  }
}

@media (max-width: 960px)
{
  .business-section-5
  {
    background: url(img/4-photobg.jpg) no-repeat;
    background-position: center top;
    background-size: auto 600px !important;
    height: 600px !important;
    padding: 0;
  }

  .business-section-5 img
  {
    display: none !important;
  }
}

.business-section-5 .col-md-6
{
  position: relative;
}

.business-section-5 .section-circle
{
  font-size: 24px;
  padding-top: 50px;
  font-weight: 300;
  margin-top: 60px;
}

@media (min-width: 1600px)
{
  .business-section-5 .section-circle
  {
    font-size: 36px;
    padding-top: 70px;
  }
}

@media (max-width: 960px)
{
  .business-section-6 .container
  {
    padding-top: 0;
    margin-top: -100px;
  }
}

.business-section-6 .business-button
{
  background: #8c58a4;
  color: #fff !important;
}

.business-section-6 .col-md-6
{
  position: relative;
  height: 500px;
}

@media (max-width: 990px)
{
  .business-section-6 .col-md-6
  {
    height: auto;
    padding: 0;
    margin-bottom: 20px;
  }
}

.business-section-6 .col-md-6 p
{
  width: 100%;
  max-width: 380px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.business-section-6 img
{
  margin-bottom: 30px;
}

.business-child-section-1
{
  background: url(img/1-questionsaboutxyz.jpg) no-repeat;
  background-size: 100% 500px;
  padding-top: 100px;
}

@media (max-width: 1190px)
{
  .business-child-section-1
  {
    background-size: auto 100% !important;
    padding-bottom: 0;
    background-position: left top;
  }
}

.business-child-section-1 .col-md-6
{
  height: 400px;
  position: relative;
}

@media (max-width: 1190px)
{
  .business-child-section-1 .col-md-6
  {
    height: auto;
  }
}

.business-child-section-3
{
  background: url(img/2-questionsabout.jpg) no-repeat;
  background-size: 100% 100%;
  background-color: #3D2154;
  min-height: auto;
  padding-top: 120px;
  padding-bottom: 200px;
}

@media (max-width: 1190px)
{
  .business-child-section-3
  {
    background-size: auto 100% !important;
  }
}

@media (max-width: 991px)
{
  .business-child-section-3
  {
    padding-bottom: 120px;
  }
}

.business-button
{
  background: #a7226e
;
  display: inline-block;
  padding: 10px 20px;
  font-size: 28px;
  font-weight: 600;
  border-radius: 100px;
  max-width: 340px;
  width: 100%;
  margin-bottom: 10px;
}

.business-button:hover
{
  background: #464e51;
  color: #fff;
}

.business-result-container
{
  text-align: center;
  border: 3px solid #a7226e
;
  border-radius: 20px;
  margin-bottom: 20px;
  overflow: hidden;
}

.business-result-container--cheapest
{
  border: 3px solid #464e51;
}

.business-result-container-col
{
  padding: 20px;
  width: 25%;
  float: left;
  height: 120px;
  display: table;
}

@media (max-width: 991px)
{
  .business-result-container-col
  {
    width: 100%;
    float: none;
    height: auto;
    display: block;
    text-align: center;
  }
}

.clearer
{
  display: none;
}

@media (max-width: 991px)
{
  .clearer
  {
    display: block;
  }
}

.business-result-container-col-inner
{
  vertical-align: middle;
  display: table-cell;
}

@media (max-width: 991px)
{
  .business-result-container-col-inner
  {
    display: block;
  }
}

.business-result-container-col-1
{
  border-right: 1px solid #5c376d;
}

@media (max-width: 991px)
{
  .business-result-container-col-1
  {
    border-bottom: 2px solid #5c376d;
    border-right: 0;
  }
}

.business-result-container--cheapest .business-result-container-col-1
{
  background: #464e51;
  border-right: 1px solid #464e51;
}

@media (max-width: 991px)
{
  .business-result-container--cheapest .business-result-container-col-1
  {
    border-bottom: 2px solid #464e51;
  }
}

.business-result-container-col-2
{
  border-right: 1px solid #5c376d;
  border-left: 1px solid #5c376d;
}

@media (max-width: 991px)
{
  .business-result-container-col-2
  {
    width: 50%;
    float: left;
    border-left: 0;
  }
}

.business-result-container--cheapest .business-result-container-col-2
{
  border-left: 1px solid #464e51;
}

@media (max-width: 991px)
{
  .business-result-container--cheapest .business-result-container-col-2
  {
    height: 120px;
  }
}

.business-result-container-col-3
{
  border-right: 1px solid #5c376d;
  border-left: 1px solid #5c376d;
}

@media (max-width: 991px)
{
  .business-result-container-col-3
  {
    width: 50%;
    float: left;
    border-right: 0;
  }

  .business-result-container-col-3:after
  {
    content: '';
    display: block;
    clear: both;
  }
}

@media (max-width: 991px)
{
  .business-result-container--cheapest .business-result-container-col-3
  {
    height: 120px;
  }
}

.business-result-container-col-4
{
  border-left: 1px solid #5c376d;
}

@media (max-width: 991px)
{
  .business-result-container-col-4
  {
    width: 100%;
    border-top: 2px solid #5c376d;
    border-right: 0;
    border-left: 0;
    clear: both;
  }
}

.buisness-result-button
{
  font-size: 24px;
  font-weight: 100;
  text-transform: uppercase;
  color: white;
  background-color: #a7226e
;
  margin-top: 0px;
  margin-bottom: 5px;
  padding: 0px 30px;
  border: none;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

@media (max-width: 991px)
{
  .buisness-result-button
  {
    font-size: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.business-result-container--cheapest .buisness-result-button
{
  background: #464e51;
}

.buisness-result-button:hover
{
  color: #fff;
  background: #464e51;
}

.business-result-title
{
  line-height: 1.2em;
  font-size: 24px;
  font-weight: 100;
  margin: 0;
}

@media (max-width: 991px)
{
  .business-result-title
  {
    font-size: 18px;
  }
}

.business-result-price
{
  margin: 0;
  font-weight: 600;
  line-height: 1.2;
  font-size: 30px;
}

@media (max-width: 991px)
{
  .business-result-price
  {
    font-size: 20px;
  }
}

.business-result-label
{
  margin: 0;
  line-height: 0.5;
  font-size: 18px;
  margin-bottom: 5px;
}

.business-based-on
{
  font-weight: 100;
  text-align: center;
}

.business-based-on span
{
  font-weight: 500;
}

.business-cheapest
{
  display: none;
  color: #fff;
  background: #464e51;
  text-transform: uppercase;
  font-size: 12px;
  width: 100px;
  margin: 5px auto 0;
  border-radius: 5px;
  padding: 3px 0 2px;
  font-weight: 300;
}

.business-til
{
  display: block;
  margin-top: 5px;
}

@media (max-width: 991px)
{
  .business-til
  {
    font-size: 12px;
  }
}

.business-link-small
{
  color: #fff;
  text-decoration: underline !important;
  font-size: 14px;
  cursor: pointer;
}

.business-result-container--cheapest .business-cheapest
{
  display: block;
}

.business-helptip
{
  font-size: 16px;
}

.commercial-section-1
{
  background: url(img/1-yourbusinessourexpertise.jpg) no-repeat;
  background-size: 100% 600px;
}

@media (max-width: 1190px)
{
  .commercial-section-1
  {
    padding: 60px 0 50px;
    background-position: center top;
    background-size: auto 100% !important;
  }
}

.commercial-section-1 .col-md-6
{
  position: relative;
  height: 600px;
}

.commercial-section-2
{
  background: #6193C6;
}

@media (max-width: 1190px)
{
  .commercial-section-2
  {
    padding: 0px 0 0px;
    background-size: auto 100% !important;
  }
}

.commercial-section-2 .col-md-6
{
  position: relative;
  height: 500px;
}

.commercial-section-3
{
  background: url(img/2-government.jpg) no-repeat;
  background-size: 100% 700px;
}

@media (max-width: 1190px)
{
  .commercial-section-3
  {
    padding: 100px 0 50px;
    background-size: auto 100% !important;
    background-position: center top;
  }
}

.commercial-section-3 .col-md-6
{
  position: relative;
  height: 700px;
}

.commercial-section-4
{
  background: #8C58A0;
}

.commercial-section-4 .col-md-6
{
  position: relative;
  height: 500px;
}

.commercial-section-5
{
  background: url(img/3-utilities.jpg) no-repeat;
  background-size: 100% 700px;
}

@media (max-width: 1190px)
{
  .commercial-section-5
  {
    padding: 60px 0 100px;
    background-size: auto 100% !important;
    background-position: right top;
  }
}

.commercial-section-5 .col-md-6
{
  position: relative;
  height: 700px;
}

.commercial-section-6
{
  background: #5E9C9B;
}

@media (max-width: 1190px)
{
  .commercial-section-6
  {
    padding-bottom: 0;
  }
}

@media (max-width: 960px)
{
  .commercial-section-6 .container
  {
    margin-top: -100px;
  }
}

.commercial-section-6 .col-md-6
{
  position: relative;
  height: 500px;
}

.commercial-section-7
{
  background: url(img/4-sport.jpg) no-repeat;
  background-size: 100% 600px;
}

@media (max-width: 1190px)
{
  .commercial-section-7
  {
    background-size: auto 100% !important;
    padding: 0px 0 0;
    background-position: center top;
  }
}

.commercial-section-7 .col-md-6
{
  position: relative;
  height: 600px;
}

.commercial-section-8
{
  background: url(img/5-getintouch.jpg) no-repeat;
  background-size: 100% 600px;
  padding-top: 100px;
}

@media (max-width: 1190px)
{
  .commercial-section-8
  {
    padding: 100px 0 100px;
    background-size: auto 100% !important;
    background-position: center top;
  }
}

.commercial-section-8 .col-md-6
{
  position: relative;
  height: 600px;
}

.social-landlord-section
{
  background: url(img/video-bg.jpg) no-repeat;
  background-size: 100% 410px;
  padding-top: 150px;
  padding-bottom: 100px;
  background-color: #c52576;
}

@media (max-width: 1190px)
{
  .social-landlord-section
  {
    background-size: auto 100% !important;
  }
}

@media (max-width: 960px)
{
  .social-landlord-section
  {
    padding-bottom: 0px;
    padding-top: 70px;
  }
}

.social-landlord-section .col-md-6
{
  position: relative;
  height: 500px;
}

.social-landlord-section--2
{
  background: url(img/video-footer-bg.jpg) no-repeat;
  background-size: 100% 220px;
  padding-top: 0px;
  margin-bottom: -110px;
}

@media (max-width: 1190px)
{
  .social-landlord-section--2
  {
    background-size: auto 100% !important;
  }
}

@media (max-width: 960px)
{
  .social-landlord-section--2
  {
    padding-top: 0px;
    padding-bottom: 140px;
  }
}

.social-landlord-section--2 .col-md-6
{
  position: relative;
  height: 220px;
}

.social-landlord-section--3
{
  background: url(img/1-header.jpg) no-repeat;
  background-size: 100% 640px;
  padding-top: 60px;
  padding-bottom: 20px;
}

@media (max-width: 1190px)
{
  .social-landlord-section--3
  {
    background-size: auto 100% !important;
  }
}

@media (max-width: 960px)
{
  .social-landlord-section--3
  {
    padding-top: 40px;
    padding-bottom: 0;
  }
}

.social-landlord-section--3 .col-md-6
{
  position: relative;
  height: 550px;
}

.social-landlord-section--4
{
  background: url(img/2-seeforyourself.jpg) no-repeat;
  background-color: #b53874;
  background-size: 100% 200px;
  padding-top: 100px;
  padding-bottom: 60px;
}

@media (max-width: 1190px)
{
  .social-landlord-section--4
  {
    background-size: 100% 100% !important;
  }
}

@media (max-width: 960px)
{
  .social-landlord-section--4
  {
    padding-top: 50px;
    padding-bottom: 0;
    background-size: auto 100% !important;
  }
}

.social-landlord-section--4 .col-md-6
{
  position: relative;
  height: 220px;
}

.social-landlord-section--5
{
  background: url(img/3-brilliantbenefitsfortenants.jpg) no-repeat;
  background-size: 100% 1050px;
  padding-top: 80px;
  padding-bottom: 70px;
}

@media (max-width: 1190px)
{
  .social-landlord-section--5
  {
    background-size: auto 100% !important;
  }
}

@media (max-width: 960px)
{
  .social-landlord-section--5
  {
    padding-top: 70px;
    padding-bottom: 60px;
    background-position: center top;
  }
}

.social-landlord-section--5 .col-md-6
{
  position: relative;
  height: 900px;
}

.social-landlord-section--6
{
  background-size: 100% 500px;
  padding-top: 40px;
  padding-bottom: 0px;
}

@media (max-width: 1190px)
{
  .social-landlord-section--6
  {
    background-size: auto 100% !important;
  }
}

@media (max-width: 960px)
{
  .social-landlord-section--6
  {
    padding-top: 0px;
    padding-bottom: 0;
  }
}

.social-landlord-section--6 .col-md-6
{
  position: relative;
  height: 500px;
}

.social-landlord-section--7
{
  background-size: 100% 500px;
  padding-top: 0px;
  padding-bottom: 0px;
}

@media (max-width: 1190px)
{
  .social-landlord-section--7
  {
    background-size: auto 100% !important;
  }
}

@media (max-width: 960px)
{
  .social-landlord-section--7
  {
    padding-top: 0px;
    padding-bottom: 0;
  }
}

.social-landlord-section--7 .col-md-6
{
  position: relative;
  height: 500px;
}

.social-landlord-section--8
{
  background: url(img/explain-everything-2.jpg) no-repeat;
  background-size: 100% 200px;
  background-color: #5e9c9b;
  padding-top: 100px;
  padding-bottom: 60px;
}

@media (max-width: 1190px)
{
  .social-landlord-section--8
  {
    background-size: auto auto !important;
  }
}

@media (max-width: 960px)
{
  .social-landlord-section--8
  {
    padding-top: 40px;
    padding-bottom: 0;
  }
}

.social-landlord-section--8 .col-md-6
{
  position: relative;
  height: 400px;
}

.social-landlord-section--8 .videoWrapper
{
  margin-top: 30px;
  margin-bottom: 30px;
}

.social-landlord-section--8-alt
{
  background: url(img/explain-everything.jpg) no-repeat;
  background-size: 100% 200px;
  background-color: #5e9c9b;
}

.social-landlord-section--9
{
  background: url(img/6-liketofindoutmore.jpg) no-repeat;
  background-size: 100% 1200px;
  padding-top: 100px;
  padding-bottom: 0px;
}

@media (max-width: 1190px)
{
  .social-landlord-section--9
  {
    background-size: auto 100% !important;
  }
}

@media (max-width: 960px)
{
  .social-landlord-section--9
  {
    padding-top: 80px;
    padding-bottom: 0;
    background-position: center top;
  }
}

.social-landlord-section--9 .col-md-6
{
  position: relative;
  height: 220px;
}

.social-landlord-section--10
{
  background: url(img/case-study-1.jpg) no-repeat;
  background-color: #8c58a0;
  background-size: 100% 190px;
  padding-top: 100px;
  padding-bottom: 0px;
}

@media (max-width: 1190px)
{
  .social-landlord-section--10
  {
    background-size: auto auto !important;
  }
}

@media (max-width: 960px)
{
  .social-landlord-section--10
  {
    padding-top: 80px;
    padding-bottom: 0;
    background-position: center top;
  }
}

.social-landlord-section--10 .col-md-6
{
  position: relative;
}

.social-landlord-section--11
{
  background: url(img/case-study-2.jpg) no-repeat;
  background-color: #b63875;
  background-size: 100% 200px;
  padding-top: 100px;
  padding-bottom: 0px;
}

@media (max-width: 1190px)
{
  .social-landlord-section--11
  {
    background-size: auto auto !important;
  }
}

@media (max-width: 960px)
{
  .social-landlord-section--11
  {
    padding-top: 80px;
    padding-bottom: 0;
    background-size: auto auto !important;
    background-position: center top;
  }
}

.social-landlord-section--11 .col-md-6
{
  position: relative;
}

.social-download-link
{
  line-height: 1.3em;
}

@media (min-width: 961px)
{
  .social-download-link
  {
    float: left;
    margin-top: 20px;
  }
}

@media (min-width: 961px)
{
  .social-download-image
  {
    float: right;
  }
}

.social-download-inner-left
{
  text-align: center;
  padding-right: 15px;
}

@media (min-width: 961px)
{
  .social-download-inner-left
  {
    padding: 20px 20px 20px 0;
    text-align: left;
  }
}

.social-download-inner-right
{
  text-align: center;
  padding-left: 15px;
}

@media (min-width: 961px)
{
  .social-download-inner-right
  {
    padding: 20px 0px 20px 20px;
    text-align: left;
  }
}

.tariff-section-1
{
  background-color: #6a4390;
  background: url(img/c-newbg1.jpg) no-repeat;
  background-size: 100% 680px;
}

@media (max-width: 1200px)
{
  .tariff-section-1
  {
    background-size: auto 100% !important;
    padding-top: 80px;
  }
}

@media (max-width: 1190px)
{
  .tariff-section-1
  {
    background-size: auto 100% !important;
    padding-top: 60px;
  }
}

@media (max-width: 990px)
{
  .tariff-section-1
  {
    padding-top: 150px;
    padding-bottom: 0;
  }
}

.tariff-section-1 .col-md-6
{
  height: 680px;
  position: relative;
}

.tariff-section-1 .videoWrapper
{
  margin-top: 40px;
}

.tariff-section-2
{
  background: url(img/2-webelievethat.jpg) no-repeat;
  background-size: 100% 600px;
  padding-top: 0px;
}

@media (max-width: 1190px)
{
  .tariff-section-2
  {
    background-size: 100% 100% !important;
    padding-top: 150px;
  }
}

@media (max-width: 990px)
{
  .tariff-section-2
  {
    padding-bottom: 100px;
    background-size: auto 100% !important;
    padding-top: 50px;
  }
}

.tariff-section-2 .col-md-6
{
  position: relative;
  height: 600px;
}

@media (max-width: 1190px)
{
  .tariff-section-2 .col-md-6
  {
    height: 300px;
  }
}

@media (max-width: 990px)
{
  .tariff-section-2 .col-md-6
  {
    height: auto;
  }
}

@media (min-width: 1600px)
{
  .tariff-section-2 .section-circle
  {
    font-size: 30px;
    padding-top: 50px;
  }
}

.tariff-section-smart
{
  background-color: #4f2875;
  padding-top: 0px;
}

@media (max-width: 1190px)
{
  .tariff-section-smart
  {
    padding-top: 150px;
  }
}

@media (max-width: 990px)
{
  .tariff-section-smart
  {
    padding-bottom: 100px;
    padding-top: 50px;
  }
}

.tariff-section-smart .col-md-6
{
  position: relative;
  height: 600px;
}

@media (max-width: 1190px)
{
  .tariff-section-smart .col-md-6
  {
    height: 300px;
  }
}

@media (max-width: 990px)
{
  .tariff-section-smart .col-md-6
  {
    height: auto;
  }
}

@media (min-width: 1600px)
{
  .tariff-section-smart .section-circle
  {
    font-size: 30px;
    padding-top: 50px;
  }
}

.tariff-section-fixed
{
  background: url(img/c-newbg2.jpg) no-repeat;
  background-size: 100% 600px;
  padding-top: 0px;
}

@media (max-width: 1190px)
{
  .tariff-section-fixed
  {
    background-size: 100% 100% !important;
    padding-top: 150px;
  }
}

@media (max-width: 990px)
{
  .tariff-section-fixed
  {
    padding-bottom: 100px;
    background-size: auto 100% !important;
    padding-top: 50px;
  }
}

.tariff-section-fixed .col-md-6
{
  position: relative;
  height: 600px;
}

@media (max-width: 1190px)
{
  .tariff-section-fixed .col-md-6
  {
    height: 300px;
  }
}

@media (max-width: 990px)
{
  .tariff-section-fixed .col-md-6
  {
    height: auto;
  }
}

@media (min-width: 1600px)
{
  .tariff-section-fixed .section-circle
  {
    font-size: 30px;
    padding-top: 50px;
  }
}

.tariff-section-fair
{
  background-color: #5e9b9b;
  padding-top: 0px;
}

@media (max-width: 1190px)
{
  .tariff-section-fair
  {
    padding-top: 150px;
  }
}

@media (max-width: 990px)
{
  .tariff-section-fair
  {
    padding-bottom: 100px;
    padding-top: 50px;
  }
}

.tariff-section-fair .col-md-6
{
  position: relative;
  height: 600px;
}

@media (max-width: 1190px)
{
  .tariff-section-fair .col-md-6
  {
    height: 300px;
  }
}

@media (max-width: 990px)
{
  .tariff-section-fair .col-md-6
  {
    height: auto;
  }
}

@media (min-width: 1600px)
{
  .tariff-section-fair .section-circle
  {
    font-size: 30px;
    padding-top: 50px;
  }
}

.tariff-section-variable
{
  background: url(img/c-energyplans2.jpg) no-repeat;
  background-size: 100% 600px;
  padding-top: 0px;
}

@media (max-width: 1190px)
{
  .tariff-section-variable
  {
    background-size: 100% 100% !important;
    padding-top: 150px;
  }
}

@media (max-width: 990px)
{
  .tariff-section-variable
  {
    padding-bottom: 100px;
    background-size: auto 100% !important;
    padding-top: 50px;
  }
}

.tariff-section-variable .col-md-6
{
  position: relative;
  height: 600px;
}

@media (max-width: 1190px)
{
  .tariff-section-variable .col-md-6
  {
    height: 300px;
  }
}

@media (max-width: 990px)
{
  .tariff-section-variable .col-md-6
  {
    height: auto;
  }
}

@media (min-width: 1600px)
{
  .tariff-section-variable .section-circle
  {
    font-size: 30px;
    padding-top: 50px;
  }
}

.tariff-section-3
{
  background: #f9b725;
  padding-bottom: 0;
}

@media (max-width: 990px)
{
  .tariff-section-3
  {
    padding-top: 0px;
    padding-bottom: 0;
  }
}

.tariff-section-3 .col-md-6
{
  height: 400px;
  display: table;
}

.tariff-section-4
{
  background: url(img/3-energy-advice.jpg) no-repeat;
  background-size: 100% 500px;
}

@media (max-width: 1190px)
{
  .tariff-section-4
  {
    background-position: left top;
  }
}

.tariff-section-4 .col-md-6
{
  height: 500px;
  display: table;
}

.tariff-section-4 .section-image
{
  height: 430px;
  margin-top: 100px;
}

.tariff-section-5
{
  background: url(img/3-havingalook.jpg) no-repeat;
  background-size: 100% 700px;
  padding-top: 120px;
  padding-bottom: 0;
}

@media (max-width: 1190px)
{
  .tariff-section-5
  {
    background-size: 100% 100% !important;
    padding-bottom: 0;
  }
}

@media (max-width: 990px)
{
  .tariff-section-5
  {
    padding-top: 60px;
    background-size: auto 100% !important;
  }
}

.tariff-section-5 .col-md-6
{
  height: 500px;
}

@media (max-width: 1190px)
{
  .tariff-section-5 .col-md-6
  {
    height: 400px;
  }
}

.tariff-section-6
{
  background: url(img/4-100renewableenergy.jpg) no-repeat;
  background-size: 100% 500px;
}

@media (max-width: 1190px)
{
  .tariff-section-6
  {
    background-size: auto 100% !important;
    padding: 60px 0px 0px;
  }
}

@media (max-width: 990px)
{
  .tariff-section-6
  {
    padding-bottom: 100px;
  }
}

.tariff-section-6 .section-text
{
  margin-top: 40px;
}

@media (max-width: 1190px)
{
  .tariff-section-6 .section-text
  {
    margin-top: 60px;
  }
}

@media (max-width: 990px)
{
  .tariff-section-6 .section-text
  {
    margin-top: 0px;
  }
}

.tariff-section-6 .col-md-6
{
  height: 500px;
  display: table;
}

.tariff-section-7
{
  background: url(img/5-tariffinformation.jpg) no-repeat;
  background-size: 100% 600px;
  padding-bottom: 200px;
}

@media (max-width: 1190px)
{
  .tariff-section-7
  {
    background-size: auto 100% !important;
    background-position: right top !important;
    padding-bottom: 300px;
  }
}

@media (max-width: 990px)
{
  .tariff-section-7
  {
    padding-top: 0px;
    padding-bottom: 150px;
  }
}

.tariff-section-7 .container
{
  padding-top: 0;
}

.tariff-section-7 .col-md-6
{
  height: 400px;
  position: relative;
}

.tariff-section-8
{
  background: #a7226e
;
}

@media (max-width: 990px)
{
  .tariff-section-8
  {
    padding-top: 0px;
    padding-bottom: 0;
  }
}

.tariff-section-8 .row
{
  padding-top: 0;
  margin-top: -100px;
}

@media (max-width: 990px)
{
  .tariff-section-8 .row
  {
    margin-top: 0;
  }
}

.tariff-section-8 .col-md-6
{
  height: 600px;
  position: relative;
}

.heading--sub
{
  font-size: 24px;
}

.heading--case-study
{
  margin-top: -60px;
}

.grid-col
{
  padding-right: 40px;
  padding-left: 40px;
  padding-top: 20px;
}

@media (min-width: 992px)
{
  .grid-col:nth-child(3n+1)
  {
    clear: left;
  }
}

@media (max-width: 991px) and (min-width: 767px)
{
  .grid-col:nth-child(2n+1)
  {
    clear: left;
  }
}

.grid-cell
{
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border-radius: 100%;
  background-size: cover !important;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
}

.casestudy--section-grid
{
  padding-top: 200px;
}

.case-study-description
{
  text-align: center;
  text-decoration: none;
}

.case-study--link
{
  text-decoration: none !important;
}

.case-study.grid-cell
{
  border: 5px solid #5f9c9b;
}

.case-study.grid-cell:hover,.case-study.grid-cell:focus,.case-study.grid-cell:active
{
  border: 5px solid #5f9c9b;
}

.grid-title
{
  font-size: 20px;
  color: white;
  padding-right: 30px;
  padding-left: 30px;
  text-align: center;
}

.grid-intro
{
  font-size: 14px;
  text-transform: uppercase;
  color: white;
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.grid-cat
{
  font-size: 14px;
  font-weight: 900;
  color: white;
  margin-bottom: 0px;
}

.ratio
{
  box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  position: relative;
  display: block;
}

.ratio--square
{
  padding-bottom: 100%;
}

.ratio--4-3
{
  padding-bottom: 75%;
}

.ratio--16-9
{
  padding-bottom: 56.25%;
}

.ratio--case-study
{
  padding-bottom: 34.25%;
}

.case-study-top-curve
{
  position: absolute;
  z-index: 10;
  margin-bottom: 0px;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
}

.case-study-header-curve
{
  position: absolute;
  z-index: 10;
  margin-top: 0px;
  width: 100%;
  height: auto;
  left: 0;
  bottom: 0;
}

.case-study-footer-curve
{
  position: relative;
  z-index: 10;
  width: 100%;
  height: auto;
}

.case-study--section
{
  background-color: #3e1d55;
}

.share-section
{
  background-color: #3e1d55;
}

.case-study--image
{
  position: relative;
  height: 400px;
}

.case-study-download
{
  text-align: center;
  margin-left: 0px;
  margin-top: 0px;
}

@media (max-width: 767px)
{
  .case-study-download
  {
    text-align: center;
    margin-left: 0;
  }
}

.case-study-download a
{
  color: white;
  font-size: 18px;
  font-weight: 300;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.case-study-download a:hover,.case-study-download a:focus,.case-study-download a:active
{
  color: #464e51;
  text-decoration: none;
}

@media (min-width: 992px)
{
  .case-study--image
  {
    height: 680px;
  }
}

@media (max-width: 991px) and (min-width: 767px)
{
  .case-study--image
  {
    height: 560px;
  }
}

@media (max-width: 766px)
{
  .case-study--image
  {
    height: 400px;
  }
}

.panel
{
  border: 0;
  border-radius: 10px;
  background: none;
  overflow: hidden;
  margin-bottom: 20px !important;
}

.close-button
{
  display: none;
  right: 0px;
  width: 50px;
  top: 50%;
}

.open-button
{
  right: 15px;
  width: 16px;
  top: 50%;
}

.active .close-button
{
  display: block;
}

.active .open-button
{
  display: none;
}

.panel-title a
{
  color: #a7226e
;
  display: block;
  padding: 10px 60px 10px 15px;
  background: white;
  border: 0 !important;
  font-weight: normal;
  text-decoration: none !important;
  -webkit-transition: all ease-in-out 0.2s;
  -moz-transition: all ease-in-out 0.2s;
  transition: all ease-in-out 0.2s;
}

.panel-title a:hover
{
  color: white;
  background: #c43427;
}

.panel-title a:hover .arrowcls-1
{
  stroke: white;
  -webkit-transition: all ease-in-out 0.2s;
  -moz-transition: all ease-in-out 0.2s;
  transition: all ease-in-out 0.2s;
}

.panel-title a:hover .xcls-2
{
  stroke: white;
  -webkit-transition: all ease-in-out 0.2s;
  -moz-transition: all ease-in-out 0.2s;
  transition: all ease-in-out 0.2s;
}

.panel-title a:hover .xcls-1
{
  stroke: white;
  -webkit-transition: all ease-in-out 0.2s;
  -moz-transition: all ease-in-out 0.2s;
  transition: all ease-in-out 0.2s;
}

.panel-heading
{
  padding: 0;
  border: 0 !important;
  position: relative;
}

.panel-heading svg
{
  height: 34px;
  position: absolute;
  top: 50%;
  margin-top: -17px;
  pointer-events: none;
  -webkit-transition: all ease-in-out 0.2s;
  -moz-transition: all ease-in-out 0.2s;
  transition: all ease-in-out 0.2s;
}

.panel-default>.panel-heading+.panel-collapse>.panel-body
{
  border: 0;
}

.panel-body
{
  background: white;
}

.panel-heading h4
{
  font-weight: 300;
  font-size: 20px;
  border: 0 !important;
}

.panel-body p
{
  color: #a7226e
;
  font-size: 16px;
  font-weight: normal;
}

.panel-body ul
{
  color: #a7226e
;
  font-size: 16px;
  font-weight: normal;
}

.panel-body li
{
  color: #a7226e
;
  font-size: 16px;
  font-weight: normal;
}

.til-result
{
  background: #caacd7;
  border-radius: 20px;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 40px;
}

.til-result h2
{
  text-align: center;
  color: #a7226e
;
  font-size: 20px;
}

.til-result .row
{
  margin-bottom: 20px;
}

.til-result .col-md-6:first-child
{
  font-weight: bold;
}

@media (max-width: 990px)
{
  .til-result .col-md-6:first-child
  {
    text-align: left !important;
  }
}

.til-result .til-icon
{
  margin-top: 60px;
}

.til-result .til-icon img
{
  width: 60px;
  display: block;
  margin: -30px auto 0;
}

.til-result .til-icon__line
{
  height: 1px;
  width: 100%;
  background: #a7226e
;
}

.til-result .til-icon__line--push
{
  margin-top: 5px;
}

.til-result .til-small-print
{
  color: black;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  margin-top: 20px;
}

.til-result .til-tool-inner
{
  margin-top: 20px;
  margin-bottom: 20px;
}

.til-result .til-number
{
  font-size: 20px;
  vertical-align: sub;
  display: inline-block;
  font-weight: bold;
}

#tariffName
{
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  -ms-border-radius: 10px;
}

.tariff-detailed-button
{
  font-size: 18px;
  font-weight: 300;
  display: inline-block;
  text-transform: uppercase;
  color: white;
  background-color: transparent;
  margin-top: 10px;
  margin-bottom: 40px;
  padding: 10px 30px;
  border: 2px solid white;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.tariff-detailed-button:hover
{
  background: white;
  color: #a7226e
 !important;
  text-decoration: none !important;
}

.multi-tariff-heading
{
  margin-top: 60px;
  margin-bottom: 60px;
  color: white;
  font-weight: 300;
  font-size: 38px;
}

@media (max-width: 767px)
{
  .multi-tariff-heading
  {
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 26px;
  }
}

.multi-tariff
{
  border: 1px solid white;
  margin-bottom: 30px;
  border-radius: 10px;
}

@media (max-width: 767px)
{
  .multi-tariff
  {
    border: none;
    margin-bottom: 0px;
  }
}

.multi-tariff-fade
{
  opacity: 0.2;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.multi-tariff-fade:hover
{
  opacity: 1;
}

@media (max-width: 767px)
{
  .multi-tariff-fade
  {
    opacity: 1;
  }
}

.multi-tariff-cheap
{
  border: 5px solid #464e51;
}

@media (max-width: 767px)
{
  .multi-tariff-cheap
  {
    border: none;
  }
}

.multi-tariff-section
{
  padding-top: 15px;
  padding-bottom: 15px;
  height: 140px;
}

@media (max-width: 767px)
{
  .multi-tariff-section
  {
    height: auto;
  }
}

.multi-tariff-separator
{
  border-right: 1px solid white;
}

@media (max-width: 767px)
{
  .multi-tariff-separator
  {
    border: none;
  }
}

.multi-offer-round
{
  height: 400px;
  width: 400px;
  padding-top: 100px;
  padding-left: 20px;
  padding-right: 20px;
  display: inline-block;
}

@media (max-width: 767px)
{
  .multi-offer-round
  {
    height: 350px;
    width: 350px;
    padding-top: 80px;
  }
}

.multi-tariff-variable,.Tariff .first .wpcf7-list-item-label
{
  color: #ffb91c;
}

.multi-tariff-fixed,.Tariff .last .wpcf7-list-item-label
{
  color: #9dd9f7;
}

.Tariff .first .wpcf7-list-item-label,.Tariff .last .wpcf7-list-item-label
{
  text-transform: uppercase;
  font-weight: 700;
}

.multi-tariff-name
{
  margin: 0;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 1.2;
  font-size: 24px;
  margin-bottom: 5px;
}

.multi-tariff-text
{
  margin: 0;
  line-height: 0.5;
  font-size: 20px;
}

.multi-tariff-til
{
  margin: 0;
  font-size: 12px;
  line-height: 1.2;
}

.multi-tariff-number
{
  margin: 0;
  font-weight: 700;
  line-height: 1.2;
  font-size: 46px;
}

.multi-tariff-compare
{
  margin: 0;
  line-height: 1.2;
  font-weight: 600;
  font-size: 14px;
}

.Tariff input
{
  zoom: 2 !important;
}

.multi-led-logo
{
  margin-top: 20px;
}

@media (max-width: 767px)
{
  .multi-led-logo
  {
    margin-top: 10px;
  }
}

.rings-quote
{
  padding-top: 165px;
  padding-right: 0px;
  height: 480px;
}

@media (max-width: 991px)
{
  .rings-quote
  {
    margin: 0px 0 20px;
    margin-bottom: 0px;
    padding-top: 125px;
    height: 380px;
  }
}

@media (max-width: 991px)
{
  .rings-quote-hero-mobile-only
  {
    display: block;
  }

  .rings-quote-hero-desktop-only
  {
    display: none;
  }
}

@media (min-width: 992px)
{
  .rings-quote-hero-mobile-only
  {
    display: none;
  }

  .rings-quote-hero-desktop-only
  {
    display: block;
  }
}

.rings-quote-title
{
  color: white;
  text-transform: uppercase;
  font-size: 44px;
  font-weight: 300;
  margin-top: 0px;
  margin-bottom: 15px;
}

@media (max-width: 991px)
{
  .rings-quote-title
  {
    font-size: 20px;
  }
}

.rings-quote-input-container
{
  text-align: center;
  display: inline-block;
}

.rings-quote-input
{
  color: #a7226e
 !important;
  text-align: center;
  font-size: 20px;
  background: #fff;
  font-weight: 300;
  width: 200px;
  display: block;
  border: 1px solid #a7226e
;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 15px;
  background: white;
}

.rings-quote-input:hover,.rings-quote-input:focus,.rings-quote-input:active
{
  outline: none !important;
}

@media (max-width: 1199px)
{
  .rings-quote-input
  {
    font-size: 16px;
    width: 140px;
  }
}

.rings-quote-go
{
  color: white;
  background: #a7226e
;
  line-height: 10px;
  font-size: 24px;
  text-align: center;
  padding: 0;
  width: 50px;
  height: 45px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  border-color: transparent;
}

.rings-quote-go:hover,.rings-quote-go:focus,.rings-quote-go:active
{
  color: #a7226e
;
  background-color: white;
  text-decoration: none;
  outline: none !important;
}

.rings-quote-go-2
{
  padding-top: 16px;
  display: inline-block;
}

.rings-offer-round
{
  height: 250px;
  position: absolute;
  width: 250px;
  right: 0;
  top: 250px;
  pointer-events: none;
  padding-top: 60px;
  padding-left: 20px;
  padding-right: 20px;
}

@media (max-width: 1190px)
{
  .rings-offer-round
  {
    margin-right: -50px;
  }
}

.rings-offer-round-top
{
  font-weight: 900;
  font-size: 70px;
  color: #464e51;
  line-height: 0.8;
}

.rings-offer-round-mid
{
  font-size: 22px;
  color: #464e51;
}

.rings-offer-round-bot
{
  font-size: 15px;
  line-height: 0;
}

.rings-quote-input-container-business
{
  font-size: 19px;
}

@media (max-width: 991px)
{
  .rings-quote-input-container-business
  {
    font-size: 14px;
  }
}

.rings-quote--business
{
  padding-top: 145px;
}

.rings-quote--business .rings-quote-go--business
{
  background: #9dd9f6 !important;
  padding-top: 100px;
}

.rings-quote--business .rings-quote-title
{
  color: white;
  text-transform: uppercase;
  font-size: 28px;
  font-weight: 600;
  margin-top: 0px;
  margin-bottom: 15px;
}

@media (max-width: 991px)
{
  .rings-quote--business .rings-quote-title
  {
    font-size: 20px;
  }
}

.rings-quote--small
{
  margin-left: -500px;
  padding-top: 105px;
  height: 300px;
  margin-top: -50px;
}

@media (max-width: 991px)
{
  .rings-quote--small
  {
    margin-left: auto;
    margin-top: 20px;
  }
}

.rings-quote--small .rings-quote-go
{
  display: inline-block;
  background: #9dd9f6 !important;
  padding-top: 18px;
}

.rings-quote--small .rings-quote-title
{
  color: white;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 600;
  margin-top: 0px;
  margin-bottom: 15px;
  font-weight: 300;
}

.rings-quote--small .rings-quote-title span
{
  font-weight: 600;
}

.rings-quote-braintree
{
  padding-top: 125px;
}

@media (max-width: 991px)
{
  .rings-quote-braintree
  {
    padding-top: 85px;
  }
}

@media (max-width: 420px)
{
  .rings-quote-braintree
  {
    padding-top: 90px;
  }
}

.rings-quote-braintree .rings-quote-title
{
  letter-spacing: -2px;
  margin-bottom: 10px;
  margin-top: 10px;
}

@media (max-width: 991px)
{
  .rings-quote-braintree .rings-quote-title
  {
    font-size: 28px;
    letter-spacing: -1px;
  }
}

@media (max-width: 420px)
{
  .rings-quote-braintree .rings-quote-title
  {
    font-size: 16px;
    margin-top: 15px;
  }
}

.rings-quote-braintree .rings-quote-input
{
  margin-bottom: 5px;
}

.rings-quote-braintree .braintree-rings
{
  font-size: 18px;
  line-height: 20px;
  color: #fff;
  margin: 0;
  display: block !important;
  letter-spacing: -1px;
}

@media (max-width: 991px)
{
  .rings-quote-braintree .braintree-rings
  {
    font-size: 14px;
    line-height: 16px;
  }
}

@media (max-width: 420px)
{
  .rings-quote-braintree .braintree-rings
  {
    font-size: 11px;
    line-height: 13px !important;
  }
}

.rings-quote-braintree .braintree-rings a:hover
{
  color: #a7226e
;
}

.rings-quote-braintree .rings-quote-go
{
  margin-bottom: 5px;
}

.new-rings-offer-round
{
  height: 250px;
  position: absolute;
  width: 251px;
  right: 0;
  top: 250px;
  pointer-events: none;
  padding-top: 45px;
  line-height: 0.95;
  padding-left: 25px;
  padding-right: 20px;
}

.new-rings-offer-round-desktop
{
  height: 250px;
  position: absolute;
  width: 251px;
  right: 0;
  top: 250px;
  pointer-events: none;
  padding-top: 50px;
  line-height: 0.95;
  padding-left: 25px;
  padding-right: 20px;
}

@media (max-width: 991px)
{
  .new-rings-offer-round-desktop
  {
    display: none;
  }
}

.new-rings-offer-round-mobile
{
  display: none;
  height: 250px;
  width: 251px;
  pointer-events: none;
  padding-top: 50px;
  line-height: 0.95;
  padding-left: 25px;
  padding-right: 20px;
  margin: 0 auto;
  margin-top: -50px;
}

@media (max-width: 991px)
{
  .new-rings-offer-round-mobile
  {
    display: block;
  }
}

.new-rings-offer-round-xl
{
  font-weight: 900;
  font-size: 64px;
  color: #464e51;
}

.new-rings-offer-round-l
{
  font-weight: 900;
  font-size: 24px;
  color: #464e51;
  text-transform: uppercase;
}

.new-rings-offer-round-m
{
  font-weight: 900;
  font-size: 18px;
  color: #a7226e
;
}

.new-rings-offer-round-s
{
  font-size: 14px;
  color: #a7226e
;
}

.front-usp-section
{
  background-color: #a7226e
;
  padding-bottom: 40px;
}

@media (max-width: 1199px)
{
  .front-usp-section
  {
    padding-top: 40px;
  }
}

@media (max-width: 991px)
{
  .front-usp-section img
  {
    margin-top: 30px;
  }
}

.front-usp-heading
{
  font-size: 42px;
  font-weight: 300;
  color: white;
  margin-top: 0px;
  margin-bottom: 30px;
}

@media (max-width: 1199px)
{
  .front-usp-heading
  {
    font-size: 36px;
  }
}

.front-usp-copy
{
  font-size: 18px;
  font-weight: 300;
  color: white;
}

.front-simplicity-section
{
  padding-bottom: 40px;
}

@media (max-width: 1199px)
{
  .front-simplicity-section
  {
    padding-top: 40px;
  }
}

.front-simplicity-heading
{
  font-size: 42px;
  font-weight: 300;
  color: #a7226e
;
  margin-top: 0px;
  margin-bottom: 30px;
}

@media (max-width: 1199px)
{
  .front-simplicity-heading
  {
    font-size: 36px;
  }
}

.front-simplicity-copy
{
  font-size: 18px;
  font-weight: 300;
  color: #a7226e
;
}

@media (max-width: 991px)
{
  .front-simplicity-copy
  {
    margin-bottom: 30px;
  }
}

.front-simplicity-group
{
  text-align: center;
}

.front-simplicity-label
{
  font-size: 18px;
  font-weight: 300;
  color: #a7226e
;
  margin-top: 10px;
}

.front-tariff-section
{
  padding-bottom: 40px;
}

@media (max-width: 1199px)
{
  .front-tariff-section
  {
    padding-top: 40px;
  }
}

@media (max-width: 991px)
{
  .front-tariff-section img
  {
    margin-top: 30px;
  }
}

.front-tariff-heading
{
  font-size: 42px;
  font-weight: 300;
  color: #a7226e
;
  margin-top: 0px;
  margin-bottom: 30px;
}

@media (max-width: 1199px)
{
  .front-tariff-heading
  {
    font-size: 36px;
  }
}

.front-tariff-copy
{
  font-size: 18px;
  font-weight: 300;
  color: #a7226e
;
}

.front-tariff-go
{
  color: white;
  background: #464e51;
  font-size: 18px;
  padding: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.front-tariff-go:hover,.front-tariff-go:focus,.front-tariff-go:active
{
  color: white;
  background-color: #a7226e
;
  text-decoration: none;
}

.front-customer-section
{
  background-color: #a7226e
;
  padding-bottom: 40px;
}

@media (max-width: 1199px)
{
  .front-customer-section
  {
    padding-top: 40px;
  }
}

@media (max-width: 991px)
{
  .front-customer-section img
  {
    margin-bottom: 30px;
  }
}

.front-customer-heading
{
  font-size: 42px;
  font-weight: 300;
  color: white;
  margin-top: 0px;
  margin-bottom: 30px;
}

@media (max-width: 1199px)
{
  .front-customer-heading
  {
    font-size: 36px;
  }
}

.front-customer-copy
{
  font-size: 18px;
  font-weight: 300;
  color: white;
}

.front-window-section
{
  padding-bottom: 100px;
  padding-top: 40px;
}

.front-window-heading
{
  font-size: 42px;
  font-weight: 300;
  color: white;
  margin-top: 0px;
  margin-bottom: 30px;
}

@media (max-width: 1199px)
{
  .front-window-heading
  {
    font-size: 36px;
  }
}

.front-window-copy
{
  font-size: 18px;
  font-weight: 300;
  color: white;
}

.lightbox
{
  display: none;
}

.page-template-template-gallery .featherlight .featherlight-content
{
  background: transparent;
  overflow-y: hidden;
}

@media (min-width: 1200px)
{
  .page-template-template-gallery .featherlight .featherlight-content
  {
    width: 800px;
  }
}

.form-section
{
  background-color: #a7226e
;
  padding-bottom: 40px;
}

.business-form-section
{
  background-color: rgba(87,43,130,0.25);
}

.form-heading
{
  color: white;
  border-bottom: 1px solid #fff;
  text-transform: uppercase;
  font-size: 26px;
  font-weight: 200;
  margin-bottom: 30px;
  padding-bottom: 15px;
}

.form-subheading
{
  color: white;
  font-size: 42px;
  font-weight: 200;
  margin-bottom: 20px;
}

.business-form-section .form-subheading
{
  color: #a7226e
;
  margin-bottom: 30px;
}

.form-copy
{
  color: white;
  font-size: 18px;
  font-weight: 200;
  margin-bottom: 30px;
}

.business-form-section .form-copy
{
  color: #a7226e
;
  font-weight: 300;
  padding: 0;
  list-style-position: inside;
}

span.wpcf7-not-valid-tip
{
  font-size: 14px;
  font-weight: 300;
}

.wpcf7-list-item.first
{
  margin-left: 0px;
}

.form-section .wpcf7-text,.form-section .wpcf7-textarea,.form-section .form-control
{
  color: #a7226e
;
  font-size: 18px;
  font-weight: 300;
  border: 1px solid transparent;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  margin-top: 20px;
}

@media (max-width: 991px)
{
  .form-section .wpcf7-text,.form-section .wpcf7-textarea,.form-section .form-control
  {
    font-size: 16px;
  }
}

.form-section .form-control
{
  height: 47px;
}

.form-section .wpcf7-textarea
{
  height: 150px;
  resize: none;
}

.form-section .wpcf7-submit
{
  font-size: 18px;
  font-weight: 200;
  text-transform: uppercase;
  color: white;
  background-color: transparent;
  margin-top: 40px;
  margin-bottom: 40px;
  margin-left: 16px;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 2px solid white;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.form-section .wpcf7-submit:hover,.form-section .wpcf7-submit:focus,.form-section .wpcf7-submit:active
{
  color: #a7226e
;
  background-color: white;
  border: 2px solid white;
}

.business-form-section .wpcf7-submit
{
  font-weight: 300;
  color: white;
  background-color: #464e51;
  border: none;
}

.business-form-section .wpcf7-submit:hover,.business-form-section .wpcf7-submit:focus,.business-form-section .wpcf7-submit:active
{
  color: #464e51;
  background-color: white;
  border: none;
}

.form-section .wpcf7-response-output,.renew-form .wpcf7-response-output
{
  text-align: center;
  color: white;
  border-color: white;
  font-size: 18px;
  font-weight: 300;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  margin-bottom: 30px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  max-width: 400px;
  margin: 0 auto;
}

.business-form-section .wpcf7-response-output,.renew-form .wpcf7-response-output
{
  color: #a7226e
;
  border-color: #a7226e
;
}

.overlay-form .form-section
{
  background-color: transparent;
  padding-bottom: 40px;
}

.overlay-form .featherlight-content
{
  overflow-y: scroll;
  overflow-x: hidden;
  background: transparent;
  position: relative;
  -webkit-overflow-scrolling: touch;
}

#form-overlay .featherlight .featherlight-content
{
  padding: 0;
  margin: 0;
}

@media (max-width: 1199px)
{
  .overlay-form .form-subheading
  {
    font-size: 28px;
  }
}

.form-close
{
  cursor: pointer;
}

.overlay-form .wpcf7-response-output
{
  text-align: center;
  color: white;
  border-color: white;
  font-size: 18px;
  font-weight: 300;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
}

section.times-banner-section
{
  background-color: #c43427;
}

.times-banner-section .container
{
  padding-top: 10px;
}

p.times-banner-copy
{
  color: white;
  text-align: center;
  font-size: 17px;
}

.customer-landing-section
{
  padding-bottom: 120px;
}

.customer-landing-title
{
  font-size: 40px;
  font-weight: 300;
  color: #464e51;
  margin-top: 10px;
  margin-bottom: 90px;
}

.customer-landing-group
{
  text-align: center;
}

.customer-landing-heading a
{
  font-size: 32px;
  font-weight: 300;
  color: #a7226e
;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.customer-landing-heading a:hover,.customer-landing-heading a:focus,.customer-landing-heading a:active
{
  color: #464e51;
  text-decoration: none;
}

.customer-landing-copy
{
  font-size: 18px;
  font-weight: 300;
  color: #a7226e
;
  margin-bottom: 20px;
}

.quote-btn-primary
{
  color: #a7226e
;
  background: #ccbfd8;
  line-height: 10px;
  font-size: 24px;
  padding: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  font-weight: 300;
  padding: 20px;
  border: 1px solid #a7226e
;
  width: 49.6%;
}

.quote-btn-primary.active
{
  color: white;
  background: #a7226e
;
  line-height: 10px;
  font-size: 38px;
  padding: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  font-weight: 300;
  padding: 20px;
  border: 1px solid white;
}

.quote-page-submit
{
  color: white;
  background: #a7226e
;
  line-height: 10px;
  font-size: 24px;
  padding: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  font-weight: 300;
  padding: 25px;
  border: 1px solid #fff;
  padding-left: 80px;
  padding-right: 80px;
  font-size: 30px;
}

.quote-page-submit:hover,.quote-page-submit:focus,.quote-page-submit:active
{
  color: white;
  text-decoration: none;
  background-color: #464e51;
}

.duo
{
  width: 49.4%;
}

.trio
{
  width: 32.75%;
}

.quad
{
  width: 24.4%;
}

@media (max-width: 1199px)
{
  .duo-medium
  {
    width: 49.4%;
  }
}

@media (max-width: 991px)
{
  .duo,.trio,.qua,.duo-medium
  {
    width: 100%;
  }
}

.quote-results-section
{
  background-color: #3e1d55;
  padding-bottom: 30px;
}

.results-subheading
{
  font-weight: 300;
  font-size: 28px;
  color: white;
  margin-top: 10px;
}

.results-subheading-fixed span,.tariff-select-fixed,.variable-color
{
  color: #ffb91c;
}

.results-subheading-variable span,.tariff-select-variable,.fixed-color
{
  color: #9dd9f7;
}

.gas-form-business .tariff-select-business .last
{
  display: none;
}

.quote-results-section .form-subheading
{
  color: white;
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 0px;
  line-height: 1.2;
}

.quote-results-section .form-subheading-tariff
{
  font-size: 46px;
  margin-top: 30px;
}

.quote-results-section .results-box .form-subheading
{
  font-size: 24px;
}

@media (max-width: 991px)
{
  .quote-results-section .form-subheading
  {
    font-size: 22px;
  }
}

.quote-results-section .form-copy
{
  color: white;
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 0px;
  line-height: 1.7;
}

@media (max-width: 991px)
{
  .quote-results-section .form-copy
  {
    line-height: 1.2;
  }
}

.smart-meter-text,.e7-meter-text
{
  display: none;
  margin-bottom: 0px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.gas-field
{
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.elec-field
{
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.e7-field
{
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  display: none;
}

.quote-hero-copy
{
  font-size: 18px;
  font-weight: 300;
  color: #a7226e
;
  text-align: center;
}

.quote-switch-go
{
  color: white;
  background: #a7226e
;
}

.quote-switch-go:hover,.quote-switch-go:focus,.quote-switch-go:active
{
  color: white;
  background-color: #464e51;
}

.results-form-section
{
  background-color: transparent;
  padding-bottom: 0px;
  margin-top: 30px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
}

.results-box
{
  color: white;
  height: 520px;
  padding-top: 65px;
}

@media (max-width: 991px)
{
  .results-box
  {
    background: none !important;
    height: auto;
    padding-top: 0px;
  }
}

.results-middle-text
{
  color: #a7226e
;
}

@media (max-width: 991px)
{
  .quote-results-section input[type=checkbox],.quote-results-section input[type=radio]
  {
    zoom: 1.5 !important;
  }
}

.results-divider
{
  border-top: 2px solid #464e51;
  max-width: 350px;
  text-align: center;
  margin: 0 auto;
}

.quote-results-section .form-subheading-results-number
{
  margin-bottom: 0px;
  font-size: 34px;
}

.quote-results-section .results-price
{
  font-size: 44px;
  font-weight: 500;
}

.quote-results-section .results-saving
{
  color: #ffb91c;
  font-weight: 500;
}

.results-box p
{
  font-size: 14px;
  line-height: 1;
  font-weight: 200;
}

.results-box-quantifier
{
  font-size: 24px !important;
}

.results-box-saving
{
  color: #ffb91c;
  font-size: 15px !important;
}

.results-small-text
{
  margin-top: 10px;
  font-size: 11px !important;
  line-height: 1.2;
}

.results-small-text a
{
  color: white;
}

.results-small-text a:hover,.results-small-text a:focus,.results-small-text a:active
{
  color: white;
  text-decoration: none;
}

.til-link
{
  color: white;
  text-decoration: underline !important;
}

.til-link:hover,.til-link:focus,.til-link:active
{
  color: white;
  text-decoration: underline !important;
}

.hidden-fields
{
  display: none !important;
  visibility: hidden !important;
  background-color: transparent !important;
  color: transparent !important;
  opacity: 0 !important;
  outline: 0 !important;
  border: none !important;
  position: absolute !important;
  left: -9999px !important;
  height: 0px !important;
  width: 0px !important;
}

.mpas-checker,.results-sign-up,.quote-back-button
{
  font-size: 18px;
  font-weight: 300;
  text-transform: uppercase;
  color: white;
  background-color: #464e51;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: none;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.mpas-checker:hover,.mpas-checker:focus,.mpas-checker:active,.results-sign-up:hover,.results-sign-up:focus,.results-sign-up:active,.quote-back-button:hover,.quote-back-button:focus,.quote-back-button:active
{
  color: #464e51;
  background-color: white;
  border: none;
}

.mpas-checker
{
  margin-right: 10px;
}

.results-sign-up
{
  font-size: 24px;
}

.mpas-checker-holder
{
  display: inline-block;
}

.mpas-text
{
  display: inline-block;
  line-height: 1.2;
}

.quote-detailed-button
{
  font-size: 16px;
  font-weight: 300;
  text-transform: uppercase;
  color: white;
  background-color: transparent;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: 2px solid white;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.quote-detailed-button:hover,.quote-detailed-button:focus,.quote-detailed-button:active
{
  color: #3e1d55;
  background-color: white;
  border: 2px solid white;
}

.back-to-quote
{
  color: #464e51;
  font-size: 18px;
  font-weight: 300;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.back-to-quote:hover,.back-to-quote:focus,.back-to-quote:active
{
  color: white;
  text-decoration: none !important;
}

.hidden-values
{
  display: none !important;
  visibility: hidden !important;
  background-color: transparent !important;
  color: transparent !important;
  opacity: 0 !important;
  outline: 0 !important;
  border: none;
  position: absolute !important;
  left: -9999px;
}

.exact-energy-choice
{
  display: none;
}

.low-energy-text,.high-energy-text
{
  display: none;
}

.tariff-select
{
  display: none;
}

.Tariff .wpcf7-list-item-label
{
  font-size: 26px;
}

.form-second
{
  display: none;
}

.TERMS_AND_CONDITIONS .wpcf7-list-item-label
{
  display: none;
}

.featherlight .featherlight-content
{
  width: 50%;
}

@media (max-width: 991px)
{
  .featherlight .featherlight-content
  {
    width: 90%;
  }
}

.fa-chevron-down
{
  position: absolute;
  bottom: 10px;
  left: 90%;
  color: white;
  pointer-events: none;
}

@media (max-width: 990px)
{
  .fa-chevron-down
  {
    left: 85%;
  }
}

.corresp-fields,.addr-fields
{
  display: none;
}

.quote-tariff-form
{
  background-color: #3e1d55;
  padding-bottom: 80px;
}

.quote-tariff-form .row
{
  margin-top: 20px;
}

.quote-tariff-form .wpcf7-text,.quote-tariff-form .wpcf7-textarea
{
  color: #a7226e
;
  font-size: 18px;
  font-weight: 300;
  border: 1px solid white;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
}

.quote-tariff-form label
{
  font-size: 26px;
  font-weight: 300;
  color: #3e1d55;
}

.quote-tariff-form select
{
  color: #a7226e
;
  font-size: 18px;
  font-weight: 300;
  border: 1px solid white;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  height: 47px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  width: 100%;
}

.quote-tariff-form .form-subhead
{
  color: white;
}

.quote-tariff-form .form-subhead-detailed
{
  color: #464e51;
}

@media (max-width: 991px)
{
  .quote-tariff-form label
  {
    font-size: 22px;
  }
}

.quote-tariff-form label.btn
{
  font-size: 20px;
  margin-bottom: 5px;
  border: none;
}

.quote-tariff-form .quote-tariff-submit-row
{
  margin-top: 50px;
}

.quote-tariff-form .form-subheading
{
  color: white;
  font-size: 36px;
  font-weight: 300;
  margin-bottom: 20px;
  line-height: 1.1;
}

@media (max-width: 991px)
{
  .quote-tariff-form .form-subheading
  {
    font-size: 28px;
  }
}

.quote-tariff-form .quote-btn-primary
{
  font-size: 20px;
}

.quote-tariff-form .quote-btn-primary.active
{
  color: white;
  background: #464e51;
  line-height: 10px;
  padding: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  font-weight: 300;
  padding: 20px;
  border: none;
}

.quote-tariff-form .quote-page-submit
{
  color: white;
  background: #464e51;
  border: none;
  line-height: 10px;
  padding: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  font-weight: 300;
  padding: 25px;
  padding-left: 80px;
  padding-right: 80px;
  font-size: 30px;
}

.quote-tariff-form .quote-page-submit:hover,.quote-tariff-form .quote-page-submit:focus,.quote-tariff-form .quote-page-submit:active
{
  color: #464e51;
  text-decoration: none;
  background-color: white;
}

@media (max-width: 991px)
{
  .quote-tariff-form .quote-page-submit
  {
    font-size: 26px;
  }
}

.Find input,.BusinessFind input
{
  background-color: #464e51;
  color: white !important;
  outline: none !important;
  cursor: pointer !important;
}

.pcatext.pcanotification
{
  display: none !important;
}

.main-results-heading
{
  color: white;
  font-weight: 300;
  margin-top: 10px;
  font-size: 38px;
}

@media (max-width: 600px)
{
  .main-results-heading
  {
    font-size: 24px;
  }
}

.e7-dropdown-container
{
  display: none;
}

.cost-elec-detailed,.cost-gas-detailed
{
  display: none;
}

.mpas-fields-gas,.mpas-fields-elec
{
  display: none;
}

.elec-form-business .mpas-fields-gas,.gas-form-business .mpas-fields-elec
{
  display: none !important;
}

.front-new-section
{
  background-color: #a7226e
;
  color: white;
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
}

.front-new-section-alternate
{
  background-color: white;
  color: #333;
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
}

.new-title
{
  font-size: 40px;
  font-weight: 300;
  color: white;
  margin-top: 0px;
  margin-bottom: 20px;
  text-align: center;
}

@media (max-width: 1199px)
{
  .new-title
  {
    font-size: 30px;
  }
}

.new-title-alternate
{
  font-size: 40px;
  font-weight: 300;
  color: #a7226e
;
  margin-top: 0px;
  margin-bottom: 20px;
  text-align: center;
}

@media (max-width: 1199px)
{
  .new-title-alternate
  {
    font-size: 30px;
  }
}

.new-section-copy
{
  font-size: 18px;
  font-weight: 300;
  color: white;
  margin-bottom: 20px;
  text-align: center;
}

.new-section-copy-alternate
{
  font-size: 18px;
  font-weight: 300;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.new-section-copy a
{
  color: white !important;
  font-weight: 600;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.new-section-copy a:hover,.new-section-copy a:focus,.new-section-copy a:active
{
  color: #464e51 !important;
  text-decoration: none !important;
}

.new-section-copy-alternate a
{
  color: #a7226e
 !important;
  font-weight: 600;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.new-section-copy-alternate a:hover,.new-section-copy-alternate a:focus,.new-section-copy-alternate a:active
{
  color: #464e51 !important;
  text-decoration: none !important;
}

.front-new-customer-section
{
  padding-bottom: 350px;
}

.customer-circle
{
  background-color: #464e51;
  width: 400px;
  height: 400px;
  margin-top: 100px;
  padding: 50px;
  padding-top: 100px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

@media (max-width: 991px)
{
  .customer-circle
  {
    width: 280px;
    height: 280px;
    padding: 40px;
    padding-top: 75px;
  }
}

.customer-circle-copy
{
  font-size: 34px;
  font-weight: 300;
  color: white;
  text-align: center;
}

@media (max-width: 991px)
{
  .customer-circle-copy
  {
    font-size: 24px;
  }
}

@media (min-width: 1200px)
{
  .container
  {
    padding: 40px 0 0 0;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
  }
}

.Bank1 input,.Bank2 input,.Bank3 input
{
  width: 40px !important;
  float: left;
  display: block;
  margin-right: 10px;
  margin-top: 0 !important;
}

.Bank1 input input,.Bank2 input input,.Bank3 input input
{
  margin: 0 !important;
}

.sortcode-wrapper
{
  padding-top: 20px;
}

.sortcode-wrapper span,.sortcode-wrapper p
{
  display: block;
  float: left;
  margin-left: 5px;
  margin-right: 0px;
  line-height: 1.4em;
}

.sortcode-wrapper span.sortcode-text
{
  margin-top: 5px;
}

.sortcode-wrapper p
{
  display: inline !important;
}

.sortcode-wrapper br
{
  display: none !important;
}

.results-offer-round
{
  height: 280px;
  width: 280px;
  padding-top: 70px;
  padding-left: 20px;
  padding-right: 20px;
  display: inline-block;
}

.results-offer-round-top
{
  font-weight: 900;
  font-size: 78px;
  color: #464e51;
  line-height: 0.8;
}

.results-offer-round-mid
{
  font-size: 24px;
  color: #464e51;
}

.results-offer-round-bot
{
  font-size: 16px;
  line-height: 0;
}

.sad-box
{
  height: 230px;
  max-width: 270px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
  margin-bottom: 30px;
  padding: 10px;
  display: inline-block;
}

@media (max-width: 1200px) and (min-width: 585px)
{
  .sad-box
  {
    position: relative;
    top: 30px;
    margin-bottom: 60px;
  }
}

.sad-box-title
{
  color: #a7226e
;
  font-size: 32px;
  font-weight: 300;
  margin-top: 10px;
}

.sad-box-strong
{
  font-weight: 700;
  font-size: 42px;
}

.offer-round-mobile
{
  display: none;
  margin-top: -50px;
}

@media (max-width: 991px)
{
  .offer-round-desktop
  {
    display: none;
  }

  .offer-round-mobile
  {
    display: inline-block;
  }
}

.sign-up-mobile
{
  display: none;
}

@media (max-width: 991px)
{
  .sign-up-desktop
  {
    display: none;
  }

  .sign-up-mobile
  {
    display: block;
  }
}

.gas-field
{
  display: none;
}

.pca .pcaitem
{
  font-size: 15px !important;
  font-family: 'Work Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
}

.rac-eac
{
  color: white !important;
}

.row.rac-eac p
{
  line-height: 1.3;
}

.hero-section
{
  min-height: 720px;
}

.hero-background-overlay
{
  padding-bottom: 40px;
  min-height: 740px;
}

@media (max-width: 1199px)
{
  .hero-background-overlay
  {
    background-color: rgba(255,255,255,0.88);
  }
}

.hero-heading
{
  font-size: 56px;
  font-weight: 300;
  color: #464e51;
  margin-bottom: 50px;
  padding-top: 20px;
}

@media (max-width: 1199px)
{
  .hero-heading
  {
    font-size: 36px;
  }
}

.hero-copy
{
  font-size: 24px;
  font-weight: 300;
  color: #a7226e
;
}

.hero-icon-row
{
  margin-top: 30px;
}

.hero-icon-text
{
  color: #a7226e
;
  display: inline;
  font-size: 30px;
  font-weight: 300;
  margin-left: 15px;
}

@media (max-width: 991px)
{
  .hero-icon-text
  {
    font-size: 22px;
  }

  .hero-icon-row img
  {
    width: 50px;
  }
}

.hero-postcode
{
  margin-bottom: 50px;
}

.hero-subheading
{
  color: #a7226e
;
  font-size: 24px;
  font-weight: 300;
  margin-bottom: 15px;
}

.hero-icon-row
{
  margin-top: 30px;
}

.hero-input
{
  color: #a7226e
;
  text-align: center;
  font-size: 16px;
  font-weight: 300;
  border: 1px solid #a7226e
;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  width: 260px;
  margin-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
}

@media (max-width: 1199px)
{
  .hero-input
  {
    width: 200px;
  }
}

.hero-go
{
  color: white;
  background: #464e51;
  background: -moz-linear-gradient(left, #464e51 0%, #a7226e
 100%);
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, #464e51), color-stop(100%, #a7226e
));
  background: -webkit-linear-gradient(left, #464e51 0%, #a7226e
 100%);
  background: -o-linear-gradient(left, #464e51 0%, #a7226e
 100%);
  background: -ms-linear-gradient(left, #464e51 0%, #a7226e
 100%);
  background: linear-gradient(to right, #464e51 0%, #a7226e
 100%);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  font-size: 20px;
  padding: 10px;
}

.hero-go:hover,.hero-go:focus,.hero-go:active
{
  color: white;
  text-decoration: none;
}

.front-hero-section
{
  padding-top: 30px;
  padding-bottom: 240px;
}

@media (max-width: 991px)
{
  .front-hero-section
  {
    padding-bottom: 30px;
  }
}

.front-hero-heading
{
  font-size: 56px;
  font-weight: 300;
  color: #a7226e
;
  margin: 0px;
}

@media (max-width: 1199px)
{
  .front-hero-heading
  {
    font-size: 34px;
  }
}

.front-hero-heading span
{
  color: #464e51;
  font-weight: 900;
}

.front-hero-subheading
{
  color: white;
  font-size: 36px;
  font-weight: 300;
  margin-top: 0px;
  margin-bottom: 15px;
}

.front-hero-copy
{
  font-size: 16px;
  font-weight: 300;
  color: white;
}

.front-hero-postcode
{
  background-color: rgba(87,43,130,0.87);
  padding: 30px;
  padding-bottom: 40px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
}

@media (max-width: 991px)
{
  .front-hero-postcode
  {
    margin-top: 30px;
  }
}

.front-hero-input
{
  color: #a7226e
 !important;
  text-align: left;
  font-size: 24px;
  font-weight: 300;
  width: 100%;
  display: block;
  border: 1px solid #a7226e
;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 15px;
  background: white;
}

.front-hero-input:hover,.front-hero-input:focus,.front-hero-input:active
{
  outline: none !important;
}

@media (max-width: 1199px)
{
  .front-hero-input
  {
    font-size: 18px;
  }
}

.front-hero-go
{
  color: white;
  background: #464e51;
  line-height: 10px;
  font-size: 24px;
  padding: 15px;
  padding-left: 50px;
  padding-right: 50px;
  border-color: transparent;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.front-hero-go:hover,.front-hero-go:focus,.front-hero-go:active
{
  color: #464e51;
  background-color: white;
  text-decoration: none;
}

@media (max-width: 1199px)
{
  .front-hero-go
  {
    font-size: 18px;
  }
}

@media (max-width: 991px)
{
  .front-hero-go
  {
    display: block;
    width: 200px;
    margin: 0 auto;
    margin-top: 10px;
  }
}

.efl-form .wpcf7-text,.form-section .wpcf7-textarea
{
  border: 1px solid #a7226e
;
}

.efl-form .form-control
{
  border: 1px solid #a7226e
;
  padding-top: 20px;
  margin-top: 20px;
  height: 47px;
}

.efl-form .wpcf7-submit
{
  border: 2px solid #a7226e
;
  color: #a7226e
;
}

.efl-form .wpcf7-submit:hover
{
  background-color: #a7226e
;
  color: #fff;
}

.find-out
{
  text-align: center;
}

.find-out a
{
  color: #a7226e
;
  text-decoration: underline;
  font-size: 18px;
}

.postid-1438 .blog-top-section .row img
{
  display: none;
}

.form-copy-efl
{
  color: white;
  font-size: 18px;
  font-weight: 200;
}

.form-copy-efl a
{
  color: #464e51;
  font-size: 18px;
  font-weight: 200;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.form-copy-efl a:hover,.form-copy-efl a:focus,.form-copy-efl a:active
{
  text-decoration: none;
  color: white;
}

.page-template-template-front .hero-section,.page-template-template-residential-landing .hero-section
{
  position: relative;
}

.page-template-template-front .hero-section .featherlight,.page-template-template-residential-landing .hero-section .featherlight
{
  position: absolute;
  z-index: 5;
}

.hidden
{
  display: none;
}

.disabled
{
  opacity: 0.5;
  cursor: default;
  pointer-events: none;
  background: grey !important;
}

.led-ces-section
{
  width: 100%;
  position: relative;
  background-image: url(img/bulb_banner.jpg);
  background-size: 100%;
  -moz-background-size: 100%;
  -webkit-background-size: 100%;
  background-color: #599a98;
  height: 500px;
}

@media screen and (max-width: 1200px)
{
  .led-ces-section
  {
    height: 430px;
  }
}

@media screen and (max-width: 991px)
{
  .led-ces-section
  {
    height: 300px;
  }
}

@media screen and (max-width: 768px)
{
  .led-ces-section
  {
    height: 200px;
  }
}

.calculator-heading
{
  font-size: 48px;
  color: white;
  font-weight: 300;
  margin-top: 124px;
}

@media screen and (max-width: 1200px)
{
  .calculator-heading
  {
    font-size: 36px;
    margin-top: 124px;
  }
}

@media screen and (max-width: 991px)
{
  .calculator-heading
  {
    font-size: 28px;
    margin-top: 100px;
  }
}

@media screen and (max-width: 768px)
{
  .calculator-heading
  {
    font-size: 24px;
    margin-top: 35px;
    text-align: center;
  }
}

.calculator-heading--2
{
  font-size: 40px;
  color: white;
  font-weight: 300;
  margin-top: 120px;
}

@media screen and (max-width: 1200px)
{
  .calculator-heading--2
  {
    font-size: 32px;
    margin-top: 110px;
  }
}

@media screen and (max-width: 991px)
{
  .calculator-heading--2
  {
    font-size: 28px;
    margin-top: 85px;
  }
}

@media screen and (max-width: 768px)
{
  .calculator-heading--2
  {
    font-size: 24px;
    text-align: center;
    margin-top: 20px;
  }
}

.calculator
{
  margin-top: -1px;
}

.navbar-logo-2
{
  width: 200px;
  height: auto;
}

.calculator-heading span
{
  font-weight: 500;
}

.lightbulb-wrapper p,.lightbulb-wrapper label
{
  color: white;
  font-weight: 300 !important;
}

.lightbulb-wrapper p
{
  margin-bottom: 20px;
  font-size: 16px;
}

.lightbulb-wrapper .unsure
{
  margin-right: 10px;
  display: inline-block;
}

.lightbulb-wrapper .usage
{
  padding-left: 20px;
  margin-left: 10px;
  height: 40px;
  color: #320735;
  width: 280px;
  display: inline-block;
}

.lightbulb-wrapper .feature-para
{
  font-size: 28px !important;
  font-weight: 300;
}

.feature-para span
{
  font-weight: bold;
}

.lightbulb-wrapper input
{
  border-radius: 5px;
  border: 0;
  color: #320735;
}

.lightbulb-wrapper input[type="radio"]
{
  display: none;
}

.lightbulb-wrapper input[type="text"],.lightbulb-wrapper input[type="email"],.lightbulb-wrapper select
{
  width: 100% !important;
  margin-bottom: 10px;
  font-size: 16px;
  padding-left: 20px;
  color: #320735;
  height: 40px;
}

.lightbulb-wrapper .calculator__submit
{
  background: #464e51;
  color: white;
  margin-top: 30px;
  margin-bottom: 20px;
  border: 0;
  padding: 10px 20px;
}

.led-table-header
{
  font-size: 18px;
  color: white;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  margin: 0;
}

.calculator__house-type
{
  display: block;
  float: left;
  background: white;
  font-weight: normal;
  font-size: 16px;
  color: #320735;
  width: calc(20% - 15px);
  margin-bottom: 20px;
  margin-right: 15px;
  font-weight: 300;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
}

.calculator__house-type:hover,.lightbulb-wrapper .active
{
  background: #464e51;
  color: white;
}

.calculator__house-type-wrapper
{
  margin-right: -15px;
}

.lightbulb-wrapper input[type="checkbox"]
{
  margin-right: 10px;
}

.calculator__form-wrapper
{
  margin-right: -15px;
  margin-left: -15px;
}

.calculator__row
{
  margin-bottom: 20px;
  padding-top: 10px;
  border-bottom: 2px solid #464e51;
}

.calculator__image
{
  height: 80px;
  display: inline-block;
  margin: 0 10px;
}

.calculator__field
{
  padding: 0 20px;
  float: left;
}

.calculator__name
{
  text-align: center;
  font-size: 16px;
  margin: 0;
  margin-top: 3px;
  font-weight: 300;
  line-height: 0.8;
  color: white;
}

.calculator__heat-label
{
  display: inline-block;
  font-size: 16px;
  margin-right: 15px;
  width: auto;
  color: white;
}

.calculator__heat-input
{
  width: 60px;
  text-align: center;
  font-size: 16px;
}

.lightbulb-wrapper div.wpcf7-response-output
{
  text-align: center;
  color: white;
  font-size: 20px !important;
  border-color: #a7226e
 !important;
}

.calculator__heat-wrapper
{
  margin: 5px 0;
}

.bulb-banner-image
{
  width: 70%;
  height: auto;
  float: right;
}

@media screen and (max-width: 1200px)
{
  .bulb-banner-image
  {
    margin-top: 20px;
  }
}

@media screen and (max-width: 991px)
{
  .bulb-banner-image
  {
    margin-top: 25px;
  }
}

@media screen and (max-width: 768px)
{
  .bulb-banner-image
  {
    display: none;
  }
}

.lightbulb-wrapper .footer-contact
{
  color: white;
}

.lightbulb-wrapper .footer-social img
{
  width: 30px;
  height: auto;
}

.footer-contact-icon
{
  width: 30px;
  height: auto;
}

.lightbulb-wrapper .footer-section .container
{
  border: 0 !important;
}

.lightbulb-wrapper .footer-nav
{
  border: 0 !important;
}

@media screen and (max-width: 991px)
{
  .calculator *
  {
    text-align: center !important;
  }

  .calculator input[type="text"],.calculator input[type="email"],#usage
  {
    text-align: left !important;
  }

  .led-table-header
  {
    display: none;
  }
}

@media screen and (max-width: 768px)
{
  .calculator__house-type
  {
    width: 100%;
    margin-bottom: 10px;
  }

  .calculator__house-type-wrapper
  {
    margin-right: 0px;
  }

  .navbar-logo-2
  {
    width: 150px;
    height: auto;
  }
}

.question-mark
{
  background: #464e51;
  color: white;
  border-radius: 50%;
  height: 36px;
  display: block;
  width: 36px;
  font-size: 24px;
  text-align: center;
  padding-top: 2px;
  position: absolute;
  top: 2px;
  right: 2px;
  z-index: 999;
}

.question-mark:hover
{
  background: #a7226e
;
  color: #fff;
}

.ppa-section-1
{
  background: url(img/curve-top-home.png) no-repeat;
  background-size: 100% 150px;
  background-color: #3e2255;
  padding-top: 100px;
  margin-top: -1px;
}

@media (max-width: 1199px)
{
  .ppa-section-1
  {
    background-size: auto auto !important;
  }
}

@media (max-width: 990px)
{
  .ppa-section-1
  {
    padding-top: 100px;
    padding-bottom: 0;
  }
}

.ppa-section-1 .col-md-6
{
  height: 600px;
  position: relative;
}

@media (max-width: 990px)
{
  .ppa-section-1 .col-md-6
  {
    padding: 0 20px;
  }
}

.ppa-section-2
{
  background: url(img/ppa-2.jpg) no-repeat;
  background-size: 100% 150px;
  background-color: #503465;
  padding-top: 100px;
}

@media (max-width: 1199px)
{
  .ppa-section-2
  {
    background-size: auto auto !important;
  }
}

@media (max-width: 990px)
{
  .ppa-section-2
  {
    padding-top: 100px;
    padding-bottom: 0;
  }
}

.ppa-section-2 .col-md-6
{
  height: 450px;
  position: relative;
}

@media (max-width: 990px)
{
  .ppa-section-2 .col-md-6
  {
    padding: 10px 20px;
  }
}

.ppa-section-3
{
  background: url(img/ppa-3.jpg) no-repeat;
  background-size: 100% 150px;
  background-color: #3e2255;
  padding-top: 80px;
}

@media (max-width: 1199px)
{
  .ppa-section-3
  {
    background-size: auto auto !important;
  }
}

@media (max-width: 990px)
{
  .ppa-section-3
  {
    padding-top: 100px;
    padding-bottom: 0;
  }
}

.ppa-section-3 .col-md-6
{
  height: 660px;
  position: relative;
}

@media (max-width: 990px)
{
  .ppa-section-3 .col-md-6
  {
    padding: 10px 20px;
  }
}

.ppa-section-4
{
  background: url(img/ppa-4.jpg) no-repeat;
  background-size: 100% 150px;
  background-color: #503465;
  padding-top: 150px;
}

@media (max-width: 1199px)
{
  .ppa-section-4
  {
    background-size: auto auto !important;
  }
}

@media (max-width: 990px)
{
  .ppa-section-4
  {
    padding-top: 100px;
    padding-bottom: 0;
  }
}

.ppa-section-4 .col-md-6
{
  height: 390px;
  position: relative;
}

@media (max-width: 990px)
{
  .ppa-section-4 .col-md-6
  {
    padding: 10px 20px;
  }
}

.ppa-section-5
{
  background: url(img/ppa-5.jpg) no-repeat;
  background-size: 100% 150px;
  background-color: #3e2255;
  padding-top: 50px;
  padding-bottom: 100px;
}

@media (max-width: 1199px)
{
  .ppa-section-5
  {
    background-size: auto auto !important;
  }
}

@media (max-width: 990px)
{
  .ppa-section-5
  {
    padding-top: 140px;
    padding-bottom: 60px;
  }
}

.ppa-section-5 .col-md-6
{
  position: relative;
}

@media (max-width: 990px)
{
  .ppa-section-5 .col-md-6
  {
    padding: 0 20px;
  }
}

.ppa-section-5 .wpcf7-not-valid-tip
{
  margin-top: -10px;
  height: 2px;
}

.ppa-section-5 .bottom-curve
{
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
}

.ppa-map
{
  position: relative;
  display: inline-block;
}

.ppa-map .icon-svg
{
  width: 40px;
  position: relative;
  z-index: 99;
  pointer-events: none;
}

.ppa-map .icon-svg .cls-1
{
  pointer-events: all;
  cursor: pointer;
}

.ppa-map .icon-svg .cls-2
{
  pointer-events: all;
  cursor: pointer;
}

.ppa-map .icon-svg .cls-3
{
  pointer-events: all;
  cursor: pointer;
}

.ppa-map .icon-svg .cls-4
{
  pointer-events: all;
  cursor: pointer;
}

.ppa-map .icon-svg .cls-5
{
  pointer-events: all;
  cursor: pointer;
}

.ppa-map .icon-svg .cls-6
{
  pointer-events: all;
  cursor: pointer;
}

.ppa-map .icon-svg .cls-7
{
  pointer-events: all;
  cursor: pointer;
}

.ppa-map .icon-svg .cls-8
{
  pointer-events: all;
  cursor: pointer;
}

.ppa-map .icon-svg .cls-9
{
  pointer-events: all;
  cursor: pointer;
}

.ppa-map .icon-svg .st0
{
  pointer-events: all;
  cursor: pointer;
}

.ppa-map .icon-svg .st1
{
  pointer-events: all;
  cursor: pointer;
}

.ppa-map .icon-svg .st2
{
  pointer-events: all;
  cursor: pointer;
}

.ppa-map .icon-svg .st3
{
  pointer-events: all;
  cursor: pointer;
}

.ppa-map .icon-svg .st4
{
  pointer-events: all;
  cursor: pointer;
}

@media (max-width: 990px)
{
  .ppa-map .icon-svg
  {
    width: 20px;
  }
}

@media (max-width: 990px)
{
  .ppa-map
  {
    text-align: left;
    display: block;
  }
}

.ppa-map-overlay
{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  cursor: default;
  z-index: 9995;
  pointer-events: all;
  background: rgba(0,0,0,0.6);
}

.map-overlay__close
{
  position: absolute !important;
  right: -60px;
  top: -60px;
  width: 50px !important;
  height: 50px !important;
  cursor: pointer;
}

@media (max-width: 690px)
{
  .map-overlay__close
  {
    top: 20px;
    right: 20px;
  }
}

.underline
{
  text-decoration: underline !important;
}

.underline:hover
{
  text-decoration: none !important;
}

.ppa-map-overlay-inner
{
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: 550px;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border: 3px solid #fff;
  text-align: left;
  padding: 20px;
  z-index: 9995;
}

@media (max-width: 590px)
{
  .ppa-map-overlay-inner
  {
    left: 20px;
    right: 20px;
    top: 120px;
    width: auto;
    -ms-transform: translate(0%, 0%);
    -webkit-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    padding: 0;
  }
}

@media (max-width: 990px)
{
  .ppa-map-overlay-inner
  {
    height: auto;
    text-align: center;
  }
}

.ppa-map-overlay-inner h2
{
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 10px;
  line-height: 32px;
}

@media (max-width: 690px)
{
  .ppa-map-overlay-inner h2
  {
    font-size: 24px !important;
  }
}

.ppa-map-overlay-inner h3
{
  font-size: 24px;
  font-weight: 300;
  margin-bottom: 10px;
  line-height: 30px;
}

@media (max-width: 690px)
{
  .ppa-map-overlay-inner h3
  {
    font-size: 20px !important;
  }
}

.ppa-map-overlay-inner p
{
  font-size: 22px;
  line-height: 28px;
  font-weight: 300;
  margin-top: 0px;
}

.ppa-map-overlay-inner--blue
{
  background: #b5c8e0;
}

.ppa-map-overlay-inner--blue h2
{
  color: #5b73b5;
}

.ppa-map-overlay-inner--blue h3
{
  color: #5b73b5;
}

.ppa-map-overlay-inner--blue p
{
  color: #5b73b5;
}

@media (max-width: 690px)
{
  .ppa-map-overlay-inner--blue .map-overlay__close .cls-3-exit
  {
    fill: #5b73b5 !important;
  }

  .ppa-map-overlay-inner--blue .map-overlay__close .cls-4-exit
  {
    fill: #5b73b5 !important;
  }
}

.ppa-map-overlay-inner--light-blue
{
  background: #e8f4fb;
}

.ppa-map-overlay-inner--light-blue h2
{
  color: #5b73b5;
}

.ppa-map-overlay-inner--light-blue h3
{
  color: #5b73b5;
}

.ppa-map-overlay-inner--light-blue p
{
  color: #5b73b5;
}

@media (max-width: 690px)
{
  .ppa-map-overlay-inner--light-blue .map-overlay__close .cls-3-exit
  {
    fill: #5b73b5 !important;
  }

  .ppa-map-overlay-inner--light-blue .map-overlay__close .cls-4-exit
  {
    fill: #5b73b5 !important;
  }
}

.ppa-map-overlay-inner--green
{
  background: #edf1d2;
}

.ppa-map-overlay-inner--green h2
{
  color: #384e3c;
}

.ppa-map-overlay-inner--green h3
{
  color: #384e3c;
}

.ppa-map-overlay-inner--green p
{
  color: #384e3c;
}

@media (max-width: 690px)
{
  .ppa-map-overlay-inner--green .map-overlay__close .cls-3-exit
  {
    fill: #384e3c !important;
  }

  .ppa-map-overlay-inner--green .map-overlay__close .cls-4-exit
  {
    fill: #384e3c !important;
  }
}

.ppa-map-overlay-inner--purple
{
  background: #e0d6e7;
}

.ppa-map-overlay-inner--purple h2
{
  color: #402b7d;
}

.ppa-map-overlay-inner--purple h3
{
  color: #402b7d;
}

.ppa-map-overlay-inner--purple p
{
  color: #402b7d;
}

@media (max-width: 690px)
{
  .ppa-map-overlay-inner--purple .map-overlay__close .cls-3-exit
  {
    fill: #402b7d !important;
  }

  .ppa-map-overlay-inner--purple .map-overlay__close .cls-4-exit
  {
    fill: #402b7d !important;
  }
}

.ppa-map-overlay-inner--yellow
{
  background: #Fcf9e7;
}

.ppa-map-overlay-inner--yellow h2
{
  color: #e79640;
}

.ppa-map-overlay-inner--yellow h3
{
  color: #e79640;
}

.ppa-map-overlay-inner--yellow p
{
  color: #e79640;
}

@media (max-width: 690px)
{
  .ppa-map-overlay-inner--yellow .map-overlay__close .cls-3-exit
  {
    fill: #e79640 !important;
  }

  .ppa-map-overlay-inner--yellow .map-overlay__close .cls-4-exit
  {
    fill: #e79640 !important;
  }
}

.map-image-icon
{
  display: none;
}

.ppa-map-overlay-inner--blue .blue-map-image
{
  display: block;
}

.ppa-map-overlay-inner--yellow .yellow-map-image
{
  display: block;
}

.ppa-map-overlay-inner--purple .purple-map-image
{
  display: block;
}

.ppa-map-overlay-inner--light-blue .light-blue-map-image
{
  display: block;
}

.ppa-map-overlay-inner--green .green-map-image
{
  display: block;
}

.ppa-map-icon
{
  position: absolute;
  pointer-events: none;
  transition: all 0s;
  cursor: pointer;
}

.ppa-map-icon:hover
{
  z-index: 200 !important;
}

@media (max-width: 990px)
{
  .ppa-map-icon
  {
    position: relative;
    top: auto !important;
    display: block !important;
    left: auto !important;
  }
}

@media (max-width: 990px)
{
  .ppa-map-icon .icon-svg
  {
    float: left;
    display: block !important;
  }
}

.map-icon-20
{
  top: 4%;
  left: 44%;
  z-index: 1;
}

.map-icon-21
{
  top: 4%;
  left: 47%;
  z-index: 1;
}

.map-icon-22
{
  top: 4%;
  left: 50%;
  z-index: 1;
}

.map-icon-23
{
  top: 4%;
  left: 53%;
  z-index: 1;
}

.map-icon-24
{
  top: 4%;
  left: 56%;
  z-index: 1;
}

.map-icon-19
{
  top: 6%;
  left: 51%;
  z-index: 2;
}

.map-icon-4
{
  top: 15%;
  left: 18%;
  z-index: 3;
}

.map-icon-9
{
  top: 18%;
  left: 45%;
  z-index: 4;
}

.map-icon-26
{
  top: 20%;
  left: 44%;
  z-index: 5;
}

.map-icon-18
{
  top: 22%;
  left: 21%;
  z-index: 6;
}

.map-icon-25
{
  top: 25%;
  left: 39%;
  z-index: 7;
}

.map-icon-45
{
  top: 28%;
  left: 31%;
  z-index: 8;
}

.map-icon-27
{
  top: 34%;
  left: 28%;
  z-index: 9;
}

.map-icon-15
{
  top: 35%;
  left: 60%;
  z-index: 10;
}

.map-icon-5
{
  top: 37%;
  left: 46%;
  z-index: 11;
}

.map-icon-17
{
  top: 39%;
  left: 46%;
  z-index: 12;
}

.map-icon-16
{
  top: 42%;
  left: 45%;
  z-index: 13;
}

.map-icon-34
{
  top: 43%;
  left: 69%;
  z-index: 14;
}

.map-icon-35
{
  top: 43%;
  left: 72%;
  z-index: 15;
}

.map-icon-14
{
  top: 44%;
  left: 66%;
  z-index: 16;
}

.map-icon-38
{
  top: 46%;
  left: 59%;
  z-index: 17;
}

.map-icon-28
{
  top: 47%;
  left: 47%;
  z-index: 18;
}

.map-icon-36
{
  top: 47%;
  left: 68%;
  z-index: 19;
}

.map-icon-1
{
  top: 48%;
  left: 51%;
  z-index: 20;
}

.map-icon-44
{
  top: 51%;
  left: 66%;
  z-index: 21;
}

.map-icon-33
{
  top: 52%;
  left: 61%;
  z-index: 22;
}

.map-icon-43
{
  top: 53%;
  left: 53%;
  z-index: 23;
}

.map-icon-3
{
  top: 53%;
  left: 71%;
  z-index: 24;
}

.map-icon-37
{
  top: 54%;
  left: 68%;
  z-index: 25;
}

.map-icon-8
{
  top: 55%;
  left: 63%;
  z-index: 26;
}

.map-icon-11
{
  top: 57%;
  left: 60%;
  z-index: 27;
}

.map-icon-13
{
  top: 57%;
  left: 47%;
  z-index: 28;
}

.map-icon-41
{
  top: 58%;
  left: 75%;
  z-index: 29;
}

.map-icon-7
{
  top: 59%;
  left: 79%;
  z-index: 30;
}

.map-icon-10
{
  top: 60%;
  left: 74%;
  z-index: 31;
}

.map-icon-12
{
  top: 62%;
  left: 43%;
  z-index: 32;
}

.map-icon-2
{
  top: 63%;
  left: 50%;
  z-index: 33;
}

.map-icon-40
{
  top: 66%;
  left: 54%;
  z-index: 34;
}

.map-icon-42
{
  top: 69%;
  left: 72%;
  z-index: 35;
}

.map-icon-29
{
  top: 70%;
  left: 45%;
  z-index: 36;
}

.map-icon-30
{
  top: 71%;
  left: 35%;
  z-index: 37;
}

.map-icon-32
{
  top: 74%;
  left: 50%;
  z-index: 38;
}

.map-icon-6
{
  top: 79%;
  left: 52%;
  z-index: 39;
}

.map-icon-31
{
  top: 84%;
  left: 37%;
  z-index: 40;
}

.map-icon-39
{
  top: 85%;
  left: 34%;
  z-index: 41;
}

@media (max-width: 990px)
{
  .map-image
  {
    display: none;
  }
}

.map-overlay-left
{
  float: left;
  width: 40%;
  position: relative;
}

.map-overlay-left img
{
  width: 100%;
}

@media (max-width: 690px)
{
  .map-overlay-left img
  {
    width: 120px;
    margin: 0 auto;
  }
}

@media (max-width: 690px)
{
  .map-overlay-left
  {
    width: 100%;
    padding: 0;
    padding-top: 20px;
  }
}

.map-overlay-right
{
  float: left;
  width: 60%;
  text-align: left;
  padding-left: 20px;
}

@media (max-width: 690px)
{
  .map-overlay-right
  {
    width: 100%;
    padding: 20px;
  }
}

.map-mobile
{
  display: none;
  font-size: 16px;
  float: left;
  font-weight: normal;
  color: #fff;
  padding-left: 20px;
}

@media (max-width: 990px)
{
  .map-mobile
  {
    display: block;
    margin: 5px 0 15px;
    pointer-events: all;
  }
}

.grow
{
  opacity: 0;
}

@media (max-width: 990px)
{
  .grow
  {
    opacity: 1 !important;
    -webkit-transform: scale(1) translate3d(0, 0, 0) !important;
    -o-transform: scale(1) translate3d(0, 0, 0) !important;
    -moz-transform: scale(1) translate3d(0, 0, 0) !important;
    transform: scale(1) translate3d(0, 0, 0) !important;
  }
}

.inputfile
{
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile+label
{
  font-weight: 500;
  color: white;
  background-color: #c43427;
  padding: 6px 20px;
  height: 35px;
  border-radius: 5px;
  display: inline-block;
  /* float: right; */
  margin-left: 10px;
}

.inputfile:focus+label,.inputfile+label:hover
{
  background-color: #c43427;
}

.inputfile+label
{
  cursor: pointer;
}

.inputfile:focus+label
{
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}

input[type="date"]:before
{
  content: attr(placeholder) !important;
  margin-right: 0.5em;
  color: #999999;
}

input[type="date"]:focus:before,input[type="date"]:valid:before
{
  content: "";
}

::-webkit-input-placeholder
{
  color: #999999;
}

:-ms-input-placeholder
{
  color: #999999;
}

::-moz-placeholder
{
  color: #999999;
  opacity: 1;
}

:-moz-placeholder
{
  color: #999999;
  opacity: 1;
}

.ppa-map-filter
{
  text-align: right;
  position: absolute;
  top: 40px;
  right: 0;
}

@media (max-width: 1199px)
{
  .ppa-map-filter
  {
    top: 0px;
  }
}

.ppa-map-filter h3
{
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #fff;
  font-weight: 500;
}

.ppa-map-filter label
{
  color: #fff;
  cursor: pointer;
  display: block;
  font-weight: normal;
  margin: 0;
  font-weight: 300;
}

.ppa-map-filter label span
{
  display: inline-block;
  position: relative;
  top: -6px;
  width: 45px;
  margin-right: 10px;
}

.ppa-map-filter svg
{
  height: 30px !important;
  vertical-align: middle;
  margin-right: 5px;
  position: relative;
  top: -7px;
}

@media (max-width: 1199px)
{
  .ppa-map-filter svg
  {
    height: 30px !important;
  }
}

.ppa-map-filter input
{
  zoom: 2;
}

@media (max-width: 990px)
{
  .ppa-map-filter
  {
    display: none;
  }
}

.ppa-map-filter .all-icon-svg
{
  left: 10px;
}

.int-results
{
  font-weight: 300;
  font-size: 16px;
  margin-top: 30px;
}

.int-til-details span
{
  font-weight: 600;
}

.int-terms
{
  margin-top: 30px;
  font-size: 12px;
}

.int-terms span
{
  font-weight: 600;
}

.int-numbers
{
  margin-bottom: 30px;
  font-size: 51px;
  line-height: 1;
}

.int-numbers-title
{
  font-size: 24px;
}

.int-numbers-saving
{
  color: #464e51;
}

.int-til,.int-quote
{
  border-bottom: 1px solid #464e51;
  padding-bottom: 30px;
  margin-bottom: 10px;
}

.int-offer-saving
{
  font-weight: 600;
}

.braintree-section .col-md-7
{
  display: table;
}

@media (max-width: 990px)
{
  .braintree-section .col-md-7
  {
    height: auto !important;
    display: block !important;
    padding: 0px 20px;
  }
}

.braintree-section .col-md-5
{
  display: table;
}

@media (max-width: 990px)
{
  .braintree-section .col-md-5
  {
    height: auto !important;
    display: block !important;
    padding: 0px 20px;
  }
}

@media (max-width: 990px)
{
  .braintree-section .section-image
  {
    margin-bottom: 40px;
  }
}

.braintree-section-1
{
  background-image: url(img/braintree-background-1.jpg);
  background-color: #599a98;
  background-size: 100% 700px;
  background-repeat: no-repeat;
  padding-top: 50px;
  padding-bottom: 50px;
}

@media (max-width: 1190px)
{
  .braintree-section-1
  {
    background-size: auto auto !important;
  }
}

@media (max-width: 1190px)
{
  .braintree-section-1
  {
    background-size: auto 100% !important;
  }
}

.braintree-section-1 .col-md-6
{
  height: 600px;
  position: relative;
}

@media (max-width: 990px)
{
  .braintree-section-1 .col-md-6
  {
    height: auto;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.braintree-section-1 .heading
{
  margin: 0 0 20px;
}

@media (max-width: 990px)
{
  .mobileLift
  {
    margin-top: -150px;
  }
}

.braintree-section-2
{
  background: #69418f;
}

@media (max-width: 1190px)
{
  .braintree-section-2
  {
    background-size: auto auto !important;
  }
}

.braintree-section-2 .col-md-7
{
  height: 400px;
}

.braintree-section-2 .col-md-5
{
  height: 400px;
}

.braintree-section-2 .section-content
{
  max-width: 100%;
}

.braintree-section-3
{
  background-image: url(img/braintree-background-2.jpg);
  background-size: 100% 150px;
  background-repeat: no-repeat;
  padding: 100px 0 60px;
}

@media (max-width: 1190px)
{
  .braintree-section-3
  {
    background-size: auto auto !important;
  }
}

.braintree-section-3 .col-md-7
{
  height: 400px;
}

.braintree-section-3 .col-md-5
{
  height: 400px;
}

.braintree-section-3 .section-content
{
  max-width: 100%;
  vertical-align: middle;
}

.braintree-section-3 .globe-image
{
  max-width: 350px;
}

.braintree-section-4
{
  background-image: url(img/braintree-background-3.jpg);
  background-repeat: no-repeat;
  background-size: 100% 100px;
  background-color: #6393c6;
  padding: 0;
  padding-top: 80px;
}

@media (max-width: 1190px)
{
  .braintree-section-4
  {
    background-size: auto auto !important;
  }
}

.braintree-section-4 .col-md-7
{
  height: 400px;
}

.braintree-section-4 .col-md-5
{
  height: 400px;
}

.braintree-section-4 .section-content
{
  max-width: 100%;
}

.braintree-section-4 .section-content img
{
  width: 100%;
  max-height: 100%;
  margin-top: -100px;
}

.braintree-section-5
{
  background-image: url(img/braintree-background-4.jpg);
  background-repeat: no-repeat;
  background-size: 100% 100px;
  padding: 0;
  padding-top: 150px;
}

@media (max-width: 1190px)
{
  .braintree-section-5
  {
    background-size: auto auto !important;
  }
}

@media (max-width: 990px)
{
  .braintree-section-5
  {
    background-size: 100% 20px !important;
    padding-top: 50px;
  }
}

.braintree-section-5 .section-circle
{
  padding-top: 65px;
  font-size: 24px;
  font-weight: 300;
  margin: 0 auto;
}

@media (min-width: 1600px)
{
  .braintree-section-5 .section-circle
  {
    font-size: 36px;
    padding-top: 85px;
  }
}

.til-lightbox-info
{
  color: black;
  margin-bottom: 0px;
  line-height: 1.2em;
  font-weight: 400;
  font-size: 14px;
}

.results-sign-up-consumer
{
  font-size: 24px;
  font-weight: 300;
  text-transform: uppercase;
  color: white;
  background-color: #a7226e
;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 0px;
  padding-bottom: 0px;
  border: none;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.results-sign-up-consumer:hover,.results-sign-up-consumer:focus,.results-sign-up-consumer:active
{
  color: #a7226e
;
  background-color: white;
  border: none;
}

.results-sign-up-consumer-cheap
{
  background-color: #464e51;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.results-sign-up-consumer-cheap:hover,.results-sign-up-consumer-cheap:focus,.results-sign-up-consumer-cheap:active
{
  color: #464e51;
}

.consumer-multi-tariff
{
  border: 3px solid #a7226e
;
  margin-bottom: 30px;
  border-radius: 20px;
}

.consumer-multi-tariff-cheap
{
  border: 3px solid #464e51;
}

.consumer-multi-tariff-name
{
  font-weight: 300;
  line-height: 1;
  font-size: 30px;
  margin-top: 10px;
  color: white;
}

@media (max-width: 767px)
{
  .consumer-multi-tariff-name
  {
    font-size: 24px;
  }
}

.consumer-multi-tariff-text
{
  margin: 0;
  line-height: 0.5;
  font-size: 18px;
  margin-bottom: 5px;
}

.consumer-multi-tariff-number-monthly
{
  margin: 0;
  font-weight: 600;
  line-height: 1.2;
  font-size: 30px;
}

@media (max-width: 767px)
{
  .consumer-multi-tariff-number-monthly
  {
    font-size: 24px;
    line-height: 1.6;
  }
}

.consumer-multi-tariff-number-yearly
{
  margin: 0;
  font-weight: 600;
  line-height: 1.2;
  font-size: 36px;
}

@media (max-width: 767px)
{
  .consumer-multi-tariff-number-yearly
  {
    font-size: 22px;
    line-height: 1.6;
  }
}

.consumer-multi-tariff-compare
{
  margin: 0;
  line-height: 1.1;
  font-weight: 300;
  font-size: 12px;
}

@media (max-width: 767px)
{
  .consumer-multi-tariff-compare
  {
    font-size: 11px;
  }
}

.consumer-multi-tariff-section
{
  padding: 20px;
  height: 120px;
}

@media (max-width: 767px)
{
  .consumer-multi-tariff-section
  {
    padding: 15px;
    height: 110px;
  }
}

.consumer-multi-tariff-section-thin
{
  height: 50px;
  padding: 5px;
}

@media (max-width: 767px)
{
  .consumer-multi-tariff-section-thin
  {
    height: 55px;
    padding: 10px;
  }
}

@media (max-width: 767px)
{
  .consumer-multi-tariff-name-box
  {
    height: auto;
    border-right: none !important;
  }
}

.consumer-multi-tariff-name-box-cheap
{
  background-color: #464e51;
  border-top-left-radius: 15px;
}

@media (max-width: 767px)
{
  .consumer-multi-tariff-name-box-cheap
  {
    border-top-right-radius: 15px;
  }
}

.consumer-multi-tariff-lozenge-cheap
{
  font-size: 12px;
  font-weight: 300;
  text-transform: uppercase;
  color: white;
  background-color: #464e51;
  margin-top: 10px;
  margin-bottom: 10px;
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
  border: none;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
}

@media (max-width: 767px)
{
  .consumer-multi-tariff-lozenge-cheap
  {
    margin-top: 0px;
  }
}

@media (max-width: 767px)
{
  .consumer-multi-tariff-discount
  {
    font-size: 14px;
    line-height: 1;
  }
}

.consumer-multi-tariff-led
{
  color: #ffb91c;
}

@media (max-width: 767px)
{
  .consumer-multi-tariff-led
  {
    font-size: 14px;
    line-height: 1;
  }
}

.consumer-multi-tariff-led-box
{
  background-color: #a7226e
;
  border-bottom-right-radius: 15px;
}

.consumer-signup-red
{
  color: #464e51;
}

@media (max-width: 767px)
{
  .consumer-multi-stage-indicator
  {
    margin-top: 40px;
  }
}

.consumer-multi-stage-indicator-fade
{
  opacity: 0.3;
}

.consumer-multi-stage-indicator-line
{
  border-bottom: 1px solid #464e51;
  opacity: 0.5;
}

.consumer-multi-stage-indicator-box
{
  margin-top: -35px;
}

.consumer-multi-stage-indicator-text
{
  font-size: 16px;
  margin: 0px;
  line-height: 1;
}

.consumer-multi-stage-indicator-image
{
  width: 32px;
  height: 32px;
}

@media (max-width: 767px)
{
  .consumer-multi-tariff-icon-mobile
  {
    display: none;
  }
}

@media (max-width: 767px)
{
  .consumer-multi-tariff-section-thin-dual-standing
  {
    border-right: none !important;
    border-bottom: 2px solid #a7226e
;
  }
}

.cheapTariff .consumer-multi-tariff
{
  border: 3px solid #464e51;
}

.cheapTariff .consumer-multi-tariff-name-box
{
  background: #464e51;
  border-radius: 10px 0 0 0px;
}

.cheapTariff .results-sign-up-consumer
{
  background: #464e51;
}

.cheapTariff .results-sign-up-consumer:hover
{
  background-color: #ffffff;
}

.tariffHidden
{
  display: none;
}

.consumer-multi-tariff-lozenge-cheap
{
  display: none;
}

.cheapTariff .consumer-multi-tariff-lozenge-cheap
{
  display: inline-block;
}

.showMoreTariff
{
  color: #fff;
  font-size: 24px;
  font-weight: 300;
  text-transform: uppercase;
  color: white;
  background-color: #464e51;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 5px;
  padding-bottom: 3px;
  border: none;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.showMoreTariff:hover
{
  color: #464e51;
  background-color: #ffffff;
}

.consumer-multi-tariff-text-monthly-cost
{
  margin-top: 20px;
}

.cheapTariff .consumer-multi-tariff-text-monthly-cost
{
  margin-top: 0px;
}

.consumer-multi-tariff
{
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.cheapTariff .consumer-multi-tariff
{
  opacity: 1;
}

.smart-section-1
{
  background-image: url(img/smart-background-1.jpg);
  background-size: 100% 130px;
  background-repeat: no-repeat;
  background-color: #3f2155;
  padding-top: 100px;
}

@media (max-width: 1190px)
{
  .smart-section-1
  {
    background-size: 100% 130px !important;
  }
}

@media (max-width: 990px)
{
  .smart-section-1
  {
    padding-top: 60px;
    padding-bottom: 20px;
    background-size: 100% auto !important;
  }
}

.smart-section-1 .col-md-6
{
  height: 450px;
  position: relative;
}

@media (max-width: 990px)
{
  .smart-section-1 .col-md-6
  {
    padding: 0 20px;
  }
}

.smart-section-2
{
  background-image: url(img/smart-background-2.jpg);
  background-size: 100% 130px;
  background-color: #a7226e
;
  background-repeat: no-repeat;
  padding-top: 160px;
  padding-bottom: 0;
}

@media (max-width: 1190px)
{
  .smart-section-2
  {
    background-size: 100% 130px !important;
  }
}

@media (max-width: 990px)
{
  .smart-section-2
  {
    background-size: 100% auto !important;
    padding-top: 60px;
    padding-bottom: 40px;
  }
}

.smart-section-2 .col-md-6
{
  height: 450px;
  position: relative;
}

@media (max-width: 990px)
{
  .smart-section-2 .col-md-6
  {
    padding: 0 20px;
  }
}

.smart-section-3
{
  background-image: url('./img/smart-background-3.jpg');
  background-size: 100% 130px;
  background-color: #c52676;
  padding-top: 200px;
  background-repeat: no-repeat;
}

@media (max-width: 1190px)
{
  .smart-section-3
  {
    background-size: 100% 130px !important;
  }
}

@media (max-width: 990px)
{
  .smart-section-3
  {
    padding-bottom: 0;
    background-size: 100% auto !important;
    padding-top: 100px;
  }
}

.smart-section-3 .col-md-6
{
  height: 320px;
  position: relative;
}

@media (max-width: 990px)
{
  .smart-section-3 .col-md-6
  {
    padding: 0 20px;
  }
}

.smart-section-4
{
  background-size: 100% 100px;
  background-repeat: no-repeat;
  background-color: #c52676;
  padding-bottom: 95px;
}

@media (max-width: 1190px)
{
  .smart-section-4
  {
    background-size: 100% 130px !important;
  }
}

@media (max-width: 990px)
{
  .smart-section-4
  {
    padding-top: 40px;
    padding-bottom: 150px;
    background-size: 100% auto !important;
  }
}

.smart-section-4 .col-md-6
{
  height: 600px;
  position: relative;
}

@media (max-width: 990px)
{
  .smart-section-4 .col-md-6
  {
    padding: 0 20px;
  }
}

.smart-section-5
{
  background-size: 100% 100px;
  background-repeat: no-repeat;
  background-color: #65468a;
  padding-bottom: 30px;
}

@media (max-width: 1190px)
{
  .smart-section-5
  {
    background-size: 100% 130px !important;
  }
}

@media (max-width: 990px)
{
  .smart-section-5
  {
    padding-bottom: 0;
    padding-top: 0px;
    background-size: 100% auto !important;
  }
}

.smart-section-5 .col-md-6
{
  height: 600px;
  position: relative;
}

@media (max-width: 990px)
{
  .smart-section-5 .col-md-6
  {
    padding: 0 20px;
  }
}

.smart-section-6
{
  background-color: #3c77b9;
  background-image: url(img/smart-background-6.jpg);
  background-size: 100% 130px;
  background-position: center bottom;
  background-repeat: no-repeat;
  padding-bottom: 80px;
}

@media (max-width: 1190px)
{
  .smart-section-6
  {
    background-size: 100% 130px !important;
    padding-top: 0;
  }
}

@media (max-width: 990px)
{
  .smart-section-6
  {
    padding-top: 0;
    background-size: 100% auto !important;
    padding-bottom: 100px;
  }
}

.smart-section-6 .col-md-6
{
  height: 600px;
  position: relative;
}

@media (max-width: 990px)
{
  .smart-section-6 .col-md-6
  {
    padding: 0 20px;
  }
}

.smart-download-image
{
  margin: 40px auto 10px;
  width: 80px;
  display: block;
}

.smart-download-button
{
  background: #c43427;
  width: auto;
  display: inline-block;
  color: white !important;
  padding: 3px 30px;
  font-weight: 300;
  border-radius: 5px;
  -webkit-transition: all ease-in-out 0.2s;
  -moz-transition: all ease-in-out 0.2s;
  transition: all ease-in-out 0.2s;
  text-transform: uppercase;
  margin-top: 20px;
  font-size: 18px;
}

.smart-download-button:hover
{
  padding: 3px 40px;
  text-decoration: none;
  color: white;
}

.smart-form-section-1
{
  background-image: url(img/smart-background-1.jpg);
  background-size: 100% 130px;
  background-repeat: no-repeat;
  background-color: #3f2155;
  padding-top: 00px;
}

@media (max-width: 1190px)
{
  .smart-form-section-1
  {
    background-size: 100% 130px !important;
    padding-bottom: 0;
    padding-top: 100px;
  }
}

@media (max-width: 990px)
{
  .smart-form-section-1
  {
    padding-top: 60px;
    padding-bottom: 0;
    background-size: 100% auto !important;
  }
}

.smart-form-section-1 .col-md-6
{
  height: 450px;
  position: relative;
}

@media (max-width: 990px)
{
  .smart-form-section-1 .col-md-6
  {
    padding: 0 20px;
  }
}

.smart-form-section-1 img
{
  max-width: 300px !important;
  z-index: 999;
}

.smart-form-section-1 .section-text
{
  padding-left: 20px;
  padding-right: 20px;
}

@media (max-width: 990px)
{
  .smart-form-section-1 .section-text
  {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.smart-form-section-2
{
  background-image: url(img/smart-background-2.jpg);
  background-size: 100% 130px;
  background-color: #a7226e
;
  background-repeat: no-repeat;
  padding-top: 160px;
  padding-bottom: 0;
  margin-top: 0px;
  text-align: left;
}

@media (max-width: 1190px)
{
  .smart-form-section-2
  {
    background-size: 100% 130px !important;
  }
}

@media (max-width: 990px)
{
  .smart-form-section-2
  {
    padding-top: 100px;
    padding-bottom: 0;
    background-size: 100% auto !important;
  }
}

.smart-form-section-2 .col-md-6
{
  height: 450px;
  position: relative;
}

@media (max-width: 990px)
{
  .smart-form-section-2 .col-md-6
  {
    padding: 0 20px;
  }
}

.renew-terms li
{
  font-size: 15px;
  font-weight: 300;
}

.renew-terms ul
{
  text-align: left;
}

.renew-terms h3
{
  margin-top: 10px;
  font-size: 18px;
}

.renew-terms p
{
  font-size: 15px;
  color: black;
  line-height: 1.3;
}

.renew-terms
{
  text-align: left;
  color: black;
}

.renew-checkbox
{
  line-height: 1.3;
}

.renew-checkbox input
{
  zoom: 1.5 !important;
  margin-right: 3px !important;
}

.stoke-section--1
{
  /* background-image: url(img/curve-top-home.png); */
  background-size: 100% 130px;
  background-repeat: no-repeat;
  /* background-color: #2f9599;   */
  /* padding-top: 70px; */
  padding-bottom: 0px;
  /* filter: brightness(0) saturate(100%) invert(45%) sepia(89%) saturate(306%) hue-rotate(133deg) brightness(93%) contrast(94%); */
}



@media (max-width: 1190px)
{
  .stoke-section--1
  {
    background-size: 100% 130px !important;
  }
}

@media (max-width: 991px)
{
  .stoke-section--1
  {
    /* padding-top: 60px; */
    /* padding-bottom: 20px; I removed this to fix mobil when adding bottom curve*/
    background-size: 100% auto !important;
  }
}

.stoke-section--1 h1
{
  font-size: 44px;
}

.stoke-section--2a
{
  background-color: #f5df68;
  background-image: url(img/stoke-background-2.jpg);
  background-size: 100% 130px;
  background-repeat: no-repeat;
  padding-top: 60px;
  padding-bottom: 0px;
}

@media (max-width: 1190px)
{
  .stoke-section--2a
  {
    background-size: 100% 130px !important;
    padding-top: 100px;
  }
}

@media (max-width: 991px)
{
  .stoke-section--2a
  {
    padding-top: 60px;
    padding-bottom: 20px;
    background-size: 100% auto !important;
  }
}

.stoke-section--2a .col-md-6
{
  height: 450px;
  position: relative;
}

@media (max-width: 990px)
{
  .stoke-section--2a .col-md-6
  {
    padding: 0 20px;
  }
}

.stoke-section--2a h2
{
  font-size: 44px;
}

.stoke-section--2b
{
  background-color: #a7226e
;
  padding-top: 0px;
  padding-bottom: 60px;
}

@media (max-width: 1190px)
{
  .stoke-section--2b
  {
    background-size: 100% 130px !important;
  }
}

@media (max-width: 991px)
{
  .stoke-section--2b
  {
    padding-top: 60px;
    padding-bottom: 0px;
    background-size: 100% auto !important;
  }
}

@media (max-width: 990px)
{
  .stoke-section--2b img
  {
    margin-top: 0;
  }
}

.stoke-section--2b .col-md-6
{
  height: 260px;
  position: relative;
}

@media (max-width: 991px)
{
  .stoke-section--2b .col-md-6
  {
    padding: 0 20px;
  }
}

.stoke-section--2b h2
{
  font-size: 44px;
  text-align: left;
}

.stoke-section--2b ul
{
  text-align: left;
}

@media (max-width: 991px)
{
  .stoke-section--2b img
  {
    margin-top: 30px;
  }
}

.stoke-section--3
{
  background-image: url(img/stoke-background-3.jpg);
  background-size: 100% 130px;
  background-repeat: no-repeat;
  background-color: #3c77b9;
  padding-top: 120px;
  padding-bottom: 120px;
}

@media (max-width: 1190px)
{
  .stoke-section--3
  {
    background-size: 100% 130px !important;
    padding-bottom: 120px;
  }
}

@media (max-width: 990px)
{
  .stoke-section--3
  {
    padding-top: 60px;
    padding-bottom: 45px;
    background-size: 100% auto !important;
  }
}

.stoke-section--3 .col-md-6
{
  height: 500px;
  position: relative;
}

@media (max-width: 990px)
{
  .stoke-section--3 .col-md-6
  {
    padding: 0 20px;
  }
}

.stoke-section--3 .bottom-image
{
  height: 135px;
}

@media (max-width: 991px)
{
  .stoke-section--3 .bottom-image
  {
    height: 70px;
  }
}

.stoke-section--4a
{
  background-size: 100% 130px;
  background-repeat: no-repeat;
  background-color: #f26b38;
  padding-top: 0px;
  padding-bottom: 0;
}

@media (max-width: 1190px)
{
  .stoke-section--4a
  {
    background-size: 100% 130px !important;
  }
}

@media (max-width: 990px)
{
  .stoke-section--4a
  {
    padding-top: 60px;
    padding-bottom: 10px;
    background-size: 100% auto !important;
  }
}

.stoke-section--4a .col-md-6
{
  height: 425px;
  position: relative;
}

@media (max-width: 991px)
{
  .stoke-section--4a .col-md-6
  {
    padding: 0 20px;
  }
}

.stoke-section--4a .bottom-image
{
  height: 135px;
}

@media (max-width: 991px)
{
  .stoke-section--4a .bottom-image
  {
    height: 70px;
  }
}

@media (max-width: 991px)
{
  .stoke-section--4a img
  {
    margin-top: 30px;
  }
}

.stoke-section--4a--alt
{
  padding-bottom: 120px;
}

@media (max-width: 991px)
{
  .stoke-section--4a--alt
  {
    padding-bottom: 60px;
  }
}

.stoke-section--4b
{
  background-color: #f26b38;
  padding-top: 0px;
  padding-bottom: 0px;
}

@media (max-width: 991px)
{
  .stoke-section--4b
  {
    padding-top: 10px;
    padding-bottom: 0px;
  }
}

.stoke-section--4b .col-md-6
{
  height: 450px;
  position: relative;
}

@media (max-width: 990px)
{
  .stoke-section--4b .col-md-6
  {
    padding: 0 20px;
  }
}

.stoke-section--4b .bottom-image
{
  height: 135px;
}

@media (max-width: 991px)
{
  .stoke-section--4b .bottom-image
  {
    height: 70px;
  }
}

.stoke-section--5
{
  background-color: #2f9599;
  background-size: 100% 130px;
  background-repeat: no-repeat;
  padding-top: 30px;
  padding-bottom: 60px;
}

@media (max-width: 1190px)
{
  .stoke-section--5
  {
    background-size: 100% 130px !important;
    padding-bottom: 125px;
  }
}

@media (max-width: 991px)
{
  .stoke-section--5
  {
    padding-top: 50px;
    padding-bottom: 20px;
    background-size: 100% auto !important;
  }
}

.stoke-section--5 .col-md-6
{
  height: 365px;
  position: relative;
}

@media (max-width: 991px)
{
  .stoke-section--5 .col-md-6
  {
    padding: 0 20px;
  }
}

.stoke-section--5 .bottom-image
{
  height: 68px;
}

@media (max-width: 991px)
{
  .stoke-section--5 img
  {
    margin-top: 30px;
  }
}

.stoke-section--7
{
  background-size: 100% 130px;
  background-repeat: no-repeat;
  padding-top: 0px;
  background-color: #f5df68;
  padding-bottom: 20px;
}

@media (max-width: 1190px)
{
  .stoke-section--7
  {
    background-size: 100% 130px !important;
  }
}

@media (max-width: 990px)
{
  .stoke-section--7
  {
    padding-bottom: 40px;
    background-size: 100% auto !important;
    padding-top: 60px;
  }
}

.stoke-section--7 .col-md-6
{
  height: 480px;
  position: relative;
}

@media (max-width: 991px)
{
  .stoke-section--7 .col-md-6
  {
    padding: 0 20px;
  }
}

.stoke-section--8
{
  background-image: url(img/stoke-background-8.jpg);
  background-size: 100% 130px;
  background-repeat: no-repeat;
  padding-top: 60px;
  background-color: #3b78b7;
  padding-bottom: 170px;
}

@media (max-width: 1190px)
{
  .stoke-section--8
  {
    background-size: 100% 130px !important;
  }
}

@media (max-width: 990px)
{
  .stoke-section--8
  {
    padding-top: 100px;
    padding-bottom: 130px;
    background-size: 100% auto !important;
  }
}

.stoke-section--8 .col-md-6
{
  height: 480px;
  position: relative;
}

@media (max-width: 991px)
{
  .stoke-section--8 .col-md-6
  {
    padding: 0 20px;
  }
}

@media (max-width: 991px)
{
  .stoke-section--8 img
  {
    margin-top: 30px;
  }
}

.stoke-section--8--alt
{
  background-image: url(img/stoke-background-8-alt.jpg);
}

.stoke-form-section-1
{
  background-image: url(img/smart-background-1.jpg);
  background-size: 100% 130px;
  background-repeat: no-repeat;
  background-color: #3f2155;
  padding-top: 100px;
}

@media (max-width: 1190px)
{
  .stoke-form-section-1
  {
    background-size: 100% 130px !important;
  }
}

@media (max-width: 990px)
{
  .stoke-form-section-1
  {
    padding-top: 60px;
    padding-bottom: 0;
    background-size: 100% auto !important;
  }
}

.stoke-form-section-1 .col-md-6
{
  height: 330px;
  position: relative;
}

@media (max-width: 991px)
{
  .stoke-form-section-1 .col-md-6
  {
    padding: 0 20px;
  }
}

.stoke-form-section-1 img
{
  max-width: 380px !important;
  z-index: 999;
}

.stoke-form-section-1 .section-text
{
  padding-left: 20px;
  padding-right: 20px;
}

@media (max-width: 991px)
{
  .stoke-form-section-1 .section-text
  {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.stoke-form-section-2
{
  background-image: url(img/smart-background-2.jpg);
  background-size: 100% 130px;
  background-color: #a7226e
;
  background-repeat: no-repeat;
  padding-top: 200px;
  padding-bottom: 0;
  margin-top: 0px;
  text-align: left;
}

@media (max-width: 1190px)
{
  .stoke-form-section-2
  {
    background-size: 100% 130px !important;
    padding-bottom: 0;
  }
}

@media (max-width: 991px)
{
  .stoke-form-section-2
  {
    padding-top: 100px;
    padding-bottom: 0;
    background-size: 100% auto !important;
  }
}

.stoke-form-section-2 .col-md-6
{
  height: 450px;
  position: relative;
}

@media (max-width: 991px)
{
  .stoke-form-section-2 .col-md-6
  {
    padding: 0 20px;
  }
}

.stoke-form-section-2 .form-subheading
{
  color: white;
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 20px;
  line-height: 1.2;
}

@media (max-width: 991px)
{
  .stoke-form-section-2 .form-subheading
  {
    font-size: 22px;
  }
}

.stoke-section--new
{
  background-color: #3c77b7;
  padding-top: 0px;
  padding-bottom: 150px;
}

@media (max-width: 1190px)
{
  .stoke-section--new
  {
    background-size: 100% 130px !important;
  }
}

@media (max-width: 991px)
{
  .stoke-section--new
  {
    padding-top: 60px;
    padding-bottom: 70px;
    background-size: 100% auto !important;
  }
}

.stoke-section--new img
{
  margin-top: 120px;
}

@media (max-width: 991px)
{
  .stoke-section--new img
  {
    margin-top: 0;
  }
}

.stoke-section--new .col-md-6
{
  height: 260px;
  position: relative;
}

@media (max-width: 991px)
{
  .stoke-section--new .col-md-6
  {
    padding: 0 20px;
  }
}

.stoke-section--new h2
{
  font-size: 44px;
}

.ces-section-1
{
  background-color: #3e2255;
  padding-bottom: 170px;
}

.ces-section-2
{
  background-color: #a7226e
;
  padding-bottom: 90px;
}

@media (max-width: 991px)
{
  .ces-section-2
  {
    padding-top: 0;
  }
}

@media (max-width: 991px)
{
  .ces-section-2 img
  {
    margin-bottom: 30px;
  }
}

.ces-section-3
{
  background-color: #8c58a3;
  padding-top: 80px;
  padding-bottom: 180px;
}

.ces-section-4
{
  background-color: #b53774;
  padding-bottom: 200px;
}

.ces-section-5
{
  background-color: #3e2255;
  padding-top: 50px;
  padding-bottom: 240px;
}

.section .ces-rings-section
{
  background-repeat: no-repeat;
  background-position: top center !important;
  background-size: 100%;
  padding-top: 200px;
  margin-top: -200px;
  pointer-events: none;
}

@media (max-width: 1191px)
{
  .section .ces-rings-section
  {
    padding-top: 130px;
    margin-top: -130px !important;
  }
}

.section .ces-rings-section h2
{
  pointer-events: auto;
}

.section .ces-rings-section a
{
  pointer-events: auto;
}

.section .ces-rings-section p
{
  max-width: 400px;
  margin: 0 auto;
  pointer-events: auto;
}

.ces-rings-section-left
{
  background-image: url(img/left-ring.png);
}

@media (max-width: 991px)
{
  .ces-rings-section-left
  {
    background: none;
  }
}

.ces-rings-section-right
{
  background-image: url(img/right-ring.png);
}

.stoke-cs-section--1
{
  background-image: url(img/stoke-cs-background-2.jpg);
  background-size: 100% 130px;
  background-repeat: no-repeat;
  background-color: #3f2055;
  padding-bottom: 40px;
  padding-top: 100px;
}

@media (max-width: 1190px)
{
  .stoke-cs-section--1
  {
    background-size: 100% 130px !important;
    padding-top: 100px;
  }
}

@media (max-width: 991px)
{
  .stoke-cs-section--1
  {
    background-size: 100% auto !important;
  }
}

.stoke-cs-section--1 .col-md-6
{
  height: 500px;
  position: relative;
}

@media (max-width: 991px)
{
  .stoke-cs-section--1 .col-md-6
  {
    padding: 0 20px;
  }
}

.stoke-cs-section--1 .bottom-image
{
  height: 135px;
}

@media (max-width: 991px)
{
  .stoke-cs-section--1 .bottom-image
  {
    height: 60px;
  }
}

.stoke-section--1--alt
{
  padding-bottom: 200px;
}

.stoke-section--1--alt .col-md-12
{
  margin-bottom: 40px;
}

.stoke-section--1--alt .house
{
  margin-top: 78px;
  max-width: 300px;
}

.stoke-section--1--alt .piggy
{
  margin-top: 100px;
}

.stoke-section--1--alt .keys
{
  max-width: 250px;
  margin-top: 60px;
}

.ces-section-2 .house
{
  margin-top: 78px;
  max-width: 300px;
}

.ces-section-2 .piggy
{
  margin-top: 100px;
}

.ces-section-2 .keys
{
  max-width: 250px;
}

.stoke-cs-section--2
{
  background-image: url(img/stoke-cs-background-3.jpg);
  background-size: 100% 130px;
  background-repeat: no-repeat;
  background-color: #572b82;
  padding-bottom: 40px;
  padding-top: 100px;
}

@media (max-width: 1190px)
{
  .stoke-cs-section--2
  {
    padding-top: 150px;
    background-size: 100% 130px !important;
  }
}

@media (max-width: 991px)
{
  .stoke-cs-section--2
  {
    padding-top: 0;
    background-size: 100% auto !important;
  }
}

.stoke-cs-section--2 .col-md-6
{
  height: 500px;
  position: relative;
}

@media (max-width: 991px)
{
  .stoke-cs-section--2 .col-md-6
  {
    padding: 0 20px;
  }
}

.stoke-cs-section--3
{
  background-image: url(img/stoke-cs-background-2.jpg);
  background-size: 100% 130px;
  background-repeat: no-repeat;
  background-color: #3f2056;
  padding-top: 40px;
}

@media (max-width: 1191px)
{
  .stoke-cs-section--3
  {
    background-size: 100% 130px !important;
  }
}

@media (max-width: 991px)
{
  .stoke-cs-section--3
  {
    background-size: 100% auto !important;
  }
}

.stoke-cs-section--3 .col-md-6
{
  height: 500px;
  position: relative;
}

@media (max-width: 991px)
{
  .stoke-cs-section--3 .col-md-6
  {
    padding: 0 20px;
  }
}

.stoke-cs-section--4
{
  background-image: url(img/stoke-cs-background-3.jpg);
  background-size: 100% 130px;
  background-repeat: no-repeat;
  background-color: #572b82;
  padding-top: 60px;
}

@media (max-width: 1191px)
{
  .stoke-cs-section--4
  {
    background-size: 100% 130px !important;
    padding-top: 140px;
  }
}

@media (max-width: 991px)
{
  .stoke-cs-section--4
  {
    background-size: 100% auto !important;
    padding-top: 40px;
  }
}

.stoke-cs-section--4 .col-md-6
{
  height: 500px;
  position: relative;
}

@media (max-width: 991px)
{
  .stoke-cs-section--4 .col-md-6
  {
    padding: 0 20px;
  }
}

.stoke-cs-section--5
{
  background-image: url(img/stoke-cs-background-2.jpg);
  background-size: 100% 130px;
  background-repeat: no-repeat;
  background-color: #3f2056;
  padding-bottom: 200px;
  padding-top: 100px;
}

@media (max-width: 1191px)
{
  .stoke-cs-section--5
  {
    background-size: 100% 130px !important;
  }
}

@media (max-width: 991px)
{
  .stoke-cs-section--5
  {
    background-size: 100% auto !important;
  }
}

.stoke-form-section-1
{
  background-image: url(img/smart-background-1.jpg);
  background-size: 100% 130px;
  background-repeat: no-repeat;
  background-color: #3f2155;
  padding-top: 00px;
}

@media (max-width: 1190px)
{
  .stoke-form-section-1
  {
    background-size: 100% 130px !important;
    padding-bottom: 0;
    padding-top: 100px;
  }
}

@media (max-width: 990px)
{
  .stoke-form-section-1
  {
    padding-top: 60px;
    padding-bottom: 0;
    background-size: 100% auto !important;
  }
}

.stoke-form-section-1 .col-md-6
{
  height: 450px;
  position: relative;
}

@media (max-width: 991px)
{
  .stoke-form-section-1 .col-md-6
  {
    padding: 0 20px;
  }
}

.stoke-form-section-1 img
{
  z-index: 999;
}

.stoke-form-section-1 .section-text
{
  padding-left: 20px;
  padding-right: 20px;
}

@media (max-width: 991px)
{
  .stoke-form-section-1 .section-text
  {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.stoke-form-section-2
{
  background-image: url(img/smart-background-2.jpg);
  background-size: 100% 130px;
  background-color: #a7226e
;
  background-repeat: no-repeat;
  padding-top: 160px;
  padding-bottom: 0;
  margin-top: 0px;
  text-align: left;
}

@media (max-width: 1190px)
{
  .stoke-form-section-2
  {
    background-size: 100% 130px !important;
  }
}

@media (max-width: 991px)
{
  .stoke-form-section-2
  {
    padding-top: 100px;
    padding-bottom: 0;
    background-size: 100% auto !important;
  }
}

.stoke-form-section-2 .col-md-6
{
  height: 450px;
  position: relative;
}

@media (max-width: 991px)
{
  .stoke-form-section-2 .col-md-6
  {
    padding: 0 20px;
  }
}

.stoke-download-button
{
  width: 300px;
  margin: 0 auto 40px;
  position: relative;
  font-size: 18px;
  text-transform: none;
  background: #c13e36;
}

.stoke-download-button img
{
  height: 30px;
  position: absolute;
  left: 5px;
  top: 8px;
}

.stoke-directive-piggy-section
{
  padding-bottom: 200px;
  background-color: #764395;
}

.stoke-directive-piggy-container
{
  text-align: center;
}

.ces-header
{
  width: 120px;
  float: right;
}

.video-pig-image
{
  position: absolute;
  left: -230px;
  top: -100px;
}

@media (max-width: 600px)
{
  .video-pig-image
  {
    display: none;
  }
}

.video-thumb-image
{
  position: absolute;
  right: -110px;
  top: 0;
}

@media (max-width: 600px)
{
  .video-thumb-image
  {
    display: none;
  }
}

.datepick-inline
{
  border: none !important;
  background-color: #3e1d55 !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.block_hints
{
  display: none !important;
}

.block_hints .block_free,.block_hints .block_time,.block_hints .block_booked,.block_hints .block_pending,.block_hints .block_check_in_out,.datepick-inline .datepick-days-cell
{
  border: none !important;
}

div.datepick-inline .datepick-title-row th
{
  border: none !important;
}

.datepick-inline .calendar-links
{
  background-color: #3e1d55 !important;
}

.datepick-inline .datepick-header
{
  color: white !important;
  font-weight: 300 !important;
  text-shadow: none !important;
}

.datepick-inline .datepick-header span
{
  font-size: 18px !important;
}

div.datepick-inline .datepick-title-row th
{
  color: white !important;
  font-weight: 300 !important;
  text-shadow: none !important;
  font-size: 18px !important;
}

.datepick-unselectable
{
  background-color: #3e1d55 !important;
  color: #949494 !important;
  text-shadow: none !important;
}

.datepick-current-day
{
  background-color: #464e51 !important;
}

.datepick-inline .calendar-links
{
  background: none !important;
  border: none !important;
}

.datepick-inline .calendar-links .datepick-prev a,.datepick-inline .calendar-links .datepick-next a,.datepick-inline .calendar-links .datepick-prev a:hover,.datepick-inline .calendar-links .datepick-next a:hover
{
  text-shadow: none !important;
  color: white !important;
}

.datepick-inline .datepick-days-cell a,.datepick-inline .datepick-days-cell span
{
  font-weight: 300 !important;
  text-shadow: none !important;
  font-size: 18px !important;
}

.booking_form input[type="button"],.booking_form input[type="submit"]
{
  font-size: 18px !important;
  font-weight: 300 !important;
  text-transform: uppercase !important;
  color: white !important;
  background-color: #464e51 !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  box-shadow: none !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  border: none !important;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.booking_form input[type="button"]:hover,.booking_form input[type="button"]:focus,.booking_form input[type="button"]:active,.booking_form input[type="submit"]:hover,.booking_form input[type="submit"]:focus,.booking_form input[type="submit"]:active
{
  color: #464e51 !important;
  background-color: white !important;
  border: none !important;
}

.booking_form input[type="text"]
{
  color: #a7226e
 !important;
  font-size: 18px !important;
  font-weight: 300 !important;
  border: 1px solid transparent !important;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  width: 100% !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  padding-left: 10px !important;
  margin-top: 10px !important;
}

@media (max-width: 991px)
{
  .booking_form input[type="text"]
  {
    font-size: 16px !important;
  }
}

.bk_calendar_frame
{
  margin: 0 auto !important;
}

input.wpdev-radio
{
  zoom: 2 !important;
}

.page-template-template-fixed-ten .navbar
{
  background-color: #9dd9f7;
}

.page-template-template-fixed-ten nav
{
  background-color: #9dd9f7;
}

.page-template-template-fixed-ten a.nav-expander__myaccount
{
  background-color: #9dd9f7 !important;
  color: #a7226e
;
}

.page-template-template-fixed-ten .icon-bar
{
  background: #a7226e
 !important;
}

.page-template-template-fixed-ten .icon-text
{
  color: #a7226e
;
}

.page-template-template-fixed-ten .main-menu li a
{
  color: #a7226e
;
}

.fixed-ten-curve
{
  width: 100%;
  position: absolute;
  bottom: 0;
  max-height: 150px;
  pointer-events: none;
}

.fixed-ten-hero-section
{
  background-color: #9dd9f7;
  padding-bottom: 120px;
}

.fixed-ten-hero-section h1
{
  color: #a7226e
;
  font-size: 48px;
  line-height: 1em;
  text-align: left;
}

.fixed-ten-promise-section
{
  background-color: white;
  padding-bottom: 120px;
}

.fixed-ten-promise-section h2
{
  color: #a7226e
;
  font-size: 48px;
  line-height: 1em;
  text-align: left;
  margin-bottom: 30px;
}

.fixed-ten-promise-section p
{
  color: #a7226e
;
  font-size: 20px;
  line-height: 1.4em;
  text-align: left;
  margin-bottom: 30px;
}

.fixed-ten-promise-section .promise-quote
{
  color: #a7226e
;
  font-size: 24px;
  line-height: 1.2em;
  text-align: left;
  padding: 130px 100px 0px 100px;
  margin: 0px;
}

.fixed-ten-benefit-section
{
  background-color: #9dd9f7;
  padding-bottom: 30px;
}

.fixed-ten-benefit-section h2
{
  color: #a7226e
;
  font-size: 48px;
  line-height: 1em;
  text-align: left;
  margin-bottom: 10px;
}

.fixed-ten-benefit-section ul
{
  padding-left: 20px;
}

.fixed-ten-benefit-section li
{
  color: #a7226e
;
  font-size: 24px;
  line-height: 1.4em;
  margin-bottom: 10px;
}

.fixed-ten-benefit-section .benefit-stat
{
  color: white;
  font-size: 30px;
  line-height: 1.2em;
  text-align: left;
  padding: 70px 200px 0px 50px;
  margin: 0px;
}

.fixed-ten-diagram-section
{
  background-color: white;
  padding-bottom: 30px;
}

.fixed-ten-diagram-section h2
{
  color: #a7226e
;
  font-size: 48px;
  line-height: 1em;
  text-align: left;
  margin-bottom: 10px;
}

.fixed-ten-steps-section
{
  background-color: #f3eff5;
  padding-bottom: 120px;
}

.fixed-ten-steps-section h2
{
  color: #a7226e
;
  font-size: 48px;
  line-height: 1em;
  text-align: left;
  margin-bottom: 30px;
}

.fixed-ten-steps-section p
{
  color: #a7226e
;
  font-size: 20px;
  line-height: 1.4em;
  text-align: left;
  margin-bottom: 30px;
}

.katie-section-1
{
  background-image: url(img/katie-background.jpg);
  background-color: #529ad3;
  background-repeat: no-repeat;
  background-position: center center !important;
  background-size: 100% auto !important;
  padding-top: 100px;
  height: 800px;
  position: relative;
}

@media (max-width: 1650px)
{
  .katie-section-1
  {
    background-position: center bottom !important;
  }
}

@media (max-width: 1190px)
{
  .katie-section-1
  {
    height: 600px !important;
  }
}

@media (max-width: 991px)
{
  .katie-section-1
  {
    height: 500px !important;
    background-size: 100% 750px !important;
    padding: 25px 0 !important;
  }
}

@media (max-width: 600px)
{
  .katie-section-1
  {
    height: 400px !important;
  }
}

.katie-section-1 .col-md-6
{
  position: relative;
  height: auto !important;
}

@media (max-width: 991px)
{
  .katie-section-1 .col-md-6
  {
    padding: 0;
  }
}

.katie-section-1 .section-image
{
  max-width: 100%;
  max-height: 100%;
}

.katie-section-1 .heading
{
  font-size: 80px;
  font-weight: 500;
  text-shadow: none !important;
  line-height: 70px;
  color: #a7226e
;
  text-align: left;
  margin-top: 10px;
  letter-spacing: -2px !important;
}

@media (max-width: 1190px)
{
  .katie-section-1 .heading
  {
    font-size: 42px;
    line-height: 42px;
    margin-top: 0px;
    letter-spacing: -1px !important;
  }
}

@media (max-width: 991px)
{
  .katie-section-1 .heading
  {
    font-size: 32px;
    line-height: 32px;
    margin-top: 10px;
    text-align: center;
  }
}

.katie-section-1 .heading span
{
  font-weight: 300;
  font-size: 70px;
}

@media (max-width: 991px)
{
  .katie-section-1 .heading span
  {
    font-size: 28px;
  }
}

.katie-section-1 .section-content
{
  display: table-cell;
  vertical-align: top;
  width: 100%;
}

.katie-section-1 .whale-curve-bottom
{
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: auto;
}

.katie-section-1 .whale-curve-top
{
  position: absolute;
  top: -1px;
  left: 0;
}

.katie-section-2
{
  background-color: #3f1e55;
  background-repeat: no-repeat;
  background-position: center center !important;
  background-size: 100% auto !important;
  padding-top: 100px;
  height: 700px;
  position: relative;
}

@media (max-width: 1190px)
{
  .katie-section-2
  {
    height: 700px !important;
  }
}

@media (max-width: 991px)
{
  .katie-section-2
  {
    height: auto !important;
    background-size: 100% 750px !important;
    padding: 50px 0;
  }
}

.katie-section-2 p span
{
  color: #a3c210;
  font-weight: bold;
}

@media (max-width: 991px)
{
  .katie-section-2 .col-md-6
  {
    padding: 10px 20px;
  }
}

.katie-section-2 .section-image
{
  max-width: 100%;
  max-height: 100%;
}

.katie-section-3
{
  background-image: url(img/katie-background-3.jpg);
  background-color: #b63878;
  background-repeat: no-repeat;
  background-position: center top !important;
  background-size: 100% auto !important;
  padding-top: 150px;
  height: 700px;
  position: relative;
}

@media (max-width: 1190px)
{
  .katie-section-3
  {
    height: 700px !important;
  }
}

@media (max-width: 991px)
{
  .katie-section-3
  {
    height: auto !important;
    padding: 80px 0 0 !important;
  }
}

@media (max-width: 991px)
{
  .katie-section-3 .col-md-6
  {
    padding: 0;
  }
}

.katie-section-3 .section-image
{
  max-width: 100%;
  max-height: 100%;
}

.katie-section-3 p a
{
  font-weight: bold;
}

.katie-section-4
{
  background-image: url(img/katie-background-4.jpg);
  background-color: #609c9d;
  background-repeat: no-repeat;
  background-position: center top !important;
  background-size: 100% auto !important;
  padding-top: 160px;
  height: auto;
  padding-bottom: 40px;
  position: relative;
}

@media (max-width: 1190px)
{
  .katie-section-4
  {
    height: 500px !important;
  }
}

@media (max-width: 991px)
{
  .katie-section-4
  {
    height: auto !important;
    padding: 90px 0 50px !important;
  }
}

.katie-section-4 p span
{
  color: #a3c210;
  font-weight: bold;
}

.katie-section-4 .col-md-6
{
  height: 300px;
}

@media (max-width: 991px)
{
  .katie-section-4 .col-md-6
  {
    height: auto;
    padding: 20px 0;
  }
}

.katie-section-4 .footer-social img
{
  width: 70px;
}

.katie-section-4 p
{
  font-size: 22px;
  line-height: 1.3em;
}

.katie-section-5
{
  background-image: url(img/katie-background-5.jpg);
  background-color: #3f1e55;
  background-repeat: no-repeat;
  background-position: center top !important;
  background-size: 100% auto !important;
  padding-top: 140px;
  height: 500px;
  position: relative;
}

@media (max-width: 991px)
{
  .katie-section-5
  {
    height: auto !important;
    padding: 100px 0 !important;
  }
}

.katie-section-5 .section-image
{
  max-width: 100%;
  max-height: 100%;
}

.katie-section-5 p span
{
  color: #a3c210;
  font-weight: bold;
}

@media (max-width: 991px)
{
  .katie-section-5 .col-md-4
  {
    padding: 10px 0;
  }
}

.katie-section-5 .section-text
{
  width: 100%;
}

.katie-section-5 .logo-1
{
  margin: 20px auto 0;
  width: 250px;
  display: block;
}

@media (max-width: 991px)
{
  .katie-section-5 .logo-1
  {
    margin: 10px auto;
  }
}

.katie-section-5 .logo-2
{
  margin: 0 auto;
  width: 150px;
  display: block;
}

@media (max-width: 991px)
{
  .katie-section-5 .logo-2
  {
    margin: 10px auto;
  }
}

.katie-section-5 .logo-3
{
  margin: 50px auto 0;
  width: 250px;
  display: block;
}

@media (max-width: 991px)
{
  .katie-section-5 .logo-3
  {
    margin: 10px auto;
  }
}

.katie-section-5 .bottom-curve
{
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
}

.katie-social
{
  background-color: #3f1e55;
  background-image: url(img/curve-top-home.png);
  background-repeat: no-repeat;
  background-position: center top !important;
  background-size: 100% auto !important;
  padding-top: 100px;
}

@media (max-width: 991px)
{
  .katie-social
  {
    padding-top: 60px;
  }
}

.katie-social .col-md-4
{
  text-align: center;
}

.katie-social-title
{
  color: #a3c210;
  font-size: 70px;
  font-weight: 300;
  margin-bottom: 60px;
}

@media (max-width: 991px)
{
  .katie-social-title
  {
    font-size: 40px;
  }
}

.katie-social-top-text
{
  margin-top: -120px;
}

@media (max-width: 991px)
{
  .katie-social-top-text
  {
    margin-top: 0px;
  }
}

.katie-social-thumbnail
{
  margin-bottom: 30px;
  border: 5px solid #a3c210;
  cursor: pointer;
}

.katie-social-thumbnail-description
{
  margin-bottom: 30px;
}

.energy-tips-house-container
{
  position: relative;
}

.energy-tips-house-container-mobile
{
  width: 345px;
  display: block;
  margin: 0 auto;
}

.energy-tips-house-image
{
  pointer-events: none !important;
}

.energy-tips-plus
{
  position: absolute !important;
}

@media (max-width: 1199px)
{
  .energy-tips-house-container-desktop
  {
    display: none;
  }
}

@media (min-width: 1200px)
{
  .energy-tips-house-container-desktop
  {
    display: block;
  }
}

@media (max-width: 1199px)
{
  .energy-tips-house-container-mobile
  {
    display: block;
  }
}

@media (min-width: 1200px)
{
  .energy-tips-house-container-mobile
  {
    display: none;
  }
}

.energy-tips-house-container-desktop .energy-tips-roof-text
{
  position: absolute;
  color: white;
  top: 120px;
  width: 480px;
  left: 360px;
  text-align: center;
}

.energy-tips-house-container-desktop .energy-tips-roof-text h1
{
  font-size: 30px;
}

.energy-tips-house-container-desktop .energy-tips-roof-text sup
{
  font-size: 18px;
}

.energy-tips-house-container-mobile .energy-tips-roof-text
{
  position: absolute;
  color: white;
  top: 65px;
  left: 85px;
  text-align: center;
}

.energy-tips-house-container-mobile .energy-tips-roof-text h1
{
  font-size: 13px;
}

.energy-tips-house-container-mobile .energy-tips-roof-text p
{
  font-size: 13px;
}

.energy-tips-house-container-mobile .energy-tips-roof-text sup
{
  font-size: 10px;
}

.energy-tips-quote-first
{
  background-color: #a7226e
;
  padding-top: 0px;
  padding-bottom: 40px;
}

.energy-tips-quote-second
{
  background-color: #8c59a0;
  padding-top: 30px;
  padding-bottom: 160px;
}

.energy-tips-quote-first h2,.energy-tips-quote-second h2
{
  font-size: 34px;
  font-weight: 600;
}

.page-template-template-energy-tips .featherlight:last-of-type
{
  background: rgba(76,53,95,0.5);
}

.page-template-template-energy-tips .featherlight-content
{
  max-width: 600px;
  border: 5px solid #a7226e
;
  padding-right: 0px;
}

.page-template-template-energy-tips .featherlight-content h2
{
  padding-right: 30px;
  font-size: 32px;
  font-weight: 300;
  color: #a7226e
;
}

@media (max-width: 1199px)
{
  .page-template-template-energy-tips .featherlight-content h2
  {
    font-size: 26px;
  }
}

.page-template-template-energy-tips .featherlight-content p
{
  padding-right: 30px;
  color: black;
  line-height: 1.2;
  margin-bottom: 15px;
}

@media (max-width: 1199px)
{
  .page-template-template-energy-tips .featherlight-content p
  {
    font-size: 16px;
  }
}

.page-template-template-energy-tips .featherlight-close-icon
{
  background-color: #464e51;
  margin-top: 5px;
  margin-right: 5px;
  border-radius: 50%;
  color: white;
}

.page-template-template-energy-tips .lightbox-save
{
  /* float: right; */
  background-color: #464e51;
  display: inline-block;
  border-top-left-radius: 50px;
  padding: 15px;
  padding-left: 30px;
  font-size: 22px;
  font-weight: 500;
  color: white;
}

@media (max-width: 1199px)
{
  .page-template-template-energy-tips .lightbox-save
  {
    font-size: 18px;
    padding: 10px;
    padding-left: 30px;
  }
}

.page-template-template-energy-tips .lightbox-save sup
{
  font-size: 16px;
}

@media (max-width: 1199px)
{
  .page-template-template-energy-tips .lightbox-save sup
  {
    font-size: 12px;
  }
}

.page-template-template-energy-tips .section .col-md-6
{
  height: 350px;
}

.energy-tips-caveats
{
  text-align: center;
}

.energy-tips-caveats p
{
  font-size: 14px;
}

.community-jobs-section--1
{
  background-image: url(img/community-jobs-background-2.jpg);
  background-size: 100% 130px;
  background-repeat: no-repeat;
  background-color: #3f2055;
  padding-bottom: 200px;
  padding-top: 100px;
}

@media (max-width: 1190px)
{
  .community-jobs-section--1
  {
    background-size: 100% 130px !important;
    padding-top: 100px;
  }
}

@media (max-width: 991px)
{
  .community-jobs-section--1
  {
    background-size: 100% auto !important;
  }
}

.community-jobs-section--1 .col-md-6
{
  height: 500px;
  position: relative;
}

@media (max-width: 990px)
{
  .community-jobs-section--1 .col-md-6
  {
    padding: 0 20px;
  }
}

.community-jobs-section--1 .bottom-image
{
  height: 135px;
}

@media (max-width: 990px)
{
  .community-jobs-section--1 .bottom-image
  {
    height: 60px;
  }
}

.community-jobs-section--2
{
  background-image: url(img/community-jobs-background-3.jpg);
  background-size: 100% 130px;
  background-repeat: no-repeat;
  background-color: #572b82;
  padding-top: 100px;
  padding-bottom: 100px;
}

@media (max-width: 1190px)
{
  .community-jobs-section--2
  {
    padding-top: 150px;
    background-size: 100% 130px !important;
  }
}

@media (max-width: 991px)
{
  .community-jobs-section--2
  {
    padding-top: 0;
    background-size: 100% auto !important;
  }
}

.community-jobs-section--2 .col-md-6
{
  height: 500px;
  position: relative;
}

@media (max-width: 990px)
{
  .community-jobs-section--2 .col-md-6
  {
    padding: 0 20px;
  }
}

.community-jobs-section--3
{
  background-image: url(img/community-jobs-background-2.jpg);
  background-size: 100% 130px;
  background-repeat: no-repeat;
  background-color: #3f2056;
  padding-top: 100px;
  padding-bottom: 200px;
}

@media (max-width: 1191px)
{
  .community-jobs-section--3
  {
    background-size: 100% 130px !important;
  }
}

@media (max-width: 991px)
{
  .community-jobs-section--3
  {
    background-size: 100% auto !important;
  }
}

.community-jobs-section--3 .col-md-6
{
  height: 500px;
  position: relative;
}

@media (max-width: 991px)
{
  .community-jobs-section--3 .col-md-6
  {
    padding: 0 20px;
  }
}

.danbox-overlay
{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.6);
  z-index: 9999;
  display: none;
}

.danbox-overlay-inner
{
  position: fixed;
  border: 5px solid #fff;
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: 700px;
  background: #ffffff;
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@media (max-width: 710px)
{
  .danbox-overlay-inner
  {
    left: 0px;
    right: 0px;
    width: auto;
    -moz-transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
  }
}

.danbox-overlay-inner-relative
{
  position: relative;
}

.danbox-close
{
  position: absolute;
  top: -60px;
  right: -60px;
  color: #fff;
  cursor: pointer;
  z-index: 9999;
}

.danbox-close img
{
  width: 50px !important;
}

.danbox-overlay img
{
  width: 100%;
  height: auto;
  display: block;
}

.videoWrapper
{
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
}

.videoWrapper iframe
{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
/*# sourceMappingURL=style.css.map */

.animation-start
{ 
  transform: translate(0px, 100px);
  opacity: 0;
  filter: brightness(150%);
  -webkit-transition: all 2s;
  -moz-transition: all 2s;
  -ms-transition: all 2s;
  -o-transition: all 2s;
  transition: all 2s;
}

.animation-end {
  transform: translate(0px, 0px);
  opacity: 1;
  filter: none;
}

.accordion-toggle {
  cursor: pointer;
}

.logo-animation
{ 
  transform: scale(0.1,0.1) translate(0px, -200px); 
  opacity: 0;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -ms-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
}

.logo-animation-end{
  transform: scale(1,1) translate(0px, 0px); 
  opacity: 1;
  filter: none;
}

.people-animation
{ 
  transform: scale(1,0.5) translate(0px, 100px); 
  opacity: 0;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -ms-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
}

.people-animation-end {
  transform: scale(1,1) translate(0px, 0px); 
  opacity: 1;
  filter: none;
}





/* image pop up */

/* Container needed to position the button. Adjust the width as needed */
.container-image-popup {
  position: relative;
  width: 100%;
}

/* Make the image responsive */
.container-image-popup img {
  width: 100%;
  height: auto;
}

/* Style the button and place it in the middle of the container-image-popup/image */
.container-image-popup .btn-pop-up {
  position: absolute;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  font-size: 22px;
  color: white;
  opacity: 0.2 ;
  border: 4px solid red;
  cursor: pointer;
  border-radius: 5px;
}

.container-image-popup .btn-pop-up.btn-pop-up1 {
  height: 5%;
  width: 44%;
  top: 4.1%;
  left: 25%;
  background-color: navy;
}

.container-image-popup .btn-pop-up.btn-pop-up2 {
  height: 70.1%;
  width: 27%;
  top: 51.8%;
  left: 82.2%;
  background-color: teal;
}


.container-image-popup .btn-pop-up.btn-pop-up3 {
  height: 4%;
  width: 22%;
  top: 23.85%;
  right: 23%;
  background-color: violet;
}

.container-image-popup .btn-pop-up.btn-pop-up4 {
  height: 2.5%;
  width: 80%;
  top: 42.8%;
  right: -30%;
  background-color: #3b78b7;
}

.container-image-popup .btn-pop-up.btn-pop-up5 {
  height: 2.7%;
  width: 80%;
  top: 45.4%;
  right: -30%;
  background-color: purple;
}

.container-image-popup .btn-pop-up.btn-pop-up6 {
  height: 3.5%;
  width: 80%;
  top: 48.5%;
  right: -30%;
  background-color: teal;
}

.container-image-popup .btn-pop-up.btn-pop-up7 {
  height: 3.5%;
  width: 80%;
  top: 52%;
  right: -30%;
  background-color: #9dd9f6;
}

.container-image-popup .btn-pop-up.btn-pop-up8 {
  height: 3.7%;
  width: 80%;
  top: 55.6%;
  right: -30%;
  background-color: #c42575;
}
.container-image-popup .btn-pop-up.btn-pop-up9 {
  height: 7%;
  width: 80%;
  top: 85.4%;
  right: -30.5%;
  background-color: purple;
}

.container-image-popup .btn-pop-up.btn-pop-up11 {
  height: 6%;
    width: 62.3%;
    top: 34.5%;
    left: 35.1%;
  background-color: palegoldenrod;
}

.container-image-popup .btn-pop-up.btn-pop-up12 {
  height: 7%;
  width: 62.3%;
    top: 45%;
    left: 35.1%;
  background-color: aquamarine;
}


.container-image-popup .btn-pop-up.btn-pop-up13 {
  height: 8.5%;
  width: 62.3%;
  top: 57%;
  left: 35.1%;
  background-color: rgb(15, 240, 53);
}

.container-image-popup .btn-pop-up.btn-pop-up14 {
  height: 8.2%;
  width: 62.3%;
    top: 70.3%;
    left: 35.1%;
  background-color: rgb(240, 206, 15);
}

.container-image-popup .btn-pop-up.btn-pop-up15 {
  height: 8%;
  width: 63%;
  top: 80.5%;
  left: 35.2%;
  background-color: rgb(240, 15, 229);
}


.container-image-popup .btn-pop-up:hover {
  background-color: red;
  border: 4px solid red;
}

.container-image-popup .btn-pop-up.btn-pop-up10 {
  background: #a7226e;
  width: auto;
  display: inline-block;
  color: white !important;
  padding: 7px 30px;
  font-weight: 300;
  border-radius: 5px;
  -webkit-transition: all ease-in-out 0.2s;
  -moz-transition: all ease-in-out 0.2s;
  transition: all ease-in-out 0.2s;
  text-transform: uppercase;
  font-size: 14px;
}

/* Style the button and place it in the middle of the container-image-popup/image */
.container2-image-popup2 .btn-pop-up {
  background: #a7226e;
  width: auto;
  display: inline-block;
  color: white !important;
  padding: 7px 30px;
  font-weight: 300;
  border-radius: 5px;
  -webkit-transition: all ease-in-out 0.2s;
  -moz-transition: all ease-in-out 0.2s;
  transition: all ease-in-out 0.2s;
  text-transform: uppercase;
  font-size: 14px;
  cursor: pointer;
}

.container2-image-popup .btn-pop-up:hover {
  background-color: #a7226e;
  border: none;

}


/* 
iframe css below */

.form_cont { 

  padding: 20px; 
  width: 1024px; 

}

#dswpf { 
  width: 1024px; 
  height: 700px;
  border: none;
}

a.forceUnderline {
  text-decoration: underline !important;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

