.videoLibrary {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 5em;
}

.videoLibrary_item {
    padding: 3%;
}

@media (max-width: 610px) {
    .videoLibrary_item {
        height: 100%;
        display: inline-block;
        text-align: center;
    }
    .videoLibrary__item__image {
        width:80% !important;
        min-width:80% !important;
        max-width:80% !important;
        max-height:none !important;
    }
    .videoLibrary p {
        width:80% !important;
        min-width:80% !important;
        max-width:80% !important;
        display: inline-block;
        text-align: center;
    }
}



.videoLibrary__item__image {
    z-index: 0;
    width: 100%;
    padding:0px !important;
}

.videoLibrary p {
    z-index: 1;
    margin-top: -2px;
    text-align: center;
    line-height: 22px;
    padding: 10px 6px;
    border-radius: 0px 0px 10px 10px;
}

@media (max-width: 1190px) {
    .videoLibrary p {
    font-size: .9em;
    line-height: 18px;
    }
}

.videoLibrary__Button--desktop {
    /* border:6px solid red; */
    z-index: 10;
    width:60%;
    height: 50%;
    margin:12% 20% ;
    display: block;
    position: absolute;
    left:0px;
    top:0px;
    cursor: pointer;
    @media (max-width: ){

    }
}

.videoLibrary__Button--mobile {
    /* border:2px solid blue; */
    z-index: 10;
    width:60%;
    height: 50%;
    margin:12% 20% ;
    display: block;
    position: absolute;
    left:0px;
    top:0px;
}
